// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SelectChangeEvent } from '@mui/material';
import { projectCarouselData, projectDataType } from "./types";
import {
  ProjectList2,
  QCInitiated,
  sectionList,
  settingList1,
  ProjectInQueue,
  settingList,
} from '../__mocks__/KanbanInterface';
import { Alert, Platform } from "react-native";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { ComponentState } from "react";
import { toast } from "react-toastify";
import { ReactNode } from "react";
interface ApiData {
  contentType: string,
  method: string,
  endPoint: string,
  body?: object
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  projects: projectDataType,
  statusFlow: string[],
  data: {
    history: { location: { pathname: string } },
    projectStatusColors: projectCarouselData["projectStatusColors"],
    projects: projectDataType
  },
}

interface statusList {
  status:string,
  count:number,
  colorCode:string,
}
interface RoomList {
    id: string,
    type:string,
    attributes: {
      id: number,
      room_id: number,
      bx_block_profile_project_id: number,
      room_name:string,
      start_date:any,
      end_date:any,
      task_list_count:string,
      completed_tasks_count:string,
    }
  }

  interface TaskList {
          id: string,
          type: string,
          attributes: {
            id: number,
            account_id: string,
            name:string,
        }        
  }

  interface PriorityList {
    id:string,
    Priority_name:string,
    PriorityBackgroundColor: string,
    PriorityColor: string, 
     
    
  }

  interface FileMetaData {
    name:string;
    size:string;
  }


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  ActiveKeyBoardControl: boolean,
  currentSlide: number,
  prevSlide: number,
  isVertical: boolean
  startDate: string,
  endDate: string,
  email: string,
  password: string,
  selectedStartDate: string;
  loader:any;
  selectedEndDate: string;
  modalVisible: boolean;
  project_in_Queue: Array<ProjectInQueue>
  qc_Initiated: Array<QCInitiated>;
  statusFlow: string[]
  statusData: { attributes: { color: string, section_type: string; name: string } }[]
  settingData: { name: string, direction: string, theme: string, active: boolean }
  isHorizontal: boolean
  theam: string
  dataLength: number,
  toggle:boolean,
  isBulkAction:boolean,
  inputName:string,
  statusList:statusList[],
  priority:string;
  anchorEl:any;
  selectedAction:string;
  subMenuOpen: boolean;
  uploadFilePopUp:boolean;
  subMenuAnchorEl:any;
 isTaskListSelected:boolean;
 isAssignMemberModalOpen:boolean;
 selectedUsers: any;
 memberError:string;
 users: any;
 messageToggle: boolean;
 tostifymessage: string;
 status: string;
 isFilterModal:boolean;
  clickedIdValue: string | null;
 projectId:number;
 isRoomSelected:string[];
 roomList:RoomList[];
 originalRoomList:any;
 isRoomListOpen:boolean;
 taskList:TaskList[];
 isTaskListSelectedFilter:string[];
 isTaskListOpen:boolean;
 isPriorityOpen:boolean;
 isPriorityList:PriorityList[];
 fileMetadata: any; 
 isPrioritySelected:string[];
 projectName:string;
 kanbanData:string[];
 isProjectTaskList:string[];
 isTaskListArrayValue:string[];
 roomDataSelected: string[];
 searchText:string; 
 priorityDropdownOpen:any;
 isTaskListDetailOpen:boolean;
 selectedStatus :string;
  statuses:string[];
  anchorElStatus:any;
  priorities:string[];
  anchorElPriority:any;
  anchorElPriorityUpload:any;
  selectedPriority:string;
  selectedTaskListID:string;
  currentRoomIDValue:string;
  selectedTasklistPriority: string;
  taskListValues:any;
  taskListMetaDetail: any;
  toggleID:any;
  showAll:boolean;
  showComment:boolean;
  commenttext:string;
  commentListAll:any;
  attachmentList:any;
  showAttachments:boolean;
  errorMessage:any;
  fileUrl:any;
  file:any;
  fileSizeError:any;
  commentDeletevalue:any;
  commentPopUpState:any;
  kanbanViewState:any;
  levelOneId:any;
  taskListOpen:any;
  savelistResponseValue:any;
  isCommentAttatchment:boolean;
  comment_attachmentList:any;
  openConfirmDeleteModal: boolean;
  accumulatedRoomData:any;
  sortConfig: { key: any, direction: any }
  openedTaskLists: string[];
  isTaskListCompleted: boolean;
  handleListStatus: string;
  handleListPriority: string;
  selectedSortDate: string;
  selectedListViewStatus: string;
  selectedListPriority: string;
  sortedColumn: any;
  addedmembers:any[];
  assignee_ids:any;
  getAllMembers:any;
  selectedMembers:any;
  searchFieldError:any;
  searchText2:any;
  customTextEntries:any;
  showSuggestions:boolean;
  openError:boolean;
  isSuccess:boolean;
  statusError:string;
  initialSelectedMembers:any;
  removedMembers:any;
  customSelectedMembers:any;
}

interface SS {
  id: any;

}

export default class KanbanBoardController extends BlockComponent<
  Props,
  S,
  SS
> {
  projectListApiId: string = ""
  sectionListApiId: string = ""
  settingListApiId: string = ""
  sectionAPIID: string = ""
  settingsAPIID: string = ""
  getTaskListCallId:string=""
  getProjectMembersCallId:string=""
  getRoomListCallId:string=""
  getRoomListForFilterCallId: string="";
  postAssignMemberCallId:string=""
  getProjectTaskListCallId:string=""
  changeTaskListStatusCallId:string=""
  taskListsValueId:string=""
  gettaskListIdValue:string=""
  getAllCommentListValueId:string=""
  getHitPostCommentApis:string =""
  getAttachmentListValue:string=""
  uploadFileID:string=""
  getUploadDeleteAPIs:string=""
  getSubTasklistValueID:string=""
  updatePriorityApiCallId: string = "";
  updateStatusApiCallId: string = "";
  searchTimeout: any;
  apiAddMemberCallId:any;
  apiGetMembersListCallId:string='';
  deleteAssignMemberCallId:string=''
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      ActiveKeyBoardControl: false,
      currentSlide: 0,
      prevSlide: 0,
      isVertical: false,
      startDate: '19/02/2023',
      endDate: '25/03/2023',
      email: "",
      password: "",
      selectedStartDate: '',
      selectedEndDate: '',
      modalVisible: false,
      taskListOpen:false,
      qc_Initiated: [],
      openedTaskLists: [],
      openConfirmDeleteModal: false,
      file: [],
      fileUrl:null,
      fileMetadata: null,
      errorMessage: null,
      fileSizeError:false,
      loader:false,
      project_in_Queue: [],
      statusFlow:  [],
      statusData: [],
      showAll:false,
      showComment:false,
      settingData: { name: "", direction: "", theme: "", active: false },
      isHorizontal: false,
      theam: '',
      dataLength: 0,
      toggle:true,
      isBulkAction:false,
      uploadFilePopUp:false,
      inputName:"",
      statusList:[ { status: "To-Do", count: 2, colorCode: "#60A5FA" },
      { status: "In Progress", count: 1, colorCode: "#F59E0B" },
      { status: "Completed", count: 5, colorCode: "#34D399" },
      { status: "Blocked", count: 4, colorCode: "#F87171" },],
      priority:"low",
      anchorEl:null,
      selectedAction: '',
    subMenuOpen: false,
    subMenuAnchorEl: null,
    isTaskListSelected:false,
    isAssignMemberModalOpen:false,
    selectedTaskListID:'',
    toggleID:'',
    currentRoomIDValue:'',
    selectedTasklistPriority: "",
    showAttachments:false,
    commenttext:'',
    selectedUsers: [],
    memberError:"",
    users: [],
    messageToggle: false,
    tostifymessage: "",
    status: "",
    isFilterModal:false,
    projectId:0,
    isRoomSelected:[],
    roomList:[],
    originalRoomList:[],
    isRoomListOpen:false,
    taskList:[],
    isTaskListSelectedFilter:[],
    attachmentList:[],
    isTaskListOpen:false,
    isPriorityOpen:false,
    savelistResponseValue:[],
    kanbanViewState:'Kanban View',
    isPriorityList: [
      {
        id: "1",
        Priority_name: "Low",
        PriorityBackgroundColor: "#DBEAFE",
        PriorityColor: "#2563EB",     
      },
      {
        id: "2",
        Priority_name: "Mid",
        PriorityBackgroundColor: "#FEF3C7", 
        PriorityColor: "#D97706",          
      },
      {
        id: "3",
        Priority_name: "High",
        PriorityBackgroundColor: "#FEE2E2", 
        PriorityColor: "#DC2626",        
      }
    ],
    isPrioritySelected:[],
    projectName:"",
    kanbanData:[],
    isProjectTaskList:[],
    commentListAll:[],
    isTaskListArrayValue:[],
    roomDataSelected:[],
    searchText:"",
    priorityDropdownOpen: [],
    isTaskListDetailOpen:false,
    selectedStatus:"To Do",
    statuses:["To Do", "In Progress", "Completed", "Blocked", "Archived"],
    anchorElStatus:null,
    priorities: ["Low", "Mid", "High"],
    anchorElPriority:null,
    anchorElPriorityUpload:null,
    taskListValues:[],
    taskListMetaDetail: {},
    selectedPriority:"",
    commentDeletevalue:"",
    levelOneId:"",
    clickedIdValue:"",
    commentPopUpState:false,
    isCommentAttatchment:false,
    comment_attachmentList:[],
    accumulatedRoomData:[],
    isTaskListCompleted: true,
    handleListStatus: "",
    handleListPriority: "",
    sortConfig: { key: null, direction: "asc" },
    sortedColumn: {},
    addedmembers:[],
    getAllMembers:[],
    selectedSortDate: "Due Date",
    selectedListViewStatus: "",
    selectedListPriority: "",
    selectedMembers:[],
    searchFieldError:'',
    searchText2:'',
    customTextEntries:[],
    showSuggestions:true,
      openError:false,
    isSuccess:false,
    statusError:'',
    assignee_ids:[],
    initialSelectedMembers:[],
  removedMembers:[],
  customSelectedMembers:[],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const id = this.props.navigation?.getParam("project_id");
    this.setState({
      projectId:id
    })
    this.getTaskListApi()
    this.getProjectsMembers()
    this.getProjectTaskListApi()

  

    if (Platform.OS != "web") {
      this.projectList()
      this.sectionList()
      this.settingList()
      await getStorageData("USER_TOKEN")
    } else {
      this.props.data.history.location.pathname == "/bb_taskboard" && await this.ContentAPICall() && this.DataSettingAPICall()
    }
    this.handleAddMember()
  }



async receive(from: string, message: Message) {
  runEngine.debugLog("Message Received", message);

  if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  switch (apiRequestCallId) {
    case this.projectListApiId:
      await this.projectListResponse(responseJson);
      break;

    case this.sectionListApiId:
      this.sectionListResponse(responseJson);
      break;

    case this.settingListApiId:
      this.settingListResponse(responseJson);
      break;

    case this.getTaskListCallId:
      this.handleGetTaskListResponse(responseJson);
      break;

    case this.getProjectMembersCallId:
      this.handleGetProjectMembersResponse(responseJson);
      break;

    case this.getRoomListCallId:
      this.handleGetRoomListResponse(responseJson);
      break;
      case this.getRoomListForFilterCallId:
        this.handleGetRoomListForFilterResponse(responseJson);
        break;
    case this.postAssignMemberCallId:
      this.handleAssignMemberResponse(responseJson);
      break;
    
    case this.getProjectTaskListCallId:
      this.handleProjectTaskListResponse(responseJson)
      break;
    
    case this.changeTaskListStatusCallId:
      this.handleChangeTaskListStatusResponse(responseJson)
      break;

    case this.taskListsValueId:
      this.renderTaskListResponse(responseJson) 
      break;

    case this.getAllCommentListValueId:
      this.handleAllComments(responseJson)  
      break;

    case this.getHitPostCommentApis:
      this.setState({
        commenttext:""
      })  
      this.handleCommentListAPIs()
      break;
    case this.getAttachmentListValue:
      this.handleAttachmentListStore(responseJson)
      break;  
      
    case this.uploadFileID:
     this.handleSuccedMessage()
     break;

    case this.getUploadDeleteAPIs:
    this.handleAttachmentList()
    break;

    case this.getSubTasklistValueID:
    this.handleSaveResponseforTaskList(responseJson)
    break;

    case this.gettaskListIdValue:
    this.handleUpdateComplete()
    break;

    case this.updatePriorityApiCallId:
    this.handleUpdatePriorityResponse(responseJson)
    break;

    case this.updateStatusApiCallId:
    this.handleUpdateStatusResponse(responseJson)
    break;
    case this.apiAddMemberCallId:
      this.handleData(responseJson)
    case this.deleteAssignMemberCallId:
      if(responseJson.message){
        this.setState({openError:true,statusError:'Member(s) unassigned successfully',isSuccess:true})
        setTimeout(() => {
          this.setState({ openError: false, statusError: "",  });
        }, 3000);
        this.setState({searchText:'',searchFieldError:'',selectedMembers:[],isAssignMemberModalOpen:false,removedMembers:[],customSelectedMembers:[]})
        this.getProjectTaskListApi()
        this.handleSelectedTaskList(this.state.selectedTaskListID)
    }
    default:
      runEngine.debugLog("Unhandled API request ID", apiRequestCallId);
      break;
  }
}
handleRemoveUploadedFile = (fileName: string) => {
  this.setState((prevState) => ({
    fileMetadata: prevState.fileMetadata.filter((file:any) => file.name !== fileName),
    file: prevState.file.filter((file:any) => file.name !== fileName),
  }));
};
handleChangeTaskListStatusResponse = (responseJson:any) =>{
   this.getProjectTaskListApi()
   this.setState({
    isProjectTaskList:[]
   })
}

handleUpdateComplete = () => {
  this.handleSubTaskValueData()
  this.getTaskListApi()
  this.getProjectTaskListApi()
  this.handleSelectedTaskList(this.state.selectedTaskListID)
}

  handleGetRoomListResponse = (responseJson: any) => {     
      const roomData = responseJson.data.data;
      const totalPages = responseJson.total_page
      const currentPage = responseJson.current_page 
  
      const updatedData :any= [...this.state.accumulatedRoomData, ...roomData];
  
      if (currentPage < totalPages) {
          this.getRoomListApi(currentPage + 1, updatedData);
      } else {
          const roomList = updatedData.map((room: any) => ({
              id: room.id,
              type: room.type,
              attributes: {
                  id: Number(room.id),
                  room_id: room.attributes.room_id,
                  bx_block_profile_project_id: room.attributes.bx_block_profile_project_id,
                  room_name: room.attributes.room_name,
                  start_date: room.attributes.start_date,
                  end_date: room.attributes.end_date,
                  task_list_count: room?.attributes?.total_task_list_count,
                  completed_tasks_count: room?.attributes?.completed_tasks_count
              }
          }));
  
          this.setState({
              originalRoomList: roomList,
              accumulatedRoomData: [],
          }, () => {
          });

            console.log('sdfdtrtre',this.state.originalRoomList)
      }
  };
  handleGetRoomListForFilterResponse = (responseJson: any) => {
    const roomData = responseJson.data.data;
    const updateListData: any = [...this.state.accumulatedRoomData, ...roomData];
    const roomList = updateListData.map((room: any) => ({
      id: room.id,
      type: room.type,
      attributes: {id: Number(room.id), bx_block_profile_project_id: room.attributes.bx_block_profile_project_id,
        room_name: room.attributes.room_name,
        room_id: room.attributes.room_id,
        end_date: room.attributes.end_date,
        start_date: room.attributes.start_date,
        completed_tasks_count: room?.attributes?.completed_tasks_count,
        task_list_count: room?.attributes?.total_task_list_count
      }
    }));this.setState({
      roomList: roomList,
      accumulatedRoomData: [],
    });
  };

  handleUploadFile = async () => {
    this.setState({loader:true})
    const token = await getStorageData("token")
    const header = {
      token: token,
    };

    let formData = new FormData();
    
    if(this.state.file?.length > 0){
      this.state.file?.forEach((val:any)=>{
        formData.append("files[]",val)
      })
    }
    if(this.state.isCommentAttatchment){
      formData.append("is_attachment",(this.state.isCommentAttatchment).toString())
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadFileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/projects/upload_files/${this.state.selectedTaskListID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
};

  handleUploadFileBtn = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        file: [],
        fileMetadata: [],
        uploadFilePopUp: true
      }
    })
  }
  handleCloseUploadDialog=()=>{
    this.setState((prevState)=>{
      return {
        ...prevState,
        uploadFilePopUp:false,
        file:null,
        fileMetadata:null,
        isCommentAttatchment:false
      }
    })
  }

  handleSuccedMessage=()=>{
    this.setState({
      loader:false,
      uploadFilePopUp:false,
      isCommentAttatchment: false
    })
    this.handleAttachmentList()
  }

  onDrop = (acceptedFiles: File[]) => {
    this.setState((prevState) => {
      const updatedFiles = [...(prevState.file || [])];
      const updatedMetadata = [...(prevState.fileMetadata || [])];
  
      acceptedFiles.forEach((file) => {
        const fileSizeInMB = file.size / (1024 * 1024);
        const isFileTooLarge = fileSizeInMB > 10;
        const allowedTypes = ["application/pdf", "image/png", "image/jpeg"];
        const isInvalidType = !allowedTypes.includes(file.type);
  
        // Create a file object with error messages
        const transformedFile = {
          name: file.name,
          size: `${fileSizeInMB.toFixed(2)} MB`,
          errorMessage: isInvalidType
            ? "Only PDF, PNG, and JPG files are allowed."
            : isFileTooLarge
            ? "File size must not exceed 10MB."
            : null,
        };
  
        if (!isInvalidType && !isFileTooLarge) {
          // Only read valid files
          const reader = new FileReader();
          reader.onload = () => {
            this.setState((innerState) => ({
              fileUrl: reader.result as string,
              fileMetadata: [...innerState.fileMetadata, transformedFile],
              file: [...innerState.file, file],
            }));
          };
          reader.readAsDataURL(file);
        } else {
          // Add invalid files with error messages to show in UI
          updatedMetadata.push(transformedFile);
        }
      });
  
      return {
        fileMetadata: updatedMetadata,
        file: updatedFiles,
      };
    });
  };
  
  
  

  handleGetProjectMembersResponse = (responseJson:any) =>{
    this.setState({users:responseJson.members})
  }

  handleGetTaskListResponse = (responseJson:any) =>{
    const taskData = responseJson?.data;
  
    if (Array.isArray(taskData)) {
      const taskDataList = taskData.map((task: any) => {
        return {
          id:task.id,
          type: task.type,
          attributes: {
            id: Number(task.id),
            name: task.attributes.name,
            account_id:task.attributes.account_id
          },
        };
      });
      
      this.setState({
        taskList:taskDataList
      })
    }
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (JSON.stringify(prevProps.statusFlow) != JSON.stringify(this.props.statusFlow)) {
      this.setState({ statusFlow: this.props.statusFlow })
    }
  }

  apiCallFunction = async (data: ApiData) => {
    const { contentType, method, endPoint, body, } = data;

    const header = {
      "Content-Type": contentType,
      "token": await getStorageData("USER_TOKEN")
    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return requestMessagee.messageId;
  };

  ContentAPICall = async () => {
    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectionEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  DataSettingAPICall = async () => {

    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.settingsAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.settingsEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  projectListResponse = async (responseJson: ProjectList2) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successOrderResponse(responseJson)
      }

    }
  }
  sectionListResponse = (responseJson: sectionList) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSectionListResponse(responseJson)
      }

    }
  }
  settingListResponse = (responseJson: settingList1) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSettingListResponse(responseJson)
      }

    }
  }

  projectList = async () => {
    this.projectListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_taskallocator/kanbanboard?from=${this.state.startDate}&to=${this.state.endDate}`,
    });
  }
  sectionList = async () => {
    this.sectionListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/sections`,
    });
  }
  settingList = async () => {
    this.settingListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/settings`,
    });
  }
  
  successOrderResponse(responseJson: ProjectList2) {
    this.setState(
      {
        project_in_Queue: responseJson['Project in Queue'],
        dataLength: responseJson['Project in Queue'].length
      }
    )
  }
  successSectionListResponse(responseJson: sectionList) {
    let tempArray: string[] = []
    responseJson.data.map((item: { attributes: { section_type: string } }) => {
      tempArray.push(item.attributes.section_type)
    })
    this.setState({ statusFlow: tempArray, statusData: responseJson.data })
  }

  successSettingListResponse(responseJson: settingList) {
    responseJson.data.map((item: { attributes: { active: boolean } }, index: number) => {
      if (item.attributes.active) {
        let theme = responseJson.data[index].attributes.theme
        let dirction = responseJson.data[index].attributes.direction === "vertical"
        this.setState({ theam: theme, isHorizontal: dirction })
      }
    })
  }


  goBack = () => {
    Alert.alert("Logout")
    removeStorageData("USER_TOKEN")
    const message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  getTaskListApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
 
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTaskListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetTaskListEndPoint}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleToggle = async(tab: string) => {
    const isTeamsTab = tab === "Kanban Board View";
    localStorage.setItem("selectedTab", tab);
    if(tab === "List View"){
      this.getRoomListForFilterApi()
      this.getRoomListApi();
    }
  
    this.setState({
      toggle: isTeamsTab,
      kanbanViewState:tab
    })
 
  };

  handleFilter =()=>{
    this.setState({
      isFilterModal:true
    }, () => {
      this.getRoomListForFilterApi()
    })
  }
  applySearchFilter = (searchText: string) => {
    if (!searchText.trim()) {
      this.setState({ originalRoomList: this.state.roomList }); // Show all data if search is empty
    } else {
      const filteredRooms = this.state.roomList.filter((room) =>
        room.attributes.room_name.toLowerCase().includes(searchText)
      );
      this.setState({ originalRoomList: filteredRooms });
    }
  };
  handleSearch= (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value.toLowerCase();

    this.setState({ searchText });
      if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  
    this.searchTimeout = setTimeout(() => {
      this.applySearchFilter(searchText);
      this.getProjectTaskListApi(searchText)
    }, 1000);
  this.getProjectTaskListApi()
  };

  handleMenuOpen = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, subMenuOpen: false });
  };

  handleActionClick = (action: string, event: any) => {
    this.setState({isBulkAction:true})
    if (action === 'Change Status') {
      this.setState({
        selectedAction: action,
        subMenuOpen: true,
        subMenuAnchorEl: event.currentTarget,
        isBulkAction:true
      });
      this.handleSubTaskValueData()
    } else {
      this.setState({ selectedAction: action,
        isAssignMemberModalOpen:true
       });
      this.handleMenuClose();
    }
  };

  handleSubMenuClick = async(status:string) => {
    this.setState({
      anchorElStatus:null,
      handleListStatus: status
    })
    const ids =this.state.roomDataSelected.map((item: any) => item.id);
    const roomIds = this.state.roomDataSelected.map((item: any) => item.roomId);  
    let selectedRoomId = roomIds.length>0 ? roomIds: this.state.currentRoomIDValue;
    let selectedTaskListId = ids.length>0 ? ids : this.state.selectedTaskListID
    let statusValue
    if(status === "To Do"){
        statusValue="to_do"
    }else if(status == "In Progress"){
        statusValue="in_progress"
    }else if(status == "Completed"){
      statusValue= "complete"
    }else if(status == "Blocked"){
      statusValue="deleted"
    }else if(status == "Archived"){
      statusValue="archived"
    }
    this.updateStatusApiCallId = await this.updatePriority({
      contentType:"application/json",
      endPoint: `bx_block_tasks/projects/${this.state.projectId}/update_kanban_task_list_status`,
      method: "PATCH",
      body: {
        "task_list_ids": selectedTaskListId,
        "status": statusValue
      }
    });
  };

  handleChangeProjectTaskListSelect = (id: string, roomId: string) => {
    this.setState((prevState: ComponentState) => {
      const isAlreadySelected = prevState.isProjectTaskList.includes(id);
  
      const updatedProjectTaskList = isAlreadySelected
        ? prevState.isProjectTaskList.filter((projectId:any) => projectId !== id)
        : [...prevState.isProjectTaskList, id];

      const updatedRoomDataSelected = isAlreadySelected
      ? prevState.roomDataSelected.filter((data: any) => !(data.id === id && data.roomId === roomId))
      : [...prevState.roomDataSelected, { id, roomId }];
      return {
        isProjectTaskList: updatedProjectTaskList,
        roomDataSelected: updatedRoomDataSelected,
      };
    });
  };

  handleAssignMemberResponse = (responseJson:any)=>{
     this.setState({
      isAssignMemberModalOpen:false,
      isProjectTaskList:[],
      roomDataSelected: []
     })
     this.setState({
      openError: true,
      statusError: "Member(s) successfully assigned.",
      isSuccess: true,
      selectedMembers:[],
      customTextEntries:[]
    });
    setTimeout(() => {
      this.setState({ openError: false, statusError: "",  });
    }, 3000);
    this.handleSelectedTaskList(this.state.selectedTaskListID)
    this.getProjectTaskListApi()
    this.handleSubTaskValueData(); 
  }
sendEnter=(e:any)=>{
  if (e.key === "Enter" && this.state.commenttext.trim().length > 0) {this.postCommentAPIs();} 
}

  handleAssignMember = async (memarr:any) => {
    
    const assignee_ids = memarr.map((member: any) => member.id);
    let extractedTaskIds = this.state.roomDataSelected.map((item: any) => item.id);
    console.log("Final task_ids before sending request:", extractedTaskIds);

  
   
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
let body;
if(this.state.isBulkAction){
  body = {
    "assignee_ids":assignee_ids,
    "task_ids": extractedTaskIds.length > 0 ? extractedTaskIds : [],
    "project_id": this.state.projectId,
    "assignee_names":this.state.customTextEntries
};
}
else{
     body = {
        "assignee_ids":assignee_ids,
        "task_ids":[this.state.selectedTaskListID],
        "project_id": this.state.projectId,
        "assignee_names":this.state.customTextEntries
    };
  }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAssignMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PostAddAssignEndPoint}`
    );
    requestMessage.addData(  getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );

    requestMessage.addData(  getName(MessageEnum.RestAPIRequestMethodMessage),  `PATCH`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleAddMemberModal = () =>{
    this.setState((prev) => ({
      isAssignMemberModalOpen: !prev.isAssignMemberModalOpen,
    }));
    this.setState({selectedMembers:[]})
  }

    handleFilterModal =()=>
    this.setState((prev) => ({
      isFilterModal: !prev.isFilterModal,
    }));

    getStatusBackgroundColor = (status:string) => {
      switch (status) {
        case 'To Do':
          return '#3B82F6'; 
        case 'In Progress':
          return '#FBBF24'; 
        case 'Completed':
          return '#34D399'; 
        case 'Blocked':
          return '#F87171'; 
        case 'Archived':
          return '#64748B'; 
        default:
          return ''; 
      }
    };

  getProjectsMembers = async () => {
      const token = await getStorageData("token")
      const header = {
        "Content-Type": "application/json",
        token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getProjectMembersCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.GetProjectMembersEndPoint}?project_id=${this.state.projectId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
  getRoomListApi = async (page = 1, accumulatedData = []) => {
    const isRoomSelected = this.state.roomList
    .filter((room) => this.state.isRoomSelected.includes(room.id))
    .map((room) => `pr_room_id[]=${encodeURIComponent(room.attributes.id)}`)
    .join("&");
    const queryParams = [isRoomSelected]
    .filter((param) => param)
    .join("&");
    let sortData = ""
    if(this.state.selectedSortDate== "Closest First"){
      sortData = "earliest_due_date"
    }
    if(this.state.selectedSortDate == "Furthest First") {
      sortData = "latest_due_date"
    }
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoomListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetRoomListEndPoint}/${this.state.projectId}?${queryParams}&sort_by=${sortData}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ accumulatedRoomData: accumulatedData });
  };
  getRoomListForFilterApi = async (page = 1, accumulatedData = []) => {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoomListForFilterCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetRoomListEndPoint}/${this.state.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ accumulatedRoomData: accumulatedData });
  };
  handleChangeRoomSelected = (roomId:string) => {
    const { isRoomSelected } = this.state;
    if (isRoomSelected.includes(roomId)) {
      this.setState({
        isRoomSelected: isRoomSelected.filter((id) => id !== roomId),
      });
    } else {
      this.setState({
        isRoomSelected: [...isRoomSelected, roomId],
      });
    }
  };

  handleChangeTaskListSelected = (taskId:string) => {
    const { isTaskListSelectedFilter } = this.state;
    if (isTaskListSelectedFilter.includes(taskId)) {
      this.setState({
        isTaskListSelectedFilter: isTaskListSelectedFilter.filter((id) => id !== taskId),
      });
    } else {
      this.setState({
        isTaskListSelectedFilter: [...isTaskListSelectedFilter, taskId],
      });
    }
  };

  handleChangePriority = (priorityId: string) => {
    const { isPrioritySelected } = this.state;
    if (isPrioritySelected.includes(priorityId)) {
      this.setState({
        isPrioritySelected: isPrioritySelected.filter((id) => id !== priorityId),
      });
    } else {
      this.setState({
        isPrioritySelected: [...isPrioritySelected, priorityId],
      });
    }
  };
  

  ApplyFilterHandle =()=>{
    this.setState({
      isFilterModal:false,
      openedTaskLists: []
    })
    this.getProjectTaskListApi()
    this.getRoomListApi()
  }

  handleOpenRoomList = () =>{
    this.setState({
      isRoomListOpen : !this.state.isRoomListOpen
    })
  }

  handleOpenTaskList = () =>{
    this.setState({
      isTaskListOpen:!this.state.isTaskListOpen
    })
  }

  handleOpenTaskListView = (id: string) => {
    this.setState((prevState) => {
      let isOpeningNewTaskList = prevState.clickedIdValue !== id;
  
      return {
        openedTaskLists: isOpeningNewTaskList ? [id] : [],
        clickedIdValue: isOpeningNewTaskList ? id : null,
      };
    }, () => {
      if (this.state.clickedIdValue !== null) {
        this.handleSubTaskValueData(); 
      }
    });
  };

  handleSubTaskValueData =async()=>{
    const isPriorityListFilter = this.state.isPriorityList
      .filter((priority) => this.state.isPrioritySelected.includes(priority.id))
      .map((priority) => {
        const mappedPriority =
          priority.Priority_name === "Mid" ? "medium" : priority.Priority_name.toLowerCase();
        return `priority[]=${encodeURIComponent(mappedPriority)}`;
      }).join("&");
    const isTaskListFilterSelected = this.state.taskList.filter((task) => this.state.isTaskListSelectedFilter.includes(task.id)).map((task) => `task_list_name[]=${encodeURIComponent(task.attributes.name)}`).join("&");
  const queryParams = [ isTaskListFilterSelected, isPriorityListFilter]
    .filter((param) => param)
    .join("&");
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubTasklistValueID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasklistApisEndpoint}/${this.state.clickedIdValue}?${queryParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSaveResponseforTaskList = (response: any) => {
    if (!response || !response.data) return;

    const output = response.data;
    const roomId = this.state.clickedIdValue;
    if (!roomId) return;
    this.setState((prevState) => ({
      savelistResponseValue: {
        ...prevState.savelistResponseValue,
        [roomId]: output,
      }
    }));
  }; 

  handleUpdatePriorityResponse = (
    responseJson: any
  ) => {
    if(responseJson.error){
      this.setState({openError:true,isSuccess:false,statusError:responseJson.error})
      setTimeout(() => { this.setState({ statusError: '',openError:false });}, 3000);
    }
    else  {
      this.setState({selectedPriority:this.state.handleListPriority})
    }
    this.getProjectTaskListApi();
    this.handleSubTaskValueData();
  }
  handleUpdateStatusResponse = (responseJson: any
  ) => {
    if(responseJson.error || (responseJson.errors && responseJson.errors.length > 0)){
      this.setState({openError:true,isSuccess:false,statusError:`Some task lists can't be completed as not all tasks are complete`})
      setTimeout(() => {
        this.setState({ statusError: '',openError:false });}, 3000);
    }
    else  {
      this.setState({selectedStatus:this.state.handleListStatus})
    }
      this.setState({ anchorEl: null, subMenuOpen: false, roomDataSelected: [],isProjectTaskList: []}, () => {
      this.getProjectTaskListApi();
      this.handleSubTaskValueData();
    });
  };
 formatDate=(dateString:any)=> {
    const date = new Date(dateString);
  
    const day = String(date.getDate()).padStart(2, '0'); 
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear(); 
  
    return `${day}/${month}/${year}`; 
  }
  


  handleOpenPriorityList = () =>{
    this.setState({
      isPriorityOpen:!this.state.isPriorityOpen
    })
  }

  getProjectTaskListApi = async (searchText = "") => {
    this.setState({loader:true})
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
   let sortData = ""
    if(this.state.selectedSortDate== "Closest First"){
      sortData = "earliest_due_date"
    }
    if(this.state.selectedSortDate == "Furthest First") {
      sortData = "latest_due_date"
    }
 
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProjectTaskListCallId = requestMsg.messageId;
  
    const isRoomSelected = this.state.roomList
      .filter((room) => this.state.isRoomSelected.includes(room.id))
      .map((room) => `pr_room_id[]=${encodeURIComponent(room.attributes.id)}`)
      .join("&");

    const isTaskListSelectedFilter = this.state.taskList
      .filter((task) => this.state.isTaskListSelectedFilter.includes(task.id)) 
      .map((task) => `task_list_name[]=${encodeURIComponent(task.attributes.name)}`)
      .join("&");
  
    const isPriorityList = this.state.isPriorityList
      .filter((priority) => this.state.isPrioritySelected.includes(priority.id))
      .map((priority) => {
        const mappedPriority =
          priority.Priority_name === "Mid" ? "medium" : priority.Priority_name.toLowerCase();
        return `priority[]=${encodeURIComponent(mappedPriority)}`;
      })
      .join("&");
  
    const queryParams = [isRoomSelected, isTaskListSelectedFilter, isPriorityList]
      .filter((param) => param)
      .join("&");
  
      const endpoint = `${configJSON.GetProjectTaskListEndPoint}/${this.state.projectId}/kanban_board?${queryParams}&sort_by=${sortData}&search=${encodeURIComponent(searchText)}`;
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMsg.id, requestMsg);
  
    return true;
  };

  handleProjectTaskListResponse = (responseJson: any) => {
  
    this.setState({
      projectName: responseJson.project_name,
      kanbanData: responseJson.kanban_board
    });
    this.setState({loader:false})
  };

  filterTasksByStatus = (status: string) => {
    // const { kanbanData } = this.state;
    const normalizedStatus = status.trim().toLowerCase();
    const kanbanData = Array.isArray(this.state.kanbanData) ? this.state.kanbanData : []   
     const statusMapping: { [key: string]: string } = {
      "to do": "to_do",
      "in progress": "in_progress",
      "completed": "complete",
      "blocked": "deleted",
      "archived": "archived",
    };
  
    const backendStatus = statusMapping[normalizedStatus];
    const globalRoomCount: { [key: string]: number } = {};
  
    return kanbanData.flatMap((room: any) => {
      const roomBaseName = room.room_name;
  
      if (!globalRoomCount[roomBaseName]) {
        globalRoomCount[roomBaseName] = 1;
      } else {
        globalRoomCount[roomBaseName]++;
      }
  
      const roomNameWithSeries =
        globalRoomCount[roomBaseName] === 1
          ? roomBaseName
          : `${roomBaseName} ${globalRoomCount[roomBaseName] - 1}`;
  
      return room?.task_lists_by_status
        ?.filter((list: any) => list.status === backendStatus)
        ?.flatMap((list: any) =>
          list.task_lists.map((task: any) => ({
            taskId: task.id,
            roomId: room.room_id,
            roomName: roomNameWithSeries, 
            taskName: task.name,
            priority: task.priority,
            checklist: `${task.completed_tasks_count}/${task.task_lists_count}`,
            assignee_name: task.assignee_name,
            status: list.status,
            startDate: task.start_date,
            endDate: task.end_date,
            assignee_namearray:task.assignee_name,
            assignee_ids:task.assignee_ids,
            assignee_names_added_as_text:task.assignee_names_added_as_text
          }))
        );
    });
  };
  
  handleDateSort = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
      const selectedSortDate = event.target.value as string;
      this.setState({ selectedSortDate }, () => {
        this.getProjectTaskListApi()
        this.getRoomListApi()
      });
    };

  handleStatusListView = (event: SelectChangeEvent<unknown>, selectedTasklist: any) => {
    const selectedStatus = event.target.value as string;
    this.setState({ selectedTaskListID: selectedTasklist, selectedListViewStatus:  selectedStatus}, () => {
      this.handleSubMenuClick(selectedStatus)
    })
  };
    handlePriorityListView = (event: SelectChangeEvent<unknown>, selectedTasklist: any) => {
      const selectedPriority = event.target.value as string;
      this.setState({
        selectedListPriority: selectedPriority
      }, () => {
        this.handlePriorityItemClick(selectedPriority,selectedTasklist)
      })
    };

   getPriorityColor = (priority: string, type: string): string => {
    const colorMap: { [key: string]: { [key: string]: string } } = {
      text: {
        low: "#2563EB",
        medium: "#D97706",
        high: "#DC2626",
      },
      background: {
        low: "#DBEAFE",
        medium: "#FEF3C7",
        high: "#FEE2E2",
      },
    };
  
    return colorMap[type]?.[priority] || "";
  };

handleClearAllFilter = () =>{
  this.setState({
    isPrioritySelected:[],
    isRoomSelected:[],
    isTaskListSelectedFilter:[],
    openedTaskLists: []
  },()=>{
    this.getProjectTaskListApi()
    this.getRoomListApi()
  })
}

togglePriorityDropdown = (id: string) => {
  this.setState((prevState) => ({
    priorityDropdownOpen: {
      ...prevState.priorityDropdownOpen,
      [id]: !prevState.priorityDropdownOpen[id], // TypeScript now understands this is valid
    },
  }));
};

handleDrop = (event: any, status: any) => {
  const storyId = event.dataTransfer.getData("storyId");
  this.setState({selectedTaskListID: storyId}, () => {
    this.handleSubMenuClick(status)
  })
};

 handleDragStart = (event: any, storyId: any) => {
  event.dataTransfer.setData("storyId", storyId);
};

handlePriorityChange = async(id:string, roomId:string, newPriority:string) => {
  this.setState((prevState) => ({
    priorityDropdownOpen: {
      ...prevState.priorityDropdownOpen,
      [id]: false,
    },
  }), () => {
    this.handlePriorityItemClick(newPriority, id)
  });

  const token = await getStorageData("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,};

  const body = {
      "room_id":roomId,
      "task_list_id": id,
      "priority": newPriority.toLowerCase()
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.changeTaskListStatusCallId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.patchChangeStatusEndPoint}/${this.state.projectId}/update_priority`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.httpPatchMethod}`
  );
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
};

handleTaskListDetailCard = (id:any,roomId:any,priority?: any, status?: string
) =>{
  this.setState((prev) => ({
    isTaskListDetailOpen: !prev.isTaskListDetailOpen,
    selectedTaskListID:id,
    currentRoomIDValue:roomId,
    selectedPriority: priority,
    selectedStatus:  this.getFormatedStatus(status),
    isBulkAction:false
  }));
  this.handleSelectedTaskList(id)
  this.handleCommentListAPIs()
  this.handleAttachmentList()
}
  getFormatedStatus = (status: any) => {
    switch (status) {
      case "to_do":
        return "To-Do";
      case "in_progress":
        return "In Progress";
      case "complete":
        return "Completed";
      case "deleted":
        return "Blocked";
      case "archived":
        return "Archived";
      default:
        return "transparent";
    }
  }
handleRefactorData =()=>{
     this.state.kanbanData?.map((val:any)=>{
        if(val?.room_id === this.state.currentRoomIDValue ){
             return val
        }
     })
}
 handleMenuClickDetail = (event:any) => {
  this.setState({
    anchorElStatus:event.currentTarget
  })
};

 handleMenuItemClickDetail = (status:string) => {
  this.setState({
    anchorElStatus:null,
    selectedStatus:status
  })
};
handleClose = () => {
  this.setState({
    anchorElStatus:null
  })
};
calculateProgress = (completedTasks:any, totalTasks:any) => {
  return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
};
getCompletedTasks = (attributes:any) => {
  return attributes?.completed_tasks_count || 0;
};

getTotalTasks = (attributes:any) => {
  return attributes?.tasks_count || 0;
};
renderTaskListResponse=(value:any)=>{
  const responseValue = value.data?.data.attributes.project_tasks;

  this.setState({
    taskListValues:responseValue ? responseValue : [],
    taskListMetaDetail: value.data?.data
  }
  )}

handleSelectedTaskList=async(idValue:any)=>{
  const token =  await getStorageData("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };


  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.taskListsValueId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.kanboardTaskListAPIs}/${idValue}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );

  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.validationApiMethodType}`
  );
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
}

getStatusBackgroundColorDetail = (status:string) => {
  switch (status) {
    case "To Do":
      return "#3B82F6";
    case "In Progress":
      return "#FBBF24";
    case "Completed":
      return "#34D399";
    case "Blocked":
      return "#F87171";
    case "Archived":
      return "#64748B";
    case "To-Do":
      return "#3B82F6";
    default:
      return "transparent";
  }
};

 handlePriorityClick = (event:any) => {
  this.setState({
    anchorElPriority:event.currentTarget
  })
};

 handlePriorityItemClick = async (priority:string, id?: any) => {
  let tasklistId = this.state.selectedTaskListID ? this.state.selectedTaskListID: id
   let setPriority = "";
   if (priority === "Low") {
     setPriority = "low"
   };

   if (priority === "Mid") {
     setPriority = "medium"
   };

   if (priority === "High") {
     setPriority = "high"
   };
  this.setState({
    anchorElPriority:null,
    handleListPriority:priority
  })
   this.updatePriorityApiCallId = await this.updatePriority({
        contentType:"application/json",
        endPoint: `bx_block_tasks/projects/${String(tasklistId).trim()}/update_priority`,
        method: "PATCH",
        body: {
            "priority": setPriority
        }
      });
};

updatePriority = async (ApiCallData: any) => {
  const { contentType, method, endPoint, body } = ApiCallData;
  const token = await getStorageData("token");
  const header = {
    'Content-Type': contentType,
    "token": token
  };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  body &&  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

 handlePriorityClose = () => {
  this.setState({
    anchorElPriority:null
  })
};
 getPriorityBackgroundColor = (priority:string) => {
  switch (priority) {
    case "Low":
      return "#DBEAFE";
    case "Mid":
      return "#FEF3C7";
    case "High":
      return "#FEE2E2";
    case "low":
      return "#DBEAFE";
    case "medium":
      return "#FEF3C7";
    case "high":
      return "#FEE2E2";
    default:
      return "transparent";
  }
};

getPriorityColorStatus = (priority:string) => {
  switch (priority) {
    case "Low":
      return "#2563EB";
    case "Mid":
      return "#D97706";
    case "High":
      return "#DC2626";
    case "low":
      return "#2563EB";
    case "medium":
      return "#D97706";
    case "high":
      return "#DC2626";
    default:
      return "transparent";
  }
};
getPriorityText = (priority:string) => {
  switch (priority) {
    case "low":
      return "Low";
    case "medium":
      return "Mid";
    case "high":
      return "High";
    default:
      return priority;
  }
};
getMouseEvent = (inputValue: any) => {
inputValue.currentTarget.style.maxWidth = "none"
};
getMouseLeaveEvent = (inputValue: any) => {
  inputValue.currentTarget.style.maxWidth = "195px"
}
  
toggleItem = (index: any) => {
    if (this.state.toggleID === index) {
      this.setState({
        toggleID: ''
      })
    }
    else {
      this.setState({
        toggleID: index
      });
    }
  };
handleCloseAction = () => {
  this.setState({ anchorElPriority: null})
};
handleChangeTaskListSelect = (id: string, roomId: string) => {
  this.setState((prevState: ComponentState) => {
    const isAlreadySelected = prevState.isTaskListArrayValue.includes(id);

    const updatedProjectTaskList = isAlreadySelected
      ? prevState.isTaskListArrayValue.filter((projectId:any) => projectId !== id)
      : [...prevState.isTaskListArrayValue, id];

    // Update roomDataSelected array
    const updatedRoomDataSelected = isAlreadySelected
      ? prevState.roomDataSelected.filter((data:any) => data.roomId !== roomId)
      : [...prevState.roomDataSelected, { id, roomId }];

    return {
      isTaskListArrayValue: updatedProjectTaskList,
    };
  });
};

handleCheckStatus =async(id:any)=>{
   const token = await getStorageData("token");
   const header = {
     "Content-Type": configJSON.validationApiContentType,
     token: token,};
 
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
 
   this.gettaskListIdValue = requestMessage.messageId;
   
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.kanbanBoardPutAPIs}/${id}/mark_task_complete`);
 
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header) );
 
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.httpsPutMethod}`
   );
   runEngine.sendMessage(requestMessage.id, requestMessage
   );

}

toggleShowAllTasks = () => {
  this.setState(prevState => ({
    showAll: !prevState.showAll,
    showAttachments:false,
    showComment:false
  }));
}

toggleShowComments=()=>{
  this.setState(prevState => ({
    showAll:false,
    showAttachments:false,
    showComment: !prevState.showComment,
  }));
}

toggleShowAttachment=()=>{
  this.setState((prevState)=>({
    showAll:false,
    showComment:false,
    showAttachments:!prevState.showAttachments
  }))
}

handleTypeComment =(value:any)=>{
  this.setState({
    commenttext:value
  })
}

handleCommentListAPIs=async()=>{
  const token = await getStorageData("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllCommentListValueId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getCommentListAPIs}${this.state.selectedTaskListID}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.validationApiMethodType}`
  );
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
}

handleAllComments=(value:any)=>{
   const outPutValue  =value.data?.data;
   this.setState({
    commentListAll:outPutValue ? outPutValue : []
   })
}

handlePopUpOpen=(e:any,val:any)=>{
   this.setState({
    anchorElPriorityUpload:e.currentTarget,
    commentDeletevalue:val?.attributes?.blob_id,
    commentPopUpState:true
   })
}
downloadFile = (fileUrl:any) => {
  this.setState({anchorElPriorityUpload:null})
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileUrl.split("/").pop(); 
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
handleDeleteUploadedFile=async()=>{
  this.setState({
    anchorElPriorityUpload:null, openConfirmDeleteModal: false  })
  const token = await getStorageData("token");

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getUploadDeleteAPIs = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deleteCommentApis}${this.state.commentDeletevalue}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.httpsDeleteMethod}`
  );

  runEngine.sendMessage(requestMessage.id, requestMessage
  );
}


postCommentAPIs=async()=>{
  const token = await getStorageData("token");

  const payload ={
    "project_tasklist_id":this.state.selectedTaskListID,
    "tasklist_comment":{
      "comment":`${this.state.commenttext}`
    }
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getHitPostCommentApis = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.commentsPostAPis}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.httpPostMethod}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );
   
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
  
}

handleAttachmentListStore=(value:any)=>{
  const dataStore = value.data?.data || [];
  
    const attachmentList = dataStore.filter((item: any) => !item.attributes.is_attachment);
    const comment_attachmentList = dataStore.filter((item: any) => item.attributes.is_attachment);
  
    this.setState({
      attachmentList,
      comment_attachmentList
    });
}

closeActionTab = () => {
this.setState({anchorElPriorityUpload: null})
};

openConfirmDelete = () => {
  this.setState({openConfirmDeleteModal: true});
};

closeDeleteModal = () => {
  this.setState({openConfirmDeleteModal: false});
}

formatDateResponse = (dateString:any) => {
  const date = new Date(dateString);
  const optionsDate: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);

  return `${formattedDate} ${formattedTime}`;
};

handleAttachmentList=async()=>{
  const token = await getStorageData("token");

  const payload ={
    "project_tasklist_id":this.state.selectedTaskListID,
    "tasklist_comment":{
      "comment":`${this.state.commenttext}`
    }
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAttachmentListValue = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.attachmentListValues}/${this.state.selectedTaskListID}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.validationApiMethodType}`
  );
   
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
}
handleAddMember=async ()=>{
  let token =await getStorageData("token")
 
  const header = {
    "Content-Type": 'application/json',
    token: token
  };
  const body ={
    project_id: this.state.projectId,
    member_ids: [this.state.selectedTaskListID]
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiAddMemberCallId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_profile/project_teams_and_members/add_members`
  );
  
  requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
  );

  requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
  );
  
  requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),'POST'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}
handleData=(response:any)=>{
  console.log('respon',response)
}
  clearSearch = () => {
    this.setState({ searchText2: "", selectedMembers: [], searchFieldError: '', searchText: "" }, () => {
      this.getProjectTaskListApi()
      this.getRoomListApi()
    });
  };

handleAddText = () => {
  const { searchText2, customTextEntries } = this.state;
  if (searchText2.trim() !== "") {
    this.setState({
      customTextEntries: [...customTextEntries, searchText2.trim()],
      searchText2: "", // Clear input field
      showSuggestions: false,
    });
  }
};
removeCustomText = (index: number) => {
  this.setState((prevState) => ({
    customTextEntries: prevState.customTextEntries.filter((_:any, i:any) => i !== index),
  }));
};

toggleSelectMember = (member: any) => {
  this.setState((prevState: any) => {
    const { selectedMembers, assignee_ids, removedMembers, customSelectedMembers } = prevState;
    const addedmembers = Array.isArray(prevState.addedmembers) ? prevState.addedmembers : [];

    const isSelected = selectedMembers.some((m: any) => m.id === member.id);
    const isAddedAsObject = addedmembers.some((m: any) => m.account_id === member.id);
    const isAddedAsString =
      addedmembers.every((m: any) => typeof m === "string") &&
      assignee_ids.includes(member.id);

    let updatedAddedMembers = [...addedmembers];
    let updatedAssigneeIds = Array.isArray(assignee_ids) ? [...assignee_ids] : [];
    let updatedSelectedMembers = [...selectedMembers];
    let updatedCustomSelectedMembers = [...customSelectedMembers];
    let updatedRemovedMembers = Array.isArray(removedMembers) ? [...removedMembers] : [];

    if (member.isCustom) {
      if (!customSelectedMembers.some((m: any) => m.id === member.id)) {
        updatedCustomSelectedMembers.push(member);
      } else {
        updatedCustomSelectedMembers = updatedCustomSelectedMembers.filter((m: any) => m.id !== member.id);
      }
    } else {
      if (isSelected || isAddedAsObject || isAddedAsString) {
        updatedSelectedMembers = selectedMembers.filter((m: any) => m.id !== member.id);

        if (isAddedAsObject) {
          updatedAddedMembers = addedmembers.filter((m: any) => m.account_id !== member.id);
          updatedRemovedMembers.push(member.id);
        } else {
          updatedAddedMembers = addedmembers.filter((name: any) => name !== member.name);
        }

        updatedAssigneeIds = assignee_ids.filter((id: any) => id !== member.id);
      } else {
        updatedSelectedMembers.push(member);
      }
    }

    return {
      addedmembers: updatedAddedMembers,
      assignee_ids: updatedAssigneeIds,
      selectedMembers: updatedSelectedMembers,
      removedMembers: updatedRemovedMembers,
      customSelectedMembers: updatedCustomSelectedMembers, 
    };
  });
};



removeChipMember = (id: number) => {

  this.setState((prevState) => ({

    selectedMembers: prevState.selectedMembers.filter((m:any) => m.id !== id),

    searchText2: "",

    searchFieldError:''

  }));

};

handleKeyDownMember = (event:any) => {

  const { searchText2, selectedMembers, users } = this.state;



  if (event.key === "Backspace" && searchText2 === "" && selectedMembers.length > 0) {

    const updatedSelectedMembers = [...selectedMembers];

    updatedSelectedMembers.pop();

    this.setState({ selectedMembers: updatedSelectedMembers });

  }



  if (event.key === "Enter") {

    const filteredMembers = users.filter((member:any) =>

      member.first_name.toLowerCase().includes(searchText2.toLowerCase())

    );



    if (filteredMembers.length > 0) {

      this.toggleSelectMember(filteredMembers[0]);

      this.setState({ searchText2: "" });

    }

  }

};

getMemberPlaceholder = () => {

  const { selectedMembers } = this.state;

  return selectedMembers.length === 0 && this.state.customTextEntries.length==0

    ? "Search by name or email..."

    : "";

};

handleAddButtonClick = () => {

  const { selectedMembers } = this.state;

  if(this.state.removedMembers.length!=0 || this.state.customSelectedMembers.length!=0){

    this.removerMember()
  }
  if(selectedMembers.length != 0 || this.state.customTextEntries.length != 0){

    this.handleAssignMember(selectedMembers);
  }

};

getFilteredMember(members: any[], selectedMembers: any[]) {
  const memberssss = Array.isArray(this.state.addedmembers) ? this.state.addedmembers : []
  const additionalMembers = memberssss
    .filter((member: any) => member.assignee_name)
    .map((member: any) => ({
      id: member.id,
      account_id: member.account_id,
      first_name: member.assignee_name,
      email: "", 
      profile_picture: member.profile_picture,
      isCustom: true, 
    }));

  const mergedMembers = [...members, ...additionalMembers];

  return mergedMembers.filter(
    (member, index, self) => index === self.findIndex((m) => m.id === member.id)
  );
}


handleSearch2 = (event: React.ChangeEvent<HTMLInputElement>) => {

  this.setState({ searchText2: event.target.value ,showSuggestions: true,});

};
removerMember=async ()=>{
  const textMem = this.state.customSelectedMembers.map((item:any)=>item.first_name)
  const token = await getStorageData("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  let body={
    "task_id": [this.state.selectedTaskListID],
    "assignee_ids": this.state.removedMembers,
    "assignee_names": textMem
  }


  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deleteAssignMemberCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_tasks/task_lists/remove_assignee`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `DELETE`
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

}



// Customizable Area End
