import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
interface ProjectProps {
  clientName: string;
  projectName: string;
  room: string;
  amount: number;
  dueDate: string;
  status: "UPCOMING" | "PAID" | "UNPAID"; 
}
interface InvoiceData {
  invoices: {
    data: Invoice[];
  };
  meta: MetaData | undefined;
}
interface Invoice {
  id: string;
  type: string;
  attributes: InvoiceAttributes;
}
interface InvoiceAttributes {
  total_amount: number;
  due_date: string;
  payment_status: "upcoming" | "paid" | "unpaid";
  project_name: string;
  client_name: string;
  rooms: Room[];
}
interface Room {
  id: number;
  room_name: string;
}
interface MetaData {
  message: string;
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}
interface InvoiceSummary {
  data: {
    total_paid_amount: number;
    total_unpaid_amount: number;
    total_upcoming_amount: number;
    paid_invoices_count: number;
    unpaid_invoices_count: number;
    upcoming_invoices_count: number;
    total_invoices_count: number;
  };
  meta: {
    message: string;
  };
}

export interface ApiCallInterface {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Object;
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  invoiceData: InvoiceData;
  anchorEl:  HTMLButtonElement | null ;
  openDownloadModal: boolean;
  openCreateInvoiceModal: boolean;
  anchorDueDate: any;
  filterModalOpen: boolean;
  fromDateAnchorEl: HTMLElement | null;
  toDateAnchorEl:  HTMLElement | null;
  fromDate: Date | null;
  toDate: Date | null;
  invoiceSummary: InvoiceSummary;
  totalInvoiceCount: number;
  totalMembers:number;
  currentPage:number;
  rowsPerPage:number;
  prevPage:number;
  searchValue: string;
  filteredInvoices: InvoiceData;
  allProjectName : string [];
  filteredProjectName: Array<string>;
  selectedProjectName : string[]
  allClientName : string []
  filteredClientName: Array<string>;
  selectedClientName : string[];
  filterMessage : string;
  expandedAccordion : string;
  isClientChecked : boolean;
  selectedStatus : string[];
  invoiceNameCI : string;
  projectNameCI : string;
  projectIdCI : string;
  amountCI : string;
  dueDateCI : string;
  statusCI : string;
  projectNameandID : any;
  invoiceNameError : string;
  projectIdError : string;
  amountError : string;
  statusError : string;
  dueDateFilterError : string;
  dueDateErrorCI : string;
  amountErrorBlank : string;
  invoiceNameLimitError: string;
  filterCount : string;
  createButtonHighlight : boolean;
  role : string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  DueDate = ["Today", "This Month", "This Week", "Next 30 days"]
  getOverAllProjectApiCallId: string = "";
  getOverAllInvoiceDataApiCallId: string = "";
  getAllProjectandClientName : string = "";
  postCreateInvoiceApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      openCreateInvoiceModal: false,
      invoice: "",
      invoiceError: "",
      fromDateAnchorEl: null,
      toDateAnchorEl: null,
      invoicePdf: "",
      anchorEl: null,
      anchorDueDate: null,
      openDownloadModal: false,
      filterModalOpen: false,
      toDate: null,
      fromDate: null,
      currentPage: 1,
      rowsPerPage: 10,
      totalMembers: 0,
      prevPage: 0,
      invoiceSummary: {
        data: {
          total_paid_amount: 0,
          total_unpaid_amount: 0,
          total_upcoming_amount: 0,
          paid_invoices_count: 0,
          unpaid_invoices_count: 0,
          upcoming_invoices_count: 0,
          total_invoices_count: 0
        },
        meta: {
          message: ""
        }
      },
      filteredInvoices: {
        invoices: {
          data: []
        },
        meta: undefined
      },
      totalInvoiceCount: 0,
      invoiceData: {
        invoices: {
          data: []
        },
        meta: undefined
      },
      searchValue: "",
      selectedProjectName: [],
      allProjectName: [],
      filteredProjectName: [],
      allClientName : [],
      filteredClientName: [],
      selectedClientName : [],
      filterMessage : "",
      expandedAccordion : "",
      isClientChecked : false,
      selectedStatus : [],
      invoiceNameCI: "",
      projectNameCI: "",
      amountCI : "",
      dueDateCI: '',
      statusCI: "",
      projectNameandID : [],
      projectIdCI : "",
      invoiceNameError: "",
      projectIdError: "",
      amountError : "",
      statusError : "",
      dueDateFilterError : "",
      dueDateErrorCI : "",
      amountErrorBlank : "",
      invoiceNameLimitError : "",
      filterCount : "",
      createButtonHighlight : false,
      role : "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse1 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getOverAllProjectApiCallId) {
          this.overAllInvoiceSummary(responseJson1)
    }
      if (apiRequestCallId === this.getOverAllInvoiceDataApiCallId) {
        this.overAllInvoiceDataResponse(responseJson1)
      }

      if (apiRequestCallId === this.getAllProjectandClientName) {
        this.setState({projectNameandID : responseJson1.project_data.data})
        if (responseJson1.project_data && responseJson1.project_data.data) {
            const projectSet: Set<string> = new Set(
                responseJson1.project_data.data.map((item: any) => item.attributes.project_name.project_name as string)
            );
            const clientSet: Set<string> = new Set(
                responseJson1.project_data.data.map((item: any) => item.attributes.project_name.client_name as string)
            );
            const projectArray: string[] = Array.from(projectSet);
            const clientArray: string[] = Array.from(clientSet);
            this.setState({ 
                allProjectName: projectArray, 
                filteredProjectName: projectArray, 
                allClientName: clientArray, 
                filteredClientName: clientArray 
            });
        }
    }
      if (apiRequestCallId === this.postCreateInvoiceApiCallId) {
        if (Array.isArray(responseJson1?.errors?.invoice_number) && responseJson1?.errors?.invoice_number[0] === "can't be blank") {
            this.setState({
              invoiceError: "Invoice Name can not be blank"
            });
        }
        if (Array.isArray(responseJson1?.errors?.invoice_number) && responseJson1?.errors?.invoice_number[0] === "must be unique within the account") {
          this.setState({
            invoiceError: "Invoice Name must be unique within the account"
          });
        }
        if (Array.isArray(responseJson1?.errors?.due_date) && responseJson1?.errors?.due_date[0] === "can't be blank") {
          this.setState({
            dueDateErrorCI : "Due Date can not be blank"
          });
        }
        if (responseJson1?.meta?.message == "No project found !") {
          if(this.state.invoiceNameCI === ""){
            this.setState({invoiceError : "Invoice Name can not be blank"})
          }
          if(this.state.dueDateCI === ""){
            this.setState({dueDateErrorCI : "Due Date can not be blank"})
          }
          this.setState({ projectIdError: "Select the Project Name" })
        }
        if (responseJson1?.meta?.message === "Invoice created Successfully...") {
          toast.success("New invoice has been created successfully.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            theme: "light",
            style: {
              width: "auto", 
              maxWidth: "unset",
              whiteSpace: "nowrap",
            },
          });
          this.setState({ openCreateInvoiceModal: false, projectIdCI: "", projectNameCI: "", invoiceNameCI: "", amountCI: "", dueDateCI: '', statusCI: "", invoiceError: "", projectIdError: "", amountError: "", statusError: "", amountErrorBlank : "", invoiceNameLimitError : "", dueDateErrorCI :"" })
          this.getOverAllInvoiceData()
          this.getOverAllProjectSummary()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const role = await getStorageData("role");
    this.setState({role})
   this.getOverAllProjectSummary();
   this.getOverAllInvoiceData();
   this.getAllProjectName()
  }

  overAllInvoiceSummary = (responseJson1: InvoiceSummary) => {
    this.setState({ invoiceSummary: responseJson1, totalInvoiceCount: responseJson1.data.total_invoices_count });
  };
  overAllInvoiceDataResponse = (responseJson: InvoiceData) => {
    if (responseJson.invoices && responseJson.meta) {
      const totalRecords = responseJson?.meta?.total_count || 0;
      this.setState({ filterMessage : "",invoiceData: responseJson, filteredInvoices: responseJson, totalMembers: totalRecords });
    }
    else if(responseJson.meta?.message === "No projects available. Create a new project."){
      this.setState({filterMessage : responseJson.meta.message})
    }
  };
 onChangeInvoice = (text: string) => {
    if (text === "") {
      this.setState({
        invoice: text,
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ invoice: text, invoiceError: "" });
    }

  };

  getPdfLink = () => {
    if (this.state.invoice === "" || this.state.invoice.length !== 6) {
      this.setState({
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.pdfApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.exampleAPiEndPoint + this.state.invoice
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event?.currentTarget })
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  };

  openDownloadModal = () => {
    this.setState({
      openDownloadModal: true, anchorEl: null
    })
  };

  openCreateInvoiceModal = () => {
    this.setState({
      openCreateInvoiceModal: true
    })
  };
  closeCreateInvoiceModal = () => {
    this.setState({
      openCreateInvoiceModal: false,
      projectIdCI: "", projectNameCI: "", invoiceNameCI: "", amountCI: "", dueDateCI: '', statusCI: "", invoiceError: "", projectIdError: "", amountError: "", statusError: "", amountErrorBlank : "", invoiceNameLimitError : "", dueDateErrorCI :""
    })
  };
  closeDownloadModal = () => {
    this.setState({
      openDownloadModal: false
    })
  };
  formatDate = (date: Date | null): string => {
    if (!date) return "";
    return date.toLocaleDateString("en-US", {
      month: "short", 
      day: "numeric", 
      year: "numeric"
    });
  };

  handleCloseFilters = () => {
    this.setState({filterModalOpen: false});
  };

  handleOpenFilter = () => {
    this.setState({filterModalOpen: true, filteredClientName : this.state.allClientName, filteredProjectName : this.state.allProjectName});
  }

  checkStatusColor = (status: string) => {
    if (status == "PAID") {
      return "#059669"
    }
    if (status == "UPCOMING") {
      return "#D97706"
    }
    if (status == "UNPAID") {
      return "#DC2626"
    }
  };
  checkStatusBgColor = (status: string) => {
    if (status == "PAID") {
      return "#D1FAE5"
    }
    if (status == "UPCOMING") {
      return "#FEF3C7"
    }
    if (status == "UNPAID") {
      return "#FEE2E2"
    }
    
  };
  openFromDatePopUp = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({fromDateAnchorEl: event?.currentTarget, dueDateFilterError : ""})
  };

  openToDatePopUp = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({toDateAnchorEl: event?.currentTarget, dueDateFilterError : ""})
  };

  selectFromDate= (date: Date | null) => {
    this.setState({
      fromDate: date
    }, () => {
      this.closeFromDatePicker()
    })
  };

  selectToDate = (date: Date | null) => {
    this.setState({
      toDate: date
    }, () => {
      this.closeToDatePicker()
    })
  };

  closeFromDatePicker = () => {
    this.setState({ fromDateAnchorEl: null });
  };

  closeToDatePicker = () => {
    this.setState({ toDateAnchorEl: null });
  };
  
  clearDateRange = () => {
    this.setState({ fromDate: null, toDate: null });
  };

  convertDate = (dateString : any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

  getOverAllInvoiceData = async () => {
    if ((this.state.fromDate === null && this.state.toDate === null) || (this.state.fromDate !== null && this.state.toDate !== null)) {
      let endPoint = `bx_block_invoice/project_room_invoices?per_page=${this.state.rowsPerPage}&page=${this.state.currentPage}`;

      if (this.state.selectedProjectName.length > 0) {
        const projectNamesQuery = this.state.selectedProjectName
          .map(name => `project_name[]=${encodeURIComponent(name)}`)
          .join("&");

        endPoint += `&${projectNamesQuery}`;
      }

      if (this.state.fromDate !== null && this.state.toDate !== null) {
        const fromDateQuery = (`start_date=${encodeURIComponent(this.convertDate(this.state.fromDate))}`)
        endPoint += `&${fromDateQuery}`;

        const toDateQury = `end_date=${encodeURIComponent(this.convertDate(this.state.toDate))}`
        endPoint += `&${toDateQury}`;
      }

      if (this.state.selectedStatus.length > 0) {
        const statusQuery = this.state.selectedStatus
          .map(status => `status[]=${encodeURIComponent(status)}`)
          .join("&");

        endPoint += `&${statusQuery}`;
      }

      if (this.state.selectedClientName.length > 0) {
        const clientNamesQuery = this.state.selectedClientName
          .map(name => `client_name[]=${encodeURIComponent(name)}`)
          .join("&");

        endPoint += `&${clientNamesQuery}`;
      }

      this.getOverAllInvoiceDataApiCallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        endPoint: endPoint,
        method: "GET",
      });
    }else{
      this.setState({dueDateFilterError : "From Date and To Date both are required"})
    }
  };

  getOverAllProjectSummary = async () => {
    this.getOverAllProjectApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: `bx_block_invoice/project_room_invoices/invoice_summary`,
      method: "GET",
    });
  }

  getAllProjectName = async () => {
    this.getAllProjectandClientName = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: `/bx_block_invoice/project_room_invoices/project_name_list`,
      method: "GET",
    });
  };

  postCreateInvoice = async () => {
    this.postCreateInvoiceApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: `/bx_block_invoice/project_room_invoices`,
      method: "POST",
      body : {
        "invoice":{
        "bx_block_profile_project_id": Number(this.state.projectIdCI),
        "invoice_number": this.state.invoiceNameCI,
        "payment_status": this.state.statusCI.toLowerCase(),
        "total_amount": Number(this.state.amountCI),
        "due_date": this.convertDate(this.state.dueDateCI)
    }
      }
    });
  };

  updateCreateButtonState = () => {
    const { invoiceNameCI, amountCI, projectIdCI, dueDateCI, statusCI } = this.state;

    const isFormComplete = invoiceNameCI && amountCI && projectIdCI && dueDateCI && statusCI;

    if (isFormComplete) {
      this.setState({ createButtonHighlight: true });
    } else {
      this.setState({ createButtonHighlight: false });
    }
  };

  

  handleCreateInvoice = () => {
    let hasError = false;

    if (this.state.amountCI === "") {
      this.setState({ amountErrorBlank: "Amount cannot be blank" });
      hasError = true;
    } else {
      this.setState({ amountErrorBlank: "" });
    }

    if (this.state.invoiceNameCI === "") {
      this.setState({ invoiceError: "Invoice Name can not be blank" });
      hasError = true;
    }

    if (this.state.projectIdCI === "") {
      this.setState({ projectIdError: "Select the project name" });
      hasError = true;
    }

    if (this.state.dueDateCI === "") {
      this.setState({ dueDateErrorCI: "Due date can not be blank" });
      hasError = true;
    }

    if (this.state.statusCI === "") {
      this.setState({ statusError: "Select payment status" });
      hasError = true;
    }

    if (!hasError) {
      this.postCreateInvoice();
    }
  };

  
  apiCall = async (ApiCallData: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = ApiCallData;
    const token = await getStorageData("token");
    const header = {
      'Content-Type': contentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getPaidProgress = (invoiceSummary: any) => {
    return (invoiceSummary?.paid_invoices_count / invoiceSummary?.total_invoices_count) * 100
  };
  getUnpaidProgress = (invoiceSummary: any) => {
    return (invoiceSummary?.unpaid_invoices_count / invoiceSummary?.total_invoices_count) * 100
  };
  getUpcomingProgress = (invoiceSummary: any) => {
    return (invoiceSummary?.upcoming_invoices_count / invoiceSummary?.total_invoices_count) * 100
  };
  getFormatedDueDate = (dateString: string | null | undefined): string => {
    if (!dateString) return "N/A"; 
    const parts = dateString.split("-");
    if (parts.length !== 3) return "Invalid Date";
    const [year, month, day] = parts;
    return `${month}/${day}/${year}`;
  };
  
  setMembersPage = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.fetchMembersData();
    });
  };
  
  generateMembersPageNumbers = (
    currentPage: number,
    totalPages: number,
    maxPageButtons: number
  ) => {
    if (totalPages <= maxPageButtons)
      return Array.from({ length: totalPages }, (_, i) => i + 1);

    if (currentPage <= 2) return [1, 2, "...", totalPages];
    if (currentPage >= totalPages - 1)
      return [1, "...", totalPages - 1, totalPages];

    return [
      1,
      "...",
      currentPage - 1,
      currentPage,
      currentPage + 1,
      "...",
      totalPages,
    ];
  };
  
  fetchMembersData = () => {
    this.getOverAllInvoiceData()
  };

  handleNextMemberPage = () => {
    const { currentPage, rowsPerPage, totalMembers } = this.state;
    const totalPages = Math.ceil(totalMembers / rowsPerPage);
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      this.setState({ currentPage: nextPage, prevPage: currentPage }, () => {
        this.fetchMembersData();
      });
    }
  };

  handlePreviousMemberPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      this.setState({ currentPage: previousPage, prevPage: previousPage }, () => {
        this.fetchMembersData();
      });
    }
   };

   handleSearch = (event: any) => {
    const normalizeText = (text: string) => text.replace(/[\u2013\u2014]/g, "-").toLowerCase(); 
    const searchValue = event.target && normalizeText(event.target.value);
    this.setState({ searchValue }, () => {
        if (searchValue === "") {
            this.setState({ filteredInvoices: this.state.invoiceData });
            return;
        }

        const filteredInvoices: any = this.state.invoiceData.invoices.data.filter((invoice) =>
            invoice.attributes.rooms.some((room) =>
                normalizeText(room.room_name).includes(searchValue)
            )
        );

        this.setState({ 
            filteredInvoices: { 
                invoices: { data: filteredInvoices }, 
                meta: this.state.invoiceData.meta 
            } 
        });
    });
};

  

  clearSearch = () => {
    this.setState({searchValue : ""})
    this.getOverAllInvoiceData()
  }

  
  handleFilterProjectName = (projectName: string) => {
    this.setState((prevState: any) => {
      const isAlreadySelected = prevState.selectedProjectName.includes(projectName);
      const updatedSelectedProjects = isAlreadySelected
        ? prevState.selectedProjectName.filter((name: string) => name !== projectName)
        : [...prevState.selectedProjectName, projectName];

      return { selectedProjectName: updatedSelectedProjects };
    });
  };

  handleProjectSearch = (value: string) => {
    if (value === "") {
      this.setState({ filteredProjectName: this.state.allProjectName });
    } else {
      let filterOutput = this.state.allProjectName.filter((projectName) =>
        projectName.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ filteredProjectName: filterOutput });
    }
  };

  handleApplyButton = async () => {
    await this.getOverAllInvoiceData();
    if ((this.state.fromDate === null && this.state.toDate === null) || (this.state.fromDate !== null && this.state.toDate !== null)){
      this.setState({ filterModalOpen: false, filteredClientName : [], filteredProjectName : []});
    }
    const dateFilterCount = this.state.fromDate !== null && this.state.toDate !== null ? 1 : 0;

    this.setState({filterCount: (this.state.selectedClientName.length + 
      this.state.selectedProjectName.length + 
      this.state.selectedStatus.length + dateFilterCount).toString()}) 
  };

  handleFilterClientName = (clientName: string) => {
    this.setState((prevState: any) => {
      const isAlreadySelected = prevState.selectedClientName.includes(clientName);
      const updatedSelectedClients = isAlreadySelected
        ? prevState.selectedClientName.filter((name: string) => name !== clientName)
        : [...prevState.selectedClientName, clientName];

      return { selectedClientName: updatedSelectedClients };
    });
  };

  handleClientSearch = (value: string) => {
    if (value === "") {
      this.setState({ filteredClientName: this.state.allClientName });
    } else {
      if(this.state.allClientName){
        let filterOutput = this.state.allClientName.filter((clientName) =>
          clientName.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({ filteredClientName: filterOutput });
      }
    }
  };

  handleCheckboxClick = () => {
    this.setState({isClientChecked : !this.state.isClientChecked})
  }
  openDueDateCalendar = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({dueDateErrorCI : "" , anchorDueDate: event?.currentTarget})
  };

  closeDueDateCalendar = () => {
    this.setState({ anchorDueDate: null });
  };
  handleSelectDueDateCI = (date: string) => {
    this.setState({ dueDateCI: date }, () => {
      this.closeDueDateCalendar();
      this.updateCreateButtonState();
    });
  };
  handleInvoiceNameCI = (event: { target: any }) => {
    const value = event?.target?.value;
    if (value?.length <= 50) {
      this.setState({ invoiceError: "", invoiceNameCI: value, invoiceNameLimitError: "" }, this.updateCreateButtonState);
    } else {
      this.setState({ invoiceNameLimitError: "Invoice name cannot exceed 50 characters" });
    }
  };

  handleProjectNameCI = (event : { target : any }) => {
    this.setState({ projectIdError: "", projectNameCI: event.target.value }, this.updateCreateButtonState);
  };

  handleAmountCI = (event: { target: any }) => {
    const value = event.target.value;
    const amountRegex = /^(?:\d{1,9}|\d{1,9}\.\d{0,2})$/;

    if (value === "" || amountRegex.test(value)) {
        this.setState({ amountCI: value, amountError: "", amountErrorBlank: "" }, this.updateCreateButtonState);
    } else {
        this.setState({ amountError: "Amount must be a number with up to 9 digits and 2 decimal places" });
    }
};

  handleStatusChange = (status : string) => {
    this.setState({ statusCI: status, statusError: "" }, this.updateCreateButtonState);
  };

  handleProjectClick = (id : string) => {
    this.setState({ projectIdCI: id }, this.updateCreateButtonState);
  }
  
  handleStatusFilter = (status: any, event: any) => {
    const { selectedStatus } = this.state;

    if (status === "ALL") {
        if (event.target.checked) {
            this.setState({ selectedStatus: ["PAID", "UPCOMING", "UNPAID"] });
        } else {
            this.setState({ selectedStatus: [] });
        }
    } else {
        let updatedStatus;
        if (event?.target.checked) {
            updatedStatus = [...selectedStatus, status];
        } else {
            updatedStatus = selectedStatus.filter(item => item !== status);
        }
        if (updatedStatus.includes("PAID") && updatedStatus.includes("UPCOMING") && updatedStatus.includes("UNPAID")) {
            this.setState({ selectedStatus: ["PAID", "UPCOMING", "UNPAID"] }); 
        } else {
            this.setState({ selectedStatus: updatedStatus });
        }
    }
  };
  
  handleClearAllFilters = () => {
    this.setState({ filterModalOpen: false});
    this.setState({
      selectedClientName: [],
      selectedProjectName: [],
      selectedStatus: [],
      fromDate: null,
      toDate: null,
      filterCount : "",
    }, () => {
      this.getOverAllInvoiceData()
    });
  };
  // Customizable Area End
}
