import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export interface ProjectDetailsDataTypes {
  data: Data
}
export interface Data {
  id: string
  type: string
  attributes: Attributes
}
export interface Attributes {
  id: number
  project_name: string
  status: string
  start_date: string
  due_date: string
  expected_date: string
  addressable: Addressable
}
export interface Addressable {
  id: number
  address: string
  address2: string
  city: string
  country: string
  email: string
  name: string
  last_name: string
  phone: string
  post_code: string
  state: string
  country_code: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  googleChartData: any;
  viewMoreRooms: boolean
  projectDetails: Attributes | null 
  projectDetailsErrorMsg: string
  projectId: string;
  pieChartData:any;
  searchText:any;
  openTaskList:any;
  levelOneId:any;
  levelTwoId:any;
  subList:any;
  optionValue:any;
  subTaskListData:any;
  selectedStatus:any;
  isPauseDialogOpen:boolean;
  isCompleteDialogOpen:boolean;
  isNotCompleteDialogOpen:boolean;
  isOngoingDialogOpen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
  apiGetDataCallId: any;
  apiProjectDetailsId: string = '';
  getTokenCheckExpiryId: string = '';
  apiPieChartDetailsID:string='';
  apiSubTaskListValue:string='';
  calenderDate: string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      optionValue:"NONE",
      subList:false,
      pieChartData:[],
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      viewMoreRooms: false,
      projectDetails: null,
      projectDetailsErrorMsg: '',
      projectId: '',
      searchText:'',
      levelOneId:'',
      levelTwoId:'',
      openTaskList:false,
      subTaskListData:[],
      selectedStatus:"",
      isPauseDialogOpen:false,
      isCompleteDialogOpen:false,
      isOngoingDialogOpen:false,
      isNotCompleteDialogOpen:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.dayRavenue();
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== null &&
      this.apiGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiGetDataCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        console.log(responseJson.data?.labels.length);
        let webData: [any] = [["Title", "Value"]];
        for (
          var iIndex = 0;
          iIndex < responseJson.data?.labels.length;
          ++iIndex
        ) {
          let label = responseJson.data?.labels[iIndex];
          let value = responseJson.data?.data[iIndex][0];
          webData.push([label, value]);
        }
        this.setState({ info: responseJson.data, googleChartData: webData });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }else if (this.apiProjectDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleProjectDetailsResponse(message);
    }
    else if(this.apiPieChartDetailsID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handlePieChartDataResponse(message)
    }
    else if(this.apiSubTaskListValue ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ){
      this.handleGetSubTaskListData(message)
      }
      else if(this.calenderDate ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ){
        this.handleStatus(message)    
      }
  
  
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getProjectId()
    this.getProjectDetails()
    this.getPieChartDetailsValue()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  dayRavenue = async () => {
    let token = this.state.token;

    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatisticAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dayAudience = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.audienceFollowersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onClickViewMoreRooms = () => {
    this.setState((prev) => ({viewMoreRooms: !prev.viewMoreRooms}))
  }

  getProjectId = () => {
    const currentPath = window.location.pathname;
    const path =currentPath.split('/')[3]
    this.setState({projectId: path})
  }

  getPieChartDetailsValue =async()=>{
    const token122 = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token122,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPieChartDetailsID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.getPieChartResponse}/${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubTaskValueList =async(identity:any)=>{
    const token122 = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token122,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubTaskListValue = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.subTaskAPIEndPoint}/${identity}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProjectDetails = async () => {
    const token1 = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token1,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProjectDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.projectDetialsGetAPiEndPoint}/${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleProjectDetailsResponse = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({projectDetails: responseJson.data.attributes}) 
    this.setState({selectedStatus: responseJson.data.attributes.status}) 
  }

  handlePieChartDataResponse =(message:Message)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      pieChartData:responseJson
    })
  }

  handleGetSubTaskListData=(message:any)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      subTaskListData:responseJson
    })
  }

  handleStatus=(message:any)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   if(responseJson.message){
    this.setState({isPauseDialogOpen:false,isCompleteDialogOpen:false,isNotCompleteDialogOpen:false,isOngoingDialogOpen:false})
      toast.success(responseJson.message,{
            closeButton: false,
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            draggable: false,
            pauseOnHover: false,
            closeOnClick: false,
            theme: "light",
          })
   }
   if(responseJson.error){
    this.setState({isPauseDialogOpen:false,isCompleteDialogOpen:false,isNotCompleteDialogOpen:false,isOngoingDialogOpen:false})
      toast.error(responseJson.error,{
            closeButton: false,
            autoClose: 5000,
            position: "top-center",
            hideProgressBar: true,
            draggable: false,
            closeOnClick: false,
            pauseOnHover: false,
            theme: "light",
          })
   }

  }
  
  navigateSideTabs =(route:any) => {
    const currentPath = window.location.pathname;
    const project_id =currentPath.split('/')[3]
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      `${route}`
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `project_id/${project_id}`);
    this.send(message);
    this.getPieChartDetailsValue()
  }

  handleSearch=(e:any)=>{
    const searchText = e.target.value.toLowerCase(); 
    this.setState({
      searchText,
    });  
  }

  handleOpenSubTaskList =(id:any)=>{
    const responseData = !this.state.openTaskList
    this.setState({
        openTaskList:responseData,
        levelOneId:id
    })
    this.getSubTaskValueList(id)
  }
  
  handleSUbListOpenTask =(id:any)=>{
    const reposneSubList = !this.state.subList;
    this.setState({
      levelTwoId:id,
      subList:reposneSubList
  })
  }

  handleChangeDropDown = (event:any) => {
    this.setState({
      optionValue:event.target.value,
    });
  };

  handleSelectChange = (event:any) => {
    const selectedValue = event.target.value;
    this.setState({
      selectedStatus: selectedValue,
      isPauseDialogOpen: selectedValue === 'Paused',
      isOngoingDialogOpen:selectedValue==='Ongoing'
    });
    if (selectedValue === 'Completed' && (this.state.projectDetails as any).completion_percentage === "100.00") {
      this.setState({ isCompleteDialogOpen: true });
    }
    else if(selectedValue==='Completed' && (this.state.projectDetails as any).completion_percentage !== "100.00" ){
      this.setState({ isNotCompleteDialogOpen:true,})
    }
  };

  handleClose = () => {
    this.setState({isPauseDialogOpen:false,isCompleteDialogOpen:false,isOngoingDialogOpen:false,isNotCompleteDialogOpen:false})
  }

 selectCalenderDate = async()=>{
    const token = await getStorageData("token")    
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.calenderDate = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_visual_analytics/project_status/${this.state.projectId}`
      );
  
      
      const body={
           status: this.state.selectedStatus
      }
      
       requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    navigateToProjectProposalgeneration = async () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "KanbanBoard"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), `project_id/${this.state.projectId}`);
      this.send(message);
    }


  // Customizable Area End
}
