import React from "react";
import "gantt-task-react/dist/index.css";

import {
  Box,
  // Customizable Area Start
  Tab,
  Tabs,
  Button,
  Typography,
  MenuItem,
  Menu,
  Modal,
  Checkbox,
  Paper,
  Dialog,
  DialogActions,
  CircularProgress,
  Chip,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { buildStyles, CircularProgressbar,  } from 'react-circular-progressbar';
import {TextField,IconButton, List, ListItem, ListItemIcon, ListItemText}  from "@mui/material";
import { addIcon, crossSearchIcon, crossSmallIcon, searchIcon, successIcon } from "../../../blocks/projectportfolio/src/assets";
import { styled } from "@material-ui/styles";
import {  AdjustOutlined, ArrowBack, ArrowBackIos, ArrowForwardIos, AssistantPhotoOutlined, CheckCircleRounded, Clear, Close, DateRangeOutlined, DeleteOutline, Done, GroupAddOutlined, KeyboardArrowDown, KeyboardArrowUp, LocalOfferOutlined, PeopleOutlined, SquareFootOutlined} from '@material-ui/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Calendar from "../../../components/src/GanttChart/GanttChart.component";
import "./Style.css";
import { checkListIcon } from "./assets";
import { attachment, cancelIcon, checkedIcon, downArrowBtn, nextBtnClick, PdfBtnImage, reload, threeDotIcon, unCheckIcon, UpArrowBtn, UploadImageBtn } from "../../../blocks/kanbanboard/src/assets";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import Dropzone from "react-dropzone";
import Loader from "../../../components/src/Loader.web";
import { pdfFileIcon, pdfFileWarningIcon } from "../../../blocks/drawing/src/assets";
import { FileDownloadOutlined } from "@mui/icons-material";
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
type Task = {
  id: number;
  task_list_name: string;
  start_date: string;
  end_date: string;
  completed_tasks_count: number;
  tasks_count: number;
  priority: string;
  status: string;
};

type RoomData = {
  roomID: number;
  room: string;
  taskList: Task[];
};
import { ToastContainer } from "react-toastify";
// Customizable Area End

import GanttChartController, {
  Props,
} from "./GanttChartController";
import moment from "moment";
export default class GanttChart extends GanttChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  UploadButton = styled("button")(({ isEnabel }:any) => ({
    borderRadius: "8px",
    border: "1px solid #C5E0E7",
    fontWeight: 600,
    fontSize: "16px",
    height: "56px",
    letterSpacing: 0,
    textTransform: "none",
    padding: "10px 16px",
    boxShadow: "none",
    fontFamily: "Poppins",
    borderWidth: 0,
    cursor: "pointer",
    backgroundColor: isEnabel ? "#237182" : "#F1F5F9",
    color: isEnabel ? "#FFFFFF" : "#64748B",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: isEnabel ? "#237182" : "#F1F5F9",
    },
  }));

getRoomWidth(startDate: any, endDate: any): number {
  const { activeTab, initialDate } = this.state;
  let dayMultiplier = 150, dateMultiplier = 33, weekMultiplier = 90, monthMultiplier = 105;

  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  
  if (isNaN(start) || isNaN(end) || start > end) return 0;

  let visibleStart: number, visibleEnd: number;

  if (activeTab === 0) { // Week View
      const startOfWeek = new Date(initialDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      visibleStart = startOfWeek.getTime();
      visibleEnd = endOfWeek.getTime();
      
      return ((Math.min(end, visibleEnd) - Math.max(start, visibleStart)) / (1000 * 60 * 60 * 24) + 1) * dayMultiplier;
  } 
  
  else if (activeTab === 1) { // Month View
      visibleStart = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1).getTime();
      visibleEnd = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0).getTime();

      return ((Math.min(end, visibleEnd) - Math.max(start, visibleStart)) / (1000 * 60 * 60 * 24) + 1) * dateMultiplier;
  } 
  
  else if (activeTab === 2) { // Quarter View
      const quarterStartMonth = Math.floor(initialDate.getMonth() / 3) * 3;
      visibleStart = new Date(initialDate.getFullYear(), quarterStartMonth, 1).getTime();
      visibleEnd = new Date(initialDate.getFullYear(), quarterStartMonth + 3, 0).getTime();

      return Math.ceil((Math.min(end, visibleEnd) - Math.max(start, visibleStart)) / (7 * 24 * 60 * 60 * 1000)) * weekMultiplier;
  } 
  
  else if (activeTab === 3) { // Year View
      visibleStart = new Date(initialDate.getFullYear(), 0, 1).getTime();
      visibleEnd = new Date(initialDate.getFullYear(), 11, 31).getTime();

      const startObj = new Date(Math.max(start, visibleStart));
      const endObj = new Date(Math.min(end, visibleEnd));
      
      return ((endObj.getFullYear() - startObj.getFullYear()) * 12 + (endObj.getMonth() - startObj.getMonth()) + 1) * monthMultiplier;
  }

  return 0;
}
getRoomPosition(startDate: any) {
  const { activeTab, initialDate } = this.state;
  let dayMultiplier = 150, dateMultiplier = 33, weekMultiplier = 90, monthMultiplier = 105;

  const start = new Date(startDate).getTime();
  
  let visibleStart: number;

  if (activeTab === 0) {
      const startOfWeek = new Date(initialDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      visibleStart = startOfWeek.getTime();
      
      if (start < visibleStart) return 0;
      return ((new Date(startDate).getDay()) * dayMultiplier);
  } 
  
  else if (activeTab === 1) { 
      visibleStart = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1).getTime();
      
      if (start < visibleStart) return 0; 
      return ((new Date(startDate).getDate() - 1) * dateMultiplier);
  } 
  
  else if (activeTab === 2) { 
      const quarterStartMonth = Math.floor(initialDate.getMonth() / 3) * 3;
      visibleStart = new Date(initialDate.getFullYear(), quarterStartMonth, 1).getTime();
      
      const weekNumber = Math.ceil((start - visibleStart) / (7 * 24 * 60 * 60 * 1000));
      if (start < visibleStart) return 0;
      return (weekNumber - 1) * weekMultiplier;
  } 
  
  else if (activeTab === 3) {
      visibleStart = new Date(initialDate.getFullYear(), 0, 1).getTime();
      
      if (start < visibleStart) return 0; 
      return (new Date(startDate).getMonth() * monthMultiplier);
  }

  return 0; 
}
getWeekStartDate(date: Date) {
  const day = date.getDay(); // 0 (Sunday) to 6 (Saturday)
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - day); // Move to Sunday
}

getWeekEndDate(date: Date) {
  const day = date.getDay();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - day)); // Move to Saturday
}
isEventInSelectedWeek (event: any, initialDate: Date) {
  const startDate = new Date(event.attributes.start_date);
  const endDate = new Date(event.attributes.end_date);

  const weekStart = this.getWeekStartDate(initialDate);
  const weekEnd = this.getWeekEndDate(initialDate);

  return (
    (startDate >= weekStart && startDate <= weekEnd) || 
    (endDate >= weekStart && endDate <= weekEnd) ||     
    (startDate < weekStart && endDate > weekEnd)
  );
};


  RenderRoomList() {
  const { activeTab, initialDate, events } = this.state;

const selectedYear = initialDate.getFullYear();
const selectedMonth = initialDate.getMonth();
const selectedWeek = this.getWeekNumber(initialDate);

const monthStart = new Date(selectedYear, selectedMonth, 1); 
const monthEnd = new Date(selectedYear, selectedMonth + 1, 0); 

let visibleRooms = events.filter((event: any) => {
  const startDate = new Date(event.attributes.start_date);
  const endDate = new Date(event.attributes.end_date);

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const eventWeek = this.getWeekNumber(startDate);

  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();
  const endWeek = this.getWeekNumber(endDate);

  if (activeTab === 3) { 
    return (startYear === selectedYear || endYear === selectedYear);
  } 

  else if (activeTab === 1) { 
    return (
      (startDate >= monthStart && startDate <= monthEnd) || 
      (endDate >= monthStart && endDate <= monthEnd) ||
      (startDate < monthStart && endDate > monthEnd) 
    );
  } 

  else if (activeTab === 2) { 
    const quarterStartMonth = Math.floor(selectedMonth / 3) * 3;
    const quarterEndMonth = quarterStartMonth + 2;

    return (
      (startYear === selectedYear && startMonth >= quarterStartMonth && startMonth <= quarterEndMonth) || 
      (endYear === selectedYear && endMonth >= quarterStartMonth && endMonth <= quarterEndMonth) ||
      (startMonth < quarterStartMonth && endMonth > quarterEndMonth) 
    );
  } 

  else if (activeTab === 0) { 
    return this.isEventInSelectedWeek(event, initialDate)
  }
  
  return false;
});
  
    return (
      <div className="roomListViewCard">
        {visibleRooms.length==0&&
        <Typography style={{fontFamily:'Poppins',display:'flex',justifyContent:'center',marginTop:'30vh',position:'absolute',left:'40%'}}>No room available</Typography>}
        {visibleRooms.map((val: any) => {
          const startDate = new Date(val.attributes.start_date);
          const endDate = new Date(val.attributes.end_date);          
          return (
            <div
              key={val.id}
              data-test-id="isRoomOpen"
              style={{
                marginTop: "1px",
                display: "grid",
                width: "100%",
                gap: "10px",
                gridTemplateColumns: "200px auto",
                alignItems: "center",
                position: "relative",
              }}
              
            >
              <div className="RoomNameColor">{val.attributes.room_name}</div>
              <div className="roomListCard" style={{marginLeft:(this.getRoomPosition(startDate))<0?0:(this.getRoomPosition(startDate)),width:this.getRoomWidth(startDate,endDate)}}
               data-test-id="isRoomOpen" onClick={() => {
                this.setState({selectedRoomLength:val.attributes.depth, selectedRoomWidth:val.attributes.width,selectedRoomHeight:val.attributes.height,selectedRoomID:val.attributes.id,
                  selectedRoomStartDate:val.attributes.start_date,selectedRoomEndDate:val.attributes.end_date,isRoomOpen: true ,selectedRoomName:val.attributes.room_name,addedMembers:val.attributes.task_list_members});
                this.getRoomDetail(val.attributes.id)
              }}>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    
                  }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize:'14px',
                      fontFamily: "Poppins",
                      color: "#0F172A",
                      maxWidth: "150px", 
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer"
                    }}
                    data-test-id="namee2"
                    title={val.attributes.room_name}
                    
                  >
                    {val.attributes.room_name}
                  </span>
                  <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <div
                      style={{
                        width: "250px",
                        height: "4px",
                        borderRadius: "10px",
                        backgroundColor: "#E2E8F0",
                      }}
                    >
                      <div
                        style={{
                          width: `${
                            val?.attributes.total_task_list_count === 0 
                              ? 0 
                              : (val?.attributes.completed_tasks_count / val?.attributes.total_task_list_count) * 100
                          }%`,
                          
                          backgroundColor: val?.attributes.completed_tasks_count === val.attributes.total_task_list_count ? "#34D399" : "#237182",
                          height: "4px",
                          borderRadius: "10px",
                        }}
                      ></div>
                    </div>
                    <p style={webStyle.percentageText}>
                    {val?.attributes.total_task_list_count === 0 
                      ? "0%" 
                      : ((val?.attributes.completed_tasks_count / val?.attributes.total_task_list_count) * 100) === 0
                        ? "0%"
                        : `${((val?.attributes.completed_tasks_count / val?.attributes.total_task_list_count) * 100).toFixed(2)}%`}
                  </p>


                  </div>
                </div>
                <div className="roomBottomPart">
                  <div>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        fontFamily: "Poppins",
                        color: "#334155",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <DateRangeIcon style={{ height: "18px" }} />
                      {`${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()} - ${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`}
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        color: "#237182",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                        gap: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {val?.attributes.completed_tasks_count !== val?.attributes.total_task_list_count || val?.attributes.total_task_list_count ==0 ?
                        <img src={checkListIcon} alt="checklistIcon" style={{ color: "#237182" }} />
                        :
                        <CheckCircleRounded style={{ color: "#237182", height: "16px" }} />
                       } 
                     {val?.attributes.completed_tasks_count}/{val?.attributes.total_task_list_count} 
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  
  getWeekNumber(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }
  
  RendertaskName() {
    const { activeTab, initialDate, taskLists } = this.state;
  
    const selectedYear = initialDate.getFullYear();
    const selectedMonth = initialDate.getMonth();
    const selectedWeek = this.getWeekNumber(initialDate);

    const monthStart = new Date(selectedYear, selectedMonth, 1); // Start of selected month
  const monthEnd = new Date(selectedYear, selectedMonth + 1, 0);
  
  const filteredTaskLists: Record<number, { roomID: number; room: string; taskList: Record<number, Task> }> = taskLists.reduce((acc: any, roomData: any) => {
    const filteredTasks = roomData.taskList.filter((task: any) => {
      const taskStartDate = new Date(task.start_date);
      const taskEndDate = new Date(task.end_date);

      const taskYear = taskStartDate.getFullYear();
      const taskMonth = taskStartDate.getMonth();
      const taskWeek = this.getWeekNumber(taskStartDate);

      const endYear = taskEndDate.getFullYear();
      const endMonth = taskEndDate.getMonth();
      const endWeek = this.getWeekNumber(taskEndDate);

      if (activeTab === 3) {
        return taskYear === selectedYear || endYear === selectedYear;
      } 
      else if (activeTab === 1) {
        return (
          (taskStartDate >= monthStart && taskStartDate <= monthEnd) || 
          (taskEndDate >= monthStart && taskEndDate <= monthEnd) ||
          (taskStartDate < monthStart && taskEndDate > monthEnd) 
        );
      } 
      else if (activeTab === 2) {
        const quarterStartMonth = Math.floor(selectedMonth / 3) * 3;
        const quarterEndMonth = quarterStartMonth + 2;

        return (
          (taskYear === selectedYear && taskMonth >= quarterStartMonth && taskMonth <= quarterEndMonth) || 
          (endYear === selectedYear && endMonth >= quarterStartMonth && endMonth <= quarterEndMonth) ||
          (taskMonth < quarterStartMonth && endMonth > quarterEndMonth) 
        );
      } 
      else if (activeTab === 0) {
        return (
          (taskYear === selectedYear && taskWeek === selectedWeek) || 
          (endYear === selectedYear && endWeek === selectedWeek) ||
          (taskStartDate < initialDate && taskEndDate > initialDate)
        );
      }

      return false;
    });
      if (filteredTasks.length > 0) {
        if (!acc[roomData.roomID]) {
          acc[roomData.roomID] = { roomID: roomData.roomID, room: roomData.room, taskList: {} };
        }
    
        filteredTasks.forEach((task:any) => {
          acc[roomData.roomID].taskList[task.id] = task; 
        });
      }
    
      return acc;
    }, {} as Record<number, { roomID: number; room: string; taskList: Record<number, Task> }>);
    
    const roomNameIndex: Record<string, number> = {};

    const mergedTaskLists: RoomData[] = Object.values(filteredTaskLists).map((room) => {
      const baseName = room.room;
    
      if (!roomNameIndex.hasOwnProperty(baseName)) {
        roomNameIndex[baseName] = 0; 
      } else {
        roomNameIndex[baseName] += 1;
      }
    
      const roomName =
        roomNameIndex[baseName] === 0 ? baseName : `${baseName} ${roomNameIndex[baseName]}`;
    
      return {
        roomID: room.roomID,
        room: roomName,
        taskList: Object.values(room.taskList),
      };
    });
       
  
    return (
      <div className="eventsCard">
        {mergedTaskLists.length === 0 && (
          <Typography style={{ fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', marginTop: '30vh' ,position:'absolute',left:'40%'}}>
            No tasklist available
          </Typography>
        )}
        {mergedTaskLists.map((roomData:any) => (
          <div key={roomData.room_id} style={{ marginTop: "5px", display: "grid", width: "100%", gridTemplateColumns: "200px auto", alignItems: "center", gap: "10px" }}>
            <div className="RoomNameColor1">{roomData.room}</div>
            <div style={{ display: "flex", gap: "10px", flexDirection: "column", paddingTop: "10px", paddingBottom: "10px" }}>
              {roomData.taskList.map((task:any, index:any) => {
                const formattedStartDate = new Date(task.start_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
                const formattedEndDate = new Date(task.end_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
                const [day, month, year] = formattedStartDate.split("/").map(Number);
                const startDateObj = new Date(year, month - 1, day);
                const [dayend, monthend, yearend] = formattedEndDate.split("/").map(Number);
                const endDateObj = new Date(yearend, monthend - 1, dayend);
                return (
                  <div className="cardContent"
                  style={{marginLeft:(this.getRoomPosition(startDateObj))<0?0:(this.getRoomPosition(startDateObj)),width:this.getRoomWidth(startDateObj,endDateObj)}} 
                  key={index} data-test-id="isTaskListDetailOpen" onClick={() => { 
                    this.setState({ 
                      selectedTasklistName: task.task_list_name, 
                      selectedRoomStartDate: task.start_date,
                      selectedRoomEndDate: task.end_date, 
                      selectedRoomName: roomData.room, 
                      selectedTasklistPriority: task.priority, 
                      selectedTasklistStatus: task.status ,
                      addedMembers:task.task_list_members
                    });
                    this.handleTaskListDetailCard(task.id, roomData.roomID);
                  }}>
                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                      {task.completed_tasks_count === task.tasks_count ? (
                        <span className="greenDot"></span>
                      ) : (
                        <span className="yellowDot"></span>
                      )}
                      <span style={{ fontWeight: 600, fontSize: "14px", fontFamily: "Poppins", color: "#0F172A",maxWidth:'170px',overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          cursor:'pointer', }}
                          data-test-id="tasknameee"
                     title={task.task_list_name}>
                        {task.task_list_name}
                      </span>
                      <span style={{ fontWeight: 400, fontSize: "12px", fontFamily: "Poppins", color: "#334155" }}>
                        {formattedStartDate} - {formattedEndDate}
                      </span>
                    </div>
                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                      <div style={{ width: "150px", height: "4px", borderRadius: "10px", backgroundColor: "#E2E8F0" }}>
                        <div style={{ width: `${task.tasks_count === 0 ? 0 : (task.completed_tasks_count / task.tasks_count) * 100}%`,backgroundColor: task.completed_tasks_count === task.tasks_count ? "#34D399" : "#237182", height: "4px", borderRadius: "10px" }}></div>
                      </div>
                      <span style={{ fontWeight: 400, fontSize: "12px", fontFamily: "Poppins", color: "#334155" }}>
                      {task.tasks_count === 0 
                        ? "0%" 
                        : ((task.completed_tasks_count / task.tasks_count) * 100).toFixed(
                            (task.completed_tasks_count / task.tasks_count * 100) % 1 === 0 ? 0 : 2
                          ) + "%"
                      }
                    </span>

                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  RenderQuaterCalendar() {
    const quarters = ["Q1", "Q2", "Q3", "Q4"];

    const currentMonth = this.state.initialDate.getMonth();
    const currentYear = this.state.initialDate.getFullYear();
    const quarterIndex = Math.floor(currentMonth / 3);
    const currentQuarter = quarters[quarterIndex];

    const startOfQuarter = moment([currentYear, quarterIndex * 3, 1]); 
    const endOfQuarter = moment(startOfQuarter).add(2, 'months').endOf('month');

    return (
      <div className="container">
         <div className="header">
          <div className="monthTitle">
              <div className="monthName">
                <span onClick={this.handlePreviousWeek}><ArrowBackIos style={{width:'18px',height:'18px'}}/></span>
                <span style={{fontWeight:600,fontFamily:"Poppins",color:"#325962",fontSize:"16px",marginLeft:'8px',marginRight:'8px'}}>
                  {currentQuarter} {currentYear}
                </span>
                <span onClick={this.handleNextWeek}><ArrowForwardIos style={{width:'18px',height:'18px'}}/></span>
              </div>
              <div className="monthDuration">
                <span style={webStyle.periodText}>
                  Period : {startOfQuarter.format("DD/MM/YYYY")} - {endOfQuarter.format("DD/MM/YYYY")}
                </span>
              </div>
          </div>
          <div>
            {this.renderQuarterWeeks(startOfQuarter, endOfQuarter)}
          </div>
         </div>
      </div>
    )
}

renderQuarterWeeks(startOfQuarter: moment.Moment, endOfQuarter: moment.Moment) {
  let weeks = [];
  let currentWeekStart = moment(startOfQuarter).startOf('week');
  let weekNumber = 1;

  while (currentWeekStart.isBefore(endOfQuarter) || currentWeekStart.isSame(endOfQuarter, 'week')) {
      weeks.push(`Week ${weekNumber}`);
      currentWeekStart.add(1, 'week');
      weekNumber++;
  }

  return (
    <div style={{ display: "flex", justifyContent: 'space-around', marginTop: '10px' }}>
      {weeks.map((week, id) => (
        <div key={id} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <span style={webStyle.monthText}>Week</span>
          <span style={webStyle.monthText}>{week.substring(4)}</span>
        </div>
      ))}
    </div>
  );
}


  RenderYearCalendar(){
    return (
      <div className="container">
         <div className="header">
          <div className="monthTitle">
              <div className="monthName">
                <span onClick={this.handlePreviousWeek}><ArrowBackIos style={{width:'18px',height:'18px'}}/></span>
                <span style={{fontWeight:600,fontFamily:"Poppins",color:"#325962",fontSize:"16px",marginLeft:'8px',marginRight:'8px'}}>{this.state.initialDate.getFullYear()}</span>
                <span onClick={this.handleNextWeek}><ArrowForwardIos style={{width:'18px',height:'18px'}}/></span>
              </div>
              <div className="monthDuration" style={webStyle.periodText}> <span>Period :{" "} 1/1/{this.state.initialDate.getFullYear()}{" "}-{" "}31/12/{this.state.initialDate.getFullYear()}</span></div>
          </div>
          <div className="month">
           {this.state.monthName?.map((val:any)=>{
              return(
                <div className="monthDiv" key={val?.id}>
                  <span style={webStyle.monthText}>{val?.name}</span>
                  <span style={webStyle.dateText}>{val?.id}</span>
                </div>
              )})}
          </div> 
         </div>
      </div>
    )
  }
  getUserName = (val: any) => {
    return val.attributes?.user_name || val.attributes?.uploaded_by;
  };

      getProjectTasks = (attributes:any) => {
      return Array.isArray(attributes.project_tasks) ? attributes.project_tasks : [];
    };

    getTaskListValueData = (projectTasks:any) => {
      return this.state.showAll ? projectTasks : projectTasks.slice(0, 4);
    };
    calculateProgress = (completedTasks:any, totalTasks:any) => {
      return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
    };
    getCompletedTasks = (attributes:any) => {
      return attributes.completed_tasks_count || 0;
    };

    getTotalTasks = (attributes:any) => {
      return attributes.tasks_count || 0;
    };
    getAttributes = () => {
      return this.state.taskListValues?.attributes || {};
    };
    renderTasksMap=(projectTasks:any,taskListValueData:any)=>{
      return(
        <>
         {
              projectTasks.length > 0 && taskListValueData?.map((val:any)=>{
                return (
                  <div style={{display:"flex",flexDirection:"column",gap:"5px",justifyContent:"space-around",marginBottom:"10px"}}>
                  <div 
                    style={{
                      display:"flex",
                      alignItems:"center",
                      fontSize:"16px",
                      height:"40px",
                      justifyContent:"space-between",
                      textAlign:"center"
                    }} 
                    data-test-id="toggleItem"
                    onClick={()=>this.toggleItem(val.id)}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <Checkbox
                        data-test-id="tasklist-change-id"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleCheckStatus(val.id);
                        }}
                        checked={Boolean(val.attributes.complete_status)} 
                        icon={
                          <img
                            src={unCheckIcon}
                            style={{ width: 20, height: 20 }}
                            alt="unchecked"
                          />
                        }
                        checkedIcon={
                          <img
                            src={checkedIcon}
                            style={{ width: 20, height: 20 }}
                            alt="checked"
                          />
                        }
                        style={{ color: "#64748B" }}
                      />
                      <span data-test-id="taskname" 
                        style={{
                          fontWeight: 400,
                          fontFamily: "Poppins",
                          color:val.attributes.complete_status?'#94A3B8': '#0F172A',
                          fontSize: "14px",
                          lineHeight: "22px",
                          display: "block",
                          maxWidth: "195px",
                          alignItems: "center",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          cursor:'pointer',
                          textDecoration:val.attributes.complete_status?'line-through':'none'
                        }}
                      
                        title={val.attributes.task_list_name}
                      >
                        {val.attributes.task_list_name}
                      </span>
                    </div> 
                    {
                      this.state.toggleID === val.id 
                      ? <img src={downArrowBtn} style={{height:"24px", width:"24px"}}/> 
                      : <img src={nextBtnClick} style={{height:"24px", width:"24px"}}/> 
                    }
                  </div>
                  
                  {
                    this.state.toggleID === val.id &&
                    <div style={{
                      fontSize: "12px",
                      display: "flex",
                      paddingTop: "8px", 
                      justifyContent: "space-between",
                      marginLeft: "28px",
                      backgroundColor: "#F0F5F7",
                      borderRadius: "12px",
                      lineHeight: "16px",
                      paddingBottom:"8px",
                      alignItems: "center",
                      fontFamily: "Poppins",
                      letterSpacing: "0%",
                    }}>
                      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                        <div style={{ display: "flex", alignItems: "center"}}>
                          <span style={{ width: "102px", color:"#475569", paddingLeft:"16px"}}>Specifications:</span>
                          <span style={{ color: "#0F172A" }}>{val.attributes?.specifications[0]?.specification_name}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "102px",  color:"#475569", paddingLeft:"16px"}}>Quantity:</span>
                          <span style={{ color: "#0F172A" }}>{val.attributes.specifications[0]?.quantity}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "102px",  color:"#475569", paddingLeft:"16px" }}>Unit cost/ unit:</span>
                          <span style={{ color: "#0F172A" }}>£ {parseFloat(val.attributes.specifications[0]?.unit_total || 0).toFixed(2)}</span>
                        </div>
                      </div>
                      
                      <div style={{ display: "flex", flexDirection: "column", gap: "4px", paddingRight:"40px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "131px",  color:"#475569" }}>Labour cost/ unit:</span>
                          <span style={{ color: "#0F172A" }}>£ {parseFloat(val.attributes.specifications[0]?.labour_cost || 0).toFixed(2)}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "131px",  color:"#475569" }}>Material cost/ unit:</span>
                          <span style={{ color: "#0F172A" }}>£ {parseFloat(val.attributes.specifications[0]?.material_cost || 0).toFixed(2)}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "131px",  color:"#475569" }}>Line Total:</span>
                          <span style={{ color: "#0F172A" }}>£ {parseFloat(val.attributes.specifications[0]?.total_cost || 0).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>  
                )
              })
            }
        </>
      )
    }
    calculateLeftPosition = (length:any) => {
      switch (length) {
        case 0:
          return 25;
        case 1:
          return 45;
        case 2:
          return 63;
        case 3:
          return 83;
        default:
          return 83;
      }
    };
    statusFotmate =(rawStatus:any)=>{
      const statusMapping: { [key: string]: string } = {
        to_do: "To Do",
        in_progress: "In Progress",
        complete: "Completed",
        deleted: "Blocked",
        archived:'Archived'
      };
      
      const formattedStatus = statusMapping[rawStatus] || rawStatus; 
      return formattedStatus
    }
    priorityFormatt=(priority:any)=>{
     return( priority === "medium" ? <span>Mid</span> :
      priority === "high" ? <span>High</span> :
      priority === "low" ? <span>Low</span> :
       <span>{this.state.selectedTasklistPriority}</span>)
    }
    getUsers=(users:any)=>{
      return( Array.isArray(users) ? users.slice(0, 3) : [])
    }
    groupMem=(totalAddMem:any)=>{
      return(
        <>
         {(Array.isArray(totalAddMem) ? totalAddMem : []).map((item: any, index: any) => (
      <div
        key={item.id}
        style={{
          padding: 4,
          borderRadius: '50%',
          background: '#D3E4E9',
          left: index === 0 ? '0px' : index === 1 ? '16px' : '35px',
          border: '2px solid #E0EDF0',
          height: '24px',
          width: '24px',
          position: 'absolute',
          color: '#ABCDD5',
          fontFamily: 'Poppins',
          fontSize: '10px',
          fontWeight: 700,
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        {item.profile_picture ? (
          <img
            src={item.profile_picture}
            alt={item.name}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
        ) : (
          (item.assignee_name || item.name)
            .split(' ')
            .map((word: any) => word[0])
            .join('')
            .toUpperCase()
        )}
      </div>
    ))}
</>
      )
    }
  renderTaskListDetailModel= () =>{
    const taskListValues = this.state.taskListValues;
    const attributes = this.getAttributes();

    const projectTasks = this.getProjectTasks(attributes);
    const taskListValueData = this.getTaskListValueData(projectTasks);
    const renderAttachmentList = this.state.showAttachments ? this.state.attachmentList : this.state.attachmentList.slice(0,4)
    const commentListData = this.state.showComment ? this.state.commentListAll :this.state.commentListAll.slice(0,4)
    const startDate = new Date(this.state.selectedRoomStartDate)
    const endDate = new Date(this.state.selectedRoomEndDate)
    const completedTasks = this.getCompletedTasks(attributes);
    const totalTasks = this.getTotalTasks(attributes);
    const progress = this.calculateProgress(completedTasks, totalTasks);
    
    const mergedCommentList = [...this.state.commentListAll, ...this.state.comment_attachmentList].sort(
      (a, b) => new Date(a.attributes.created_at).getTime() - new Date(b.attributes.created_at).getTime()
    );
    const totalAddMem = this.getUsers(attributes.task_list_members);
    const visibleComments = this.state.showComment ? mergedCommentList : mergedCommentList.slice(0, 4);
    console.log('grtyrtytr',totalAddMem)
    return (
      <Modal open={this.state.isTaskListDetailOpen} style={{ display: "flex" }}>
        <Box
          component="div"
          style={{
            width: "40vw",
            overflowY:"scroll", padding: "24px",
            position: "fixed",
            outline: "none", 
            boxShadow: "none", 
            border: "none",  background: "white",
            top: 0,
            right: 0,
            height: "100%",
            transform: "translateX(0)",
            display:"flex", gap:"20px",
            flexDirection:"column",justifyContent:"space-between",
            borderBottomLeftRadius: "16px",
            borderTopLeftRadius: "16px",
          }}
        >
         <Box> 
          <Box style={{ ...webStyle.modalHeader, marginBottom: "20px" }}>
            {this.state.isRoomClicked? 
            <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              this.setState({isTaskListDetailOpen:false,isRoomOpen:true})
            }}><ArrowBack style={{color:'237182'}}/> <Typography style={{color:'#237182',fontSize:'12px',fontWeight:600}}> Back to Room</Typography></div>
            :
            <Typography style={webStyle.renameText}>{this.state.selectedTasklistName}</Typography>
            }
            {!this.state.isRoomClicked &&
            <Close
              data-test-id="close-filter-modal-id"
              onClick={()=>this.handleTaskListDetailCard("","")}
              style={{ cursor: "pointer" }}
            />
            }
          </Box>
          <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between" , gap:"32px"}}>
            <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between",width:"35%",lineHeight:"24px"}}>
               <Box style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                  <Typography style={webStyle.innerDetail} > <AdjustOutlined style={webStyle.iconDetailColorStyle} /> <Typography style={webStyle.multipleDetailDataStyle}>Status:</Typography> </Typography>
                  <Typography style={webStyle.innerDetail} ><AssistantPhotoOutlined style={webStyle.iconDetailColorStyle}/>  <Typography style={webStyle.multipleDetailDataStyle}>Priority:</Typography> </Typography>
                  <Typography style={webStyle.innerDetail} ><PeopleOutlined style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Team:</Typography> </Typography>
               </Box>
               <Box  style={{display:"flex",flexDirection:"column",gap:"10px",position:'relative'}}>
               <Box style={{ display: "inline-flex", flexShrink: 0,justifyContent:'center' }}>
               <Typography
                onClick={this.handleMenuClickDetail}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: this.getStatusBackgroundColorDetail(this.statusFotmate(taskListValues.attributes?.status)),
                  padding: "1px 6px 1px 8px",
                  borderRadius: "40px",
                  color: "white",
                  fontSize:'12px',
                  marginTop:'-26px'
                }}
          >
            {this.statusFotmate(taskListValues.attributes?.status)}
            <span style={{ marginLeft: "8px" }}><KeyboardArrowDown style={{width:'20px'}}/></span>
      </Typography>
      </Box>
      <Menu
        anchorEl={this.state.anchorElStatus}
        open={Boolean(this.state.anchorElStatus)}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
      }}
      transformOrigin={{
          vertical: "top",
          horizontal: "right",
      }}
      PaperProps={{
          style: {
              marginTop: "46px",
              padding: '1px 4px',
              borderRadius: '8px',
              width: '130px'
          }
      }}
      >
         {['To Do', 'In Progress', 'Completed', 'Blocked', 'Archived'].map((status) => (
                  <MenuItem
                  key={status}
                  data-test-id="handleMenuItemClickDetail"
                  onClick={() => this.handleSubMenuClick(status)}
                  style={{
                    borderRadius: '8px',
                    backgroundColor: 'transparent',
                    
                  }}
                >
                  <Typography
                    style={{
                      ...webStyle.statusStyle,
                      backgroundColor: this.getStatusBackgroundColorDetail(status), 
                    }}
                  >
                    {status}
                  </Typography>
                </MenuItem>
                  ))}
      </Menu>
      <Box style={{ display: "inline-flex", flexShrink: 0,justifyContent:'center' }}>
      <Typography
        onClick={this.handlePriorityClick}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: this.getPriorityBackgroundColor(taskListValues.attributes?.priority),
          padding: "0px 8px",
          borderRadius: "16px",
          marginTop:'-5px',
          fontSize:'12px',
          color:this.getPriorityColorStatus(taskListValues.attributes?.priority)
        }}
      > <span style={{...webStyle.roomStatusCircleStyle,
        backgroundColor:this.getPriorityColorStatus(taskListValues.attributes?.priority),marginRight:"3px"}}></span>
       {
      this.priorityFormatt(taskListValues.attributes?.priority)
      }


         <span ><KeyboardArrowDown/></span>
      </Typography>
      </Box>
      <Menu
        anchorEl={this.state.anchorElPriority}
        open={Boolean(this.state.anchorElPriority)}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
      }}
      transformOrigin={{
          vertical: "top",
          horizontal: "right",
      }}
      PaperProps={{
          style: {
              marginTop: "46px",
              padding: '1px 4px',
              borderRadius: '8px',
              width: '130px'
          }
      }}
        style={{padding:"8px 16px"}}
      >
        {this.state.priorities.map((priority:string) => (
          <MenuItem
            key={priority}
            data-test-id="handlePriorityItemClick"
            onClick={() => this.handlePriorityUpdate(priority)}
            style={{
              padding: "8px 16px",
              borderRadius: "16px",
              backgroundColor: "transparent",
            }}
          >
         
            <Typography
              style={{
                backgroundColor: this.getPriorityBackgroundColor(priority),color:this.getPriorityColorStatus(priority),
                padding: "1px 8px", borderRadius: "16px",
                display:"flex",alignItems:"center",
                gap:"4px"}}
            >
                 <Typography style={{...webStyle.roomStatusCircleStyle, backgroundColor:this.getPriorityColorStatus(priority)}}></Typography>
                 <Typography> {priority}</Typography>
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <div style={{ display: 'flex', position: 'relative', marginTop: '-5px' }}>
        {this.groupMem(totalAddMem)}
    <Typography
      data-test-id="assignee"
      onClick={() => this.setState({ isAssignMemberModalOpen: true, isRoomTeamSeleted: false, addedMembers:attributes.task_list_members })}
    >
      <GroupAddOutlinedIcon
        style={{
          cursor: 'pointer',
          width: '22px',
          height: '22px',
          position: 'absolute',
          left: this.calculateLeftPosition(totalAddMem.length),
          marginTop: '5px',
          color: '#237182',
        }}
      />
    </Typography>
  </div>

               </Box>
            </Box>
            <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between" , width:"40%"}}>
            <Box  style={{display:"flex",flexDirection:"column",gap:"8px",marginRight:"0px", paddingRight:"0px"}}>
                  <Typography style={webStyle.innerDetail} > <DateRangeOutlined style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Start Date:</Typography></Typography>
                  <Typography style={webStyle.innerDetail} ><DateRangeOutlined style={webStyle.iconDetailColorStyle}/>  <Typography style={webStyle.multipleDetailDataStyle}>Due Date:</Typography></Typography>
                  <Typography style={webStyle.innerDetail} ><LocalOfferOutlined style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Room/Area:</Typography></Typography>
               </Box>
               <Box  style={{display:"flex",flexDirection:"column",gap:"8px",marginRight:"0px"}}>
               <Typography style={{ color: "#237182", fontSize: "16px", fontWeight: 600, fontFamily: "Poppins" }}>
                  {`${String(startDate?.getDate()).padStart(2, '0')}/${String(startDate?.getMonth() + 1).padStart(2, '0')}/${String(startDate?.getFullYear()).slice(-2)}`}
                </Typography>

                <Typography style={{ color: "#237182", fontSize: "16px", fontWeight: 600, fontFamily: "Poppins" }}>
                  {`${String(endDate?.getDate()).padStart(2, '0')}/${String(endDate?.getMonth() + 1).padStart(2, '0')}/${String(endDate?.getFullYear()).slice(-2)}`}
                </Typography>

                  <Typography style={{color:"#64748B",fontSize:"12px",fontWeight:600,fontFamily:"Poppins" , display:"flex",alignItems:"center",gap:"4px"}}> 
                    <Typography style={webStyle.roomsStatusCircleStyle}></Typography> 
                    <span style={{maxWidth: "100px", 
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer"}}
                        data-test-id="nameee"
                       
                        title={this.state.selectedRoomName}
                        >
                          {this.state.selectedRoomName}</span>
                    </Typography>
               </Box>
            </Box>
          </Box>   
          <Box style={{display:"flex",flexDirection:"column",gap:"5px", marginBottom:"10px", marginTop:"24px"}}>
            <Box style={{display:'flex',justifyContent:'space-between'}}>
          <Typography style={{fontSize:"16",fontWeight:600,color:"#334155",fontFamily:"Poppins",}}>Tasklist Details</Typography>
          <Box style={{display:'flex',gap:5}}>
            <Typography style={{color:'#475569',fontFamily:'Poppins',fontSize:14,fontWeight:400}}>{completedTasks}/{totalTasks}</Typography>
           
            <Box sx={{ height: "20px", width: "20px" }}>
                    <CircularProgressbar
                      data-testid="progressBar"
                      styles={buildStyles({
                        pathColor: "#325962",
                        trailColor: "#E4E8EB",
                      })}
                      strokeWidth={10}
                      value={progress}
                    >
                    </CircularProgressbar>
                  </Box>
          </Box>
          </Box>
          <Typography style={{fontSize:"16px",color:"#475569"}}>Click the checkbox next to the task to mark it as completed.</Typography>
          <div style={{ width: '100%' }}>
            {
             this.renderTasksMap(projectTasks,taskListValueData)
            }
          </div>  
          {projectTasks.length>5&&
          <div style={{display:"flex",justifyContent:"center"}}>
            <span style={{color:"#237182",fontSize:"14px",fontWeight:600,display:"flex",alignItems:"center",fontFamily:"Poppins",cursor:'pointer'}} 
            onClick={this.toggleShowAllTasks}>
              {this.state.showAll ? "Show less" :"Show more"}{this.state.showAll ?
               <KeyboardArrowUp style={{color:"237182",height:"25px"}}/>:<KeyboardArrowDown style={{color:"237182",height:"25px"}}/>}</span>
               </div>      
          }
          <div style={{height:"2px",borderRadius:"10px",width:"100%",backgroundColor:"#E2E8F0"}}>
            </div>  
          <div style={{display:"flex",justifyContent:"space-between", marginTop:"16px"}}>
            <span style={{fontSize:"16px",fontWeight:600,color:"#334155",fontFamily:"Poppins"}}>
              Attachments</span>
                <span style={{
                  cursor:'pointer',
                  fontSize: "16px",
                  lineHeight:"24px",
                  color: "#237182",
                  gap: "10px",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
                  onClick={this.handleUploadFileBtn}
                >
                  <img src={UploadImageBtn}
                    style={{
                      width: "14px",
                    }}
                    alt="Upload"
                  />
                  Upload Files </span>
          </div> 
          <div style={{display:"flex",flexDirection:"column",gap:"10px",marginBottom:"10px"}}>
            {
             this.state.attachmentList.length > 0 && 
             renderAttachmentList?.map((val:any,i:any)=>{
                return(
                  <Paper elevation={2} key={i} 
                  style={{justifyContent:"space-between",display:"flex",alignItems:"center",borderRadius:"10px"}}>
                      <div 
                      style={{display:"flex",gap:"20px",alignItems:"center", padding:'10px'}}>
                          <div 
                          style={{backgroundColor:"#E0EDF0",borderRadius:"10px",display:"flex",alignItems:"center",justifyContent:"center"}}> <img src={PdfBtnImage} style={{height:"43px"}}/></div>
                          <div 
                          style={{display:"flex",flexDirection:"column",fontSize:"14px",fontFamily:"Poppins"}}>
                             <span>{val.attributes.file_name}</span> 
                             <span style={{fontSize:'12px',color:'#64748B'}}>{val.attributes.file_size}</span>
                            </div>
                      </div>
                      <div>
                      <Button 
                      id="basic-button"
                      aria-controls={this.state.commentPopUpState ? 'basic-menu' : undefined}
                      data-test-id="basic-button"
                      onClick={(e:any)=>this.handlePopUpOpen(e,val)}>
                        <img src={threeDotIcon} style={{height:"20px"}} /> 
                    </Button>
                    <Menu
                        open={Boolean(this.state.anchorElPriorityUpload)}
                        anchorEl={this.state.anchorElPriorityUpload}
                        onClose={()=>{this.setState({anchorElPriorityUpload:null})}}
                        anchorOrigin={{
                          horizontal: "left",
                          vertical: "bottom",
                      }}
                      transformOrigin={{
                        horizontal: "left",
                          vertical: "top",
                      }}
                      PaperProps={{
                          style: {
                            borderRadius: '8px',
                              marginTop: "46px",
                              padding: '1px 4px',
                              width: '147px',
                          }
                      }}
                        style={{padding:"8px 16px"}}
                      >

                          <MenuItem
                            style={{
                              fontFamily:'Poppins',fontSize:'14px',
                              padding: "8px 16px",
                              backgroundColor: "transparent",
                              cursor:'pointer',
                              borderRadius: "16px",
                              display:'flex',gap:5
                            }}
                            onClick={()=>this.downloadFile(val.attributes.url)}
                          >
                            <FileDownloadOutlined/>
                            <Typography> Download</Typography>
                          </MenuItem>
                          <MenuItem
                            style={{
                              color:'#DC2626',
                              borderRadius: "16px",
                              fontFamily:'Poppins',fontSize:'14px',
                              padding: "8px 16px",
                              backgroundColor: "transparent",
                              cursor:'pointer',display:'flex',gap:5
                            }}
                            onClick={this.openConfirmDelete}
                          >
                            <DeleteOutline style={{color:"#DC2626"}}/>
                            <Typography>Delete</Typography>
                          </MenuItem>
                      </Menu>
                      </div>
                  </Paper>
                )
              })
            }
          </div>
          {this.state.attachmentList.length>4 &&
          <div style={{display:"flex",justifyContent:"center",marginTop:"16px"}}>
            <span style={{color:"#237182",fontSize:"14px",fontWeight:600,cursor:'pointer',display:"flex",alignItems:"center",fontFamily:"Poppins"}} 
            onClick={this.toggleShowAttachment}>{this.state.showAttachments ? "Show less" :"Show more"}{this.state.showAttachments ?
               <KeyboardArrowUp style={{color:"237182",height:"25px"}}/>:<KeyboardArrowDown style={{color:"237182",height:"25px"}}/>}</span></div>  
          }
          <div style={{height:"2px",borderRadius:"10px",width:"100%",backgroundColor:"#E2E8F0",marginBottom:"5px"}}></div>
          <div style={{fontSize:"16px",fontWeight:600,color:"#334155",fontFamily:"Poppins",marginBottom:"2px", marginTop:"10px"}}>Comments</div>  
          <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
          {this.state.commentListAll.length + this.state.comment_attachmentList.length > 0 &&
           visibleComments.map((val: any, i: any) => {
          return (
            <div  key={i}  style={{
              fontFamily: "Poppins",
                display: "flex",
                marginBottom: "10px",
                flexDirection: "column",
              }}
            >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 600,
              color: "#0F172A",
            }}
          >
             {this.getUserName(val)}
          </span>
          <span
            style={{
              color: "#94A3B8",
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            {this.formatDateResponse(val.attributes.created_at)}
          </span>
        </div>

        {val.attributes.comment && (  <div style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: 400 }}> {val.attributes.comment}
          </div>
        )}

        {val.attributes.file_name && (
          <Paper
            elevation={2}
            style={{
              justifyContent: "space-between",  borderRadius: "10px",
              display: "flex", alignItems: "center",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",  gap: "20px",
                alignItems: "center", padding: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#E0EDF0",
                  borderRadius: "10px",display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={PdfBtnImage} style={{ height: "43px" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
              >
                <span>{val.attributes.file_name}</span>{" "} <span style={{ fontSize: "12px", color: "#64748B" }}>{val.attributes.file_size}</span>
              </div>
            </div>
            <div>
              <Button  id="basic-button"
                data-test-id="basic-button"
                aria-controls={this.state.commentPopUpState ? "basic-menu" : undefined}
                onClick={(e: any) => this.handlePopUpOpen(e, val)}
              >
                <img src={threeDotIcon} style={{ height: "20px" }} />
              </Button>
              <Menu open={Boolean(this.state.anchorElPriorityUpload)}
                anchorEl={this.state.anchorElPriorityUpload}
                onClose={() => {
                  this.setState({ anchorElPriorityUpload: null });
                }}
                anchorOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
                PaperProps={{
                  style: {
                    padding: "1px 4px",
                    marginTop: "46px",
                    borderRadius: "8px",
                    width: "147px",
                  },
                }}
                style={{ padding: "8px 16px" }}
              >
                <MenuItem
                  style={{
                    borderRadius: "16px",
                    fontFamily: "Poppins",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    padding: "8px 16px",
                    fontSize: "14px",
                    display: "flex",
                    gap: 5,
                  }}
                  onClick={() => this.downloadFile(val.attributes.url)}
                >
                  <FileDownloadOutlined />
                  <Typography> Download</Typography>
                </MenuItem>
                <MenuItem
                  style={{
                    padding: "8px 16px",
                    borderRadius: "16px",
                    fontSize: "14px",
                    color: "#DC2626",
                    fontFamily: "Poppins",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    display: "flex",
                    gap: 5,
                  }}
                  onClick={this.openConfirmDelete}
                >
                  <DeleteOutline style={{ color: "#DC2626" }} />
                  <Typography>Delete</Typography>
                </MenuItem>
              </Menu>
            </div>
          </Paper>
        )}
      </div>
    );
  })}
</div>
      {mergedCommentList.length > 4 && ( <div style={{ display: "flex", justifyContent: "center" }}>
          <span
            style={{
              color: "#237182", fontSize: "14px",
              fontWeight: 600,  display: "flex",
              alignItems: "center", fontFamily: "Poppins",
              cursor: "pointer",marginBottom:'20px'
            }}
      onClick={this.toggleShowComments}
    >
      {this.state.showComment ? "Show less" : "Show more"} {this.state.showComment ? (
        <KeyboardArrowUp style={{ color: "237182", height: "25px" }} /> ) : (
        <KeyboardArrowDown style={{ color: "237182", height: "25px" }} /> )} </span>
          </div> )}
          </Box>  
          </Box>

         <div style={{display:"flex",position:"sticky",bottom:"39px",gap:"10px",opacity:1}}> <div style={{ position: "relative", width: "100%" }}>
              <input
                type="text"
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  height: "44px", 
                  padding: "12px 40px 12px 16px",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  color: "#475569" ,
                  borderRadius: "8px",
                  border: "1px solid #CBD5E1",
                  outline: "none", 
                }}
                data-test-id="handleTypeComment"
                onChange={(e) => this.handleTypeComment(e.target.value)}
                placeholder="Type a comment here..."
                value={this.state.commenttext}
                onKeyDown={(e) =>this.entersend(e)}
              />

              <img
              onClick={()=>{this.setState({isCommentAttatchment:true,uploadFilePopUp:true})}}
                src={attachment} 
                alt="Attachment"
                style={{
                  position: "absolute",
                  width: "18px",
                  right: "12px", 
                  cursor: "pointer",
                  top: "50%",
                  transform: "translateY(-50%)",
                  height: "18px",
                  opacity: 0.6 
                }}
              />
            </div>
            {
              this.state.commenttext?.length > 0 ?
              <button style={{width:"80px",height:"44px",border:"none",backgroundColor:"#237182",
                fontSize:"16px",fontFamily:"Poppins",cursor:'pointer',fontWeight:600,
                color:"white",borderRadius:"10px",bottom:"24px"}} 
              onClick={this.postCommentAPIs}>Send</button>:
              <button style={{width:"80px",height:"44px",border:"none",backgroundColor:"#F1F5F9",fontSize:"16px",fontFamily:"Poppins",cursor:'pointer',
                fontWeight:600,color:"#64748B",borderRadius:"10px",bottom:"24px"}} disabled={true}>Send</button>
            }
          </div>   
        </Box>
      </Modal>
    );
  }
  getFileErrorMessage = (isFileTooLarge: boolean, errorMessage: string | null, fileSize: string) => {
    if (isFileTooLarge) {
      return "File size must not exceed 10 MB.";
    }
    if (errorMessage) {
      return "File loading error";
    }
    return fileSize;
  }
  
  renderFileBlock = (fileMetaData:any) => {
    if (!fileMetaData || fileMetaData.length === 0) {
      return null;
    }
  
    return (
      <>
        <Typography className="attachedFileTypo">Attached Files:</Typography>
        {fileMetaData.map((fileData:any, index:any) => (
          <Paper
            key={index}
            style={{
              borderRadius: "8px",
              padding: "6px 8px",
              display: "flex",
              boxShadow: "rgba(0.1, 0, 0, 0.1) 0px 4px 5px 5px",
              marginTop: "8px",
              justifyContent: "space-between",
              flexDirection: "row",
              borderTop: fileData.errorMessage ? "2px solid #DC2626" : "2px solid #34D399",
              alignItems: "center",
            }}
          >
            <Box 
            style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "12px" }}>
              <img src={fileData.errorMessage? pdfFileWarningIcon : pdfFileIcon} alt="pdfFileIcon" />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography className="fileNameTypo">{fileData.name}</Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    color: fileData.errorMessage ? "#DC2626" : "#1E293B",
                    lineHeight: "16px",
                    textAlign: "left",
                  }}
                >
                  {fileData.errorMessage || fileData.size}
                </Typography>
              </Box>
            </Box>
            <Box 
            style={{ display: "flex", gap: 8 }}>
              {fileData.errorMessage && (
                <Box onClick={() => this.handleRemoveUploadedFile(fileData.name)}>
                  <img src={reload} style={{ marginTop: "5px", height: 18, width: 18, cursor: "pointer" }} />
                </Box>
              )}
              <Box onClick={() => this.handleRemoveUploadedFile(fileData.name)}>
                <img src={cancelIcon} alt="cancelIcon" />
              </Box>
            </Box>
          </Paper>
        ))}
      </>
    );
  };
  

  renderUploadModal=()=>{
    const hasErrorFiles = Array.isArray(this.state.fileMetadata) 
    ? this.state.fileMetadata.some((file) => file.errorMessage) 
    : false;
  
    return(
      <MainWrapper><CustomUploadDialog  PaperProps={{
                    style: {
                      borderRadius: "16px",
                      width: '584px',
                      boxSizing:"border-box",
                      padding: "24px 24px",
                      color: "#FFFFFF",
                    }
                  }} 
                  open={this.state.uploadFilePopUp} onClose={this.handleCloseUploadDialog}>
                    <Loader loading={this.state.loader}/>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        marginBottom: "40px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="dialogTitleTypo">
                        Upload Files</Typography>
                      <Typography
                        data-test-id="handleCloseUploadDialog"
                        onClick={this.handleCloseUploadDialog}
                        style={{
                          color: "black",
                          cursor: "pointer",
                          width: "20px",
                        }}
                      >
                        <Clear style={{width:'20px'}} />
                      </Typography>
                    </Box>
                    <Box>
                  <Dropzone
                    onDrop={this.onDrop}
                    multiple={true}
                  >
                    {({ getRootProps, getInputProps }:any) => (
                      <Box
                        {...getRootProps()}
                        style={{
                          borderRadius: "8px",
                          cursor: "pointer",
                          border: "2px dashed #CBD5E1",
                          padding: "40px",
                          textAlign: "center",
                          backgroundColor: "#F8FAFC",
                        }}
                      >
                        <input {...getInputProps()}  multiple/>
                        <Typography
                          style={{
                            fontFamily: 'Poppins',
                            color:'#292524',
                            fontSize: '14px',
                            textDecorationSkipInk: 'none',
                            fontWeight: 600,
                            lineHeight: '22px',
                            textAlign: 'center',
                            textUnderlinePosition: 'from-font',
                          }}
                        >
                        Drag & Drop File Here
                        </Typography>
                        <Typography
                          style={{
                            color:'#475569',
                marginTop:'4px',
                fontWeight: 400,
                fontFamily: 'Poppins',
                textUnderlinePosition: 'from-font',
                fontSize: '12px',
                textDecorationSkipInk: 'none',
                lineHeight: '16px',
                textAlign: 'center',
              }}
            >
                    Acceptable formats: JPG, PDF, PNG 
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Poppins',
                        lineHeight: '16px',
                        fontSize: '12px',
                        color:'#475569',
                        fontWeight: 400,
                        textDecorationSkipInk: 'none',
                        textAlign: 'center',
                        textUnderlinePosition: 'from-font',
                      }}
                    >
                      (Max size: 10MB)
                        </Typography>
                      </Box>
                    )}
                  </Dropzone>
                  {this.state.file && this.renderFileBlock(this.state.fileMetadata)} 
                
                  
                </Box>
       
        <DialogActions
          style={{
            marginTop: "38px",
            gap: "16px",
            padding: 0, }}
        >
          <CancelButton data-test-id="handleCloseUploadDialog" 
          onClick={this.handleCloseUploadDialog}>Cancel</CancelButton>
          <this.UploadButton
                disabled={hasErrorFiles || !this.state.file || this.state.file.length === 0} isEnabel={!hasErrorFiles && this.state.file && this.state.file.length > 0}   onClick={this.handleUploadFile}>
              Upload  </this.UploadButton>
        </DialogActions>  </Box>
    </CustomUploadDialog>
    </MainWrapper>
      
    )
   }
  renderRoomDetailModel= () =>{
    const startDate = new Date(this.state.selectedRoomStartDate)
    const endDate = new Date(this.state.selectedRoomEndDate)
    const totalAddMem = Array.isArray(this.state.addedMembers) ? this.state.addedMembers.slice(0, 3) : [];
    return (
      <Modal open={this.state.isRoomOpen} style={{ display: "flex" }}>
        <Box
          component="div"
          style={{
            overflowY:"scroll",
            width: "40vw",
            height: "100%",
            padding: "24px",
            border: "none",
            outline: "none", 
            boxShadow: "none", 
            background: "white",
            position: "fixed",
            top: 0,
            right: 0,
            transform: "translateX(0)",
            display:"flex",
            gap:"20px",
            flexDirection:"column",
            justifyContent:"space-between",
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
          }}
        >
         <Box> 
          <Box style={{ ...webStyle.modalHeader, marginBottom: "20px" }}>
            <Typography style={webStyle.renameText}>Room Details</Typography>
            <Close
              data-test-id="close-filter-modal-id"
              onClick={()=>{this.setState({isRoomOpen:false,isRoomClicked:false});this.getRoomListApi()}}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box style={{display:"flex", alignItems:"flex-start",justifyContent:"space-between" , gap:"32px"}}>
           
            <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between" , width:"33%"}}>
            <Box  style={{display:"flex",flexDirection:"column",gap:"8px",marginRight:"0px", paddingRight:"0px"}}>
                  <Typography style={webStyle.innerDetail} > <DateRangeOutlined style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Start Date:</Typography></Typography>
                  <Typography style={webStyle.innerDetail} ><DateRangeOutlined style={webStyle.iconDetailColorStyle}/>  <Typography style={webStyle.multipleDetailDataStyle}>Due Date:</Typography></Typography>
               </Box>
               <Box  style={{display:"flex",flexDirection:"column",gap:"8px",marginRight:"0px",alignItems:'end'}}>
               <Typography style={{
              fontWeight: 400,
              fontFamily: "Poppins",
              color: '#1E293B',
              fontSize: "14px",
              lineHeight: "22px",
              display: "flex",
              alignItems: "center"
            }}>
              {`${String(startDate?.getDate()).padStart(2, '0')}/${String(startDate?.getMonth() + 1).padStart(2, '0')}/${String(startDate?.getFullYear()).slice(-2)}`}
            </Typography>

<Typography style={{
  color: '#1E293B',
  lineHeight: "22px",
  fontWeight: 400,
  fontSize: "14px",
  fontFamily: "Poppins",
  display: "flex",
  alignItems: "center"
}}> 
  {`${String(endDate?.getDate()).padStart(2, '0')}/${String(endDate?.getMonth() + 1).padStart(2, '0')}/${String(endDate?.getFullYear()).slice(-2)}`}
</Typography>

               </Box>
            </Box>
            <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between",width:"40%",lineHeight:"24px"}}>
               <Box style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                  <Typography style={webStyle.innerDetail} > <SquareFootOutlined style={webStyle.iconDetailColorStyle} /> <Typography style={webStyle.multipleDetailDataStyle}>L x W x H:</Typography> </Typography>
               </Box>
               <Box  style={{display:"flex",flexDirection:"column",gap:"8px",marginLeft:'5px'}}>
              

           
            <Typography style={{
                          fontWeight: 400,fontFamily: "Poppins",color: '#475569',fontSize: "14px",display: "flex",alignItems: "center"}}>
                            {this.state.selectedRoomLength}m x {this.state.selectedRoomWidth}m x {this.state.selectedRoomHeight}m </Typography>
               </Box>
            </Box>
          </Box>   
          <div style={{height:"2px",borderRadius:"10px",width:"100%",backgroundColor:"#E2E8F0",marginTop:'16px'}}></div>  
          <Box style={{display:"flex",flexDirection:"column",gap:"5px", marginBottom:"10px", marginTop:"24px"}}>
          <Typography style={{fontSize:"16",fontWeight:600,color:"#334155",fontFamily:"Poppins",}}>Tasklist Details</Typography>
             
         <Box>
          {this.state.selectedRoomTasklist.length==0 && <Typography style={{
                          fontWeight: 400,
                          fontFamily: "Poppins",
                          color: '#0F172A',
                          fontSize: "14px",
                          lineHeight: "22px",
                          display: "flex",
                          alignItems: "center"
                        }}>No task list added </Typography>}
          {this.state.selectedRoomTasklist?.map((item:any,index:any)=>(
            <Box style={{
              padding:'6px 8px',
             borderRadius:"8px",
             display:"flex",justifyContent:"space-between",
             marginTop:"8px",
             cursor: "pointer"
            }} data-test-id="listclicked" onClick={()=>{this.handleTaskListDetailCard(item.id,this.state.currentRoomIDValue);
              this.setState({selectedTasklistName:item.task_list_name,isRoomOpen:false,isRoomClicked:true,isTaskListDetailOpen:true,
                addedMembers:item.task_list_members,
                selectedTasklistPriority:item.priority,selectedTasklistStatus:item.status})
            }}>
              <Typography  style={{
                          fontWeight: 400,
                          fontFamily: "Poppins",
                          color: '#0F172A',
                          fontSize: "14px",
                          lineHeight: "22px",
                          display: "flex",
                          alignItems: "center"
                        }}>Tasklist {index+1}: {item.task_list_name}</Typography>
              <ArrowForwardIos style={{color:"#475569",height:'20px'}}/>
            </Box>
          ))}
         </Box>
         
          </Box>  
          </Box>
         
        </Box>
      </Modal>
    );
  }
  hasNoResults=(searchText:any, list:any)=>{
    return searchText && list.length === 0;
  }
  getMemberMessage = (noResultsMember:any, noMembersMember:any) => {
    if (noResultsMember) {
      return "No results found";
    }
    if (noMembersMember) {
      return "No members available";
    }

    return "";

  };
  renderConfirmDeleteModal = () => {
    return (
      <DeleteDialog data-test-id="CustomDeletePopup" PaperProps={{
        style: {
          boxSizing: "border-box",
          height: "232px",
          borderRadius: "16px",
          padding: "42px 32px",
          width: '584px',
          color: "#FFFFFF",
        }
      }}
       open={this.state.openConfirmDeleteModal} 
       onClose={this.closeDeleteModal}>
        <Box sx={{ padding: 0 }}>
          <Box style={{
            justifyContent: "space-between",
            display: "flex",
          }}>
            <Typography className="title" >Are you sure you want to delete this file? </Typography>
             <Typography onClick={this.closeDeleteModal} 
             style={{
               width: "20px",
              color: "black",
              marginTop: "1px"
            }}>

              <Clear /></Typography>
              </Box>
          <Typography
           style={{ 
            color: "#1E293B", fontSize: "16px", fontWeight: 400, fontFamily: "Poppins", lineHeight: "24px", marginTop: "10px" }}>This action cannot be undone.</Typography>

          <DialogActions 
          style={{
            gap: "12px",
            marginTop: "25px",
            padding: "0px"
          }}>
            <Button data-test-id="handleCloseDialog"
             className="cancelButton" 
            onClick={this.closeDeleteModal}>
              Cancel
            </Button>
            <Button 
            data-test-id="handleConfirmDelete" className="confirmButton" 
            onClick={this.deleteFile}>
              Delete
            </Button>   </DialogActions>
        </Box>
      </DeleteDialog>
    )
  }
  renderModal() {
    const {selectedMembers, searchText, searchFieldError,customSelectedMembers} = this.state
    const members = Array.isArray(this.state.getAllMembers) ? this.state.getAllMembers : []
    const filteredMembers = members.filter((member:any) =>
      member.first_name.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
      member.email.toLowerCase().includes(this.state.searchText.toLowerCase())
    );
    const noResultsMember = this.hasNoResults(this.state.searchText, filteredMembers); const noMembersMember = members.length === 0;
    console.log("addedMembers",this.state.addedMembers)
    return (
      <Modal open={this.state.isAssignMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyle.renameModal}>
          <Box style={{...webStyle.modalHeader, marginBottom: "8px"}}>
            <Typography style={webStyle.renameText}> Assign Member </Typography>
            <Close data-test-id="close-data-id" onClick={this.handleAddMemberModal} style={{ cursor: "pointer" }} />
          </Box>
          <Typography style={{ color: "#1E293B" }}> Select a user from the list below or search by name or email.
           </Typography>
     <Box style={{ flexDirection:'row',  display:'flex', alignItems: "center", gap:'16px', width: "100%"}}>
        <Box style={{ overflowY: "auto", width: "100%",maxHeight:'70px',  display: "flex",flexDirection: "row",  padding: "2px 5px",  alignItems: "center",gap: "12px",  border: "1px solid #ccc",borderRadius: "8px", }}>
  <img src={searchIcon} 
  style={{ marginRight: '0px',marginLeft: '5px',marginTop: '-3px'}} />
      <Box sx={{ display:'flex', flexWrap: "wrap",width:'100%', }}  >


{selectedMembers.map((member:any) => (
<CustomChip label={member.first_name} onDelete={() => this.removeChipMember(member.id)} key={member.id}
deleteIcon={<img src={crossSmallIcon}/>} style={{  color: '#325962',fontWeight: 400,backgroundColor: '#E0EDF0',borderRadius: '16px',fontSize:'14px',fontFamily:'poppins',}}/>))}
 {this.state.customTextEntries.map((text: string, index: number) => (
    <CustomChip data-test-id="removee"  key={`custom-${index}`} label={text}
      onDelete={() => this.removeCustomText(index)} deleteIcon={<img src={crossSmallIcon} />}
      style={{ color: "#475569", fontSize: "14px", backgroundColor: "#F1F5F9",  fontFamily: "Poppins", borderRadius: "16px", fontWeight: 400, }}/> ))}


<TextField variant="standard"error={!!this.state.searchFieldError}
  size="small" placeholder={this.getMemberPlaceholder()} value={searchText} fullWidth onChange={this.handleSearch}
  onKeyDown={this.handleKeyDownMember}
  InputProps={{
    disableUnderline: true,
    style: {textAlign: 'left', color: '#0F172A', minWidth:'50px', fontFamily: 'Poppins', margin: '0px', padding: '0px', textUnderlinePosition: 'from-font', fontSize: '16px',
      fontWeight: 400, lineHeight: '24px', textDecorationSkipInk: 'none', },
  }}
  sx={{
    flex: 1,
    '& .MuiInputBase-input': {
      fontFamily: 'Poppins',
      fontWeight: 400,
      padding: '0px !important',
      textAlign: 'left',
      fontSize: '16px',
      margin: '8px',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#94A3B8',
      marginLeft:'-10px',
      fontWeight: 400,
      fontSize: '16px',
      fontFamily: 'Poppins',
    },
  }}
/>


</Box>
<IconButton onClick={this.clearSearch} sx={{ marginLeft: "8px", color: "gray",marginTop:'-2px' }}><img src={crossSearchIcon} />
</IconButton>
</Box>

<Box sx={{display: "flex", justifyContent: "flex-end",marginRight:'-0px'}}>
</Box>
</Box>
            <Box>
{this.state.searchFieldError &&
 (<Box mt={1}    bgcolor=""  p={1} borderRadius={1}>
          <Typography    style=   {{color:'#DC2626',
            fontFamily:'poppins',
            fontWeight:600,
            marginTop:'-16px',
            fontSize:'12px',
          marginLeft:'-6px',
          }} variant="body2">  {this.state.searchFieldError} </Typography>  </Box>  )}
    </Box>
    {(noResultsMember || noMembersMember) && 
    ( <Box
    sx={{ textAlign: "center",
      padding: "10px",
      fontSize: "16px",fontWeight: 400, lineHeight: "24px",
      color: "#0F172A", fontFamily: "Poppins",
    }}
  >
    <Typography>   No matches found. Add '{this.state.searchText}' as text? </Typography>
    
    {this.state.searchText && (
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  color: "#0F172A",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              > {this.state.searchText}
              </Box>  
               {this.state.customTextEntries.includes(searchText.trim())? (
          <Box
            style={{
              color: "#16A34A",
              gap: "6px",
              fontWeight: 600,
              alignItems: "center",
              fontSize: "16px",
              display: "flex",
            }} >  Added as text <Done/>  </Box>
        ) : (
          <RenameButton
            style={{  padding: "6px 10px",  color: "#325962",  backgroundColor: "#E0EDF0", }}
            onClick={this.handleAddText} >  Add as text </RenameButton>
        )}    
      </Box>
          )}
  </Box>
)}
   
            {!noResultsMember && !noMembersMember && (  <List sx={{ mt: 2, maxHeight: "300px", overflowY: "auto" }}>
              {this.getFilteredMember(filteredMembers, selectedMembers).map((member: any) => (
                <ListItem key={member.id} sx={{   cursor: "pointer",   padding: "8px 15px",   backgroundColor: "transparent",   "&:hover": { backgroundColor: "transparent" }, }}
                  data-test-id="toggleSelectMember"  onClick={() => this.toggleSelectMember(member)}
                >
                  <Checkbox data-test-id="checkedd" 
                 checked={
                  member.isCustom
                    ? !customSelectedMembers.some((m: any) => m.id === member.id) 
                    : selectedMembers.some((m: any) => m.id === member.id) ||
                      (Array.isArray(this.state.addedMembers) &&
                        this.state.addedMembers.some((m: any) => m.account_id === member.id))
                }
                 
                    onClick={(e) => e.stopPropagation()}    onChange={() => this.toggleSelectMember(member)}
                    icon={
                      <img src={unCheckIcon} style={{ width: 20, height: 20 }} alt="unchecked" />
                    }
                    checkedIcon={
                      <img src={checkedIcon} style={{ width: 20, height: 20 }} alt="checked"  />
                    }
                    style={{ pointerEvents: "auto" }}
                  />
            
            <ListItemIcon>{member.profile_picture ? (
    <img
    alt={member.first_name}
      src={member.profile_picture}
      style={{
        borderRadius: "50%",
        height: "44px",
        objectFit: "cover",
        width: "44px",
        border: "2px solid #E0EDF0", }} /> ) : (
    <Box
      style={{ background: "#D3E4E9", color: "#ABCDD5", width: "44px", height: "44px", borderRadius: "50%", border: "2px solid #E0EDF0", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <span
        style={{fontFamily: "Poppins",   fontSize: "18px",   fontWeight: 700,   lineHeight: "26px",   textAlign: "center", }} >{member.first_name.slice(0, 2).toUpperCase()} </span>
    </Box>)}
</ListItemIcon>
            
                  <ListItemText
                    primary={
                      <>  <Typography className="memberTabNameTypo">{member.first_name}</Typography>  <Typography className="memberTabEmailTypo">{member.email}</Typography>  </>
                    }
                    sx={{
                      transition: "none",
                      pointerEvents: "none",
                      ":hover": {
                        color: "black",
                      },  }}
                  />
                </ListItem>
              ))}
            </List>
            
            )}
           <Box style={webStyle.modalButtons}>
            <RenameButton style={webStyle.cancelButton} onClick={ this.handleAddMemberModal}       data-test-id="cancel-btn"  >
              Cancel
            </RenameButton>
            <RenameButton  data-test-id="assign-member-data-id"  onClick={this.handleAddButtonClick}
            >  Save
            </RenameButton>
          </Box>

        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <MyProfileHeader navigation={this.props.navigation} showSidebar={true} profilePhoto={this.state.updatedProfile}/>
       <CustomToast data-test-id="customToast" icon={<img src={successIcon} />} />
        <div style={{
          background:"cover !important",
          backgroundColor:"#E2E8F0",
          display:"flex",
          flexDirection:"column",
          paddingLeft:"30px",
          height: "100vh", 
          overflow: "auto",    
          marginLeft:'110px',
          position:'relative'
          }}>
            <div>
              <div style={webStyle.ganttBoxWrapper}>
                <span style={webStyle.mainTitle}>
                {this.state.projectDetails?.project_name ? this.state.projectDetails.project_name : "--"}
                   </span>
              </div>
              <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row",marginTop:"20px",paddingRight:'30px'}}>
                <div>
                 <CustomTab 
                 value={this.state.activeTab} 
                 onChange={this.handleChange} 
                 style={{display:"flex",gap:"5px",color:"#325962",backgroundColor:"#FFFFFF",width:"300px",flexDirection: "row",borderRadius:"50px",zIndex: 0,position: "relative",padding:2}}>
                    <Tab label="Week"  style={{minWidth:"auto",...webStyle.tabTitle,...(this.state.activeTab === 0 && {
                      background: "#E0EDF0", 
                      borderRadius: "50px",color:'#325962',border:'1px solid #D3E4E9'
                    })
                    }} value={0} />

                    <Tab label="Month" style={{minWidth:"auto",...webStyle.tabTitle,...(this.state.activeTab === 1 && {
                      background: "#E0EDF0", 
                      borderRadius: "50px",color:'#325962',border:'1px solid #D3E4E9'
                    })}} value={1}/>
                    <Tab label="Quater" style={{minWidth:"auto",...webStyle.tabTitle,
                      ...(this.state.activeTab === 2 && {
                        background: "#E0EDF0", 
                        borderRadius: "50px",color:'#325962',border:'1px solid #D3E4E9'
                      })
                    }} value={2}/>
                    <Tab label="Year" style={{minWidth:"auto",...webStyle.tabTitle,
                      ...(this.state.activeTab === 3 && {
                        background: "#E0EDF0", 
                        borderRadius: "50px",color:'#325962',border:'1px solid #D3E4E9'
                      })
                    }} value={3}/>
                 </CustomTab>
                </div>
                <div style={{display:"flex",gap:"20px"}}>
                  <Button onClick={()=>this.setState({initialDate:new Date()})}
                   style={webStyle.todayButton}
                  >
                    Today
                  </Button>
                         <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop:'10px' }}>
                            <Box data-test-id="toggleDropdown" onClick={this.toggleDropdown} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                <Typography style={{ color: "#284F58", fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins" }}>
                                    {this.state.listView}
                                </Typography>
                                <KeyboardArrowDown style={{ color: "#284F58" }} />
                            </Box>
                        </Box>
                        <Menu
                        data-test-id="onclose"
                            anchorEl={this.state.anchorEl}
                            open={this.state.isDropdownOpen}
                            onClose={() => this.setState({ isDropdownOpen: false })}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            PaperProps={{
                                style: {
                                    marginTop: "46px",
                                    padding: '1px 4px',
                                    borderRadius: '8px',
                                    width: '130px'
                                }
                            }}
                        >
                          <MenuItem key={'Room View'} value={"Room View"} style={{ color: '#0F172A', fontSize: '14px', fontWeight: 400, fontFamily: 'Poppins' }} data-test-id="RoomView"
                          onClick={()=>this.handleChangeSelect('Room View')}>
                                Room View
                                </MenuItem>
                                <MenuItem key={'Task List View'} value={"Task List View"} style={{ color: '#0F172A', fontSize: '14px', fontWeight: 400, fontFamily: 'Poppins' }}  data-test-id="TaskView"
                                onClick={()=>this.handleChangeSelect('Task List View')}>
                                Task List View
                                </MenuItem>
                          
                        </Menu>
                </div>
              </div>
            </div>
           <div style={{marginTop:"10px",display:"grid",width:"1300px",gap:"1px",gridTemplateColumns:"200px auto",height:"100px"}}>
            <div className="roomBlockName">
               <span style={webStyle.tableHead}>Room Name</span>
            </div>
           {
                this.state.activeTab === 0 && 
                <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                   <Calendar activeTabs={this.state.activeTab} eventValues={this.state.events} nextWeek={this.handleNextWeek}  dateValue={this.state.initialDate} prevWeek={this.handlePreviousWeek}/>
                </div> 
            }
           {
                this.state.activeTab === 1 && 
                <div style={{display:"flex",flexDirection:"column",width:"100%"}}> 
                  <Calendar activeTabs={this.state.activeTab} eventValues={this.state.events} nextWeek={this.handleNextWeek}  dateValue={this.state.initialDate} prevWeek={this.handlePreviousWeek}/>
               </div> 
            }
               {
                this.state.activeTab === 2 &&
                this.RenderQuaterCalendar()
               }
               {
                this.state.activeTab === 3 &&
                this.RenderYearCalendar()
               }
            </div> 
            <div 
          style={{ 
            position: "absolute",
            left: this.getVerticalLinePosition(),
            transform: "translateX(-50%)", 
            width: "12px",
            top:230,
            height: "12px",
            backgroundColor: "#237182",
            borderRadius: "50%",
            zIndex: 10000,
          }} 
        />
         <div 
          style={{ 
            position: "absolute",
            height: '100vh',
            width: "1px", 
            top:230,
            backgroundColor: "#237182", 
            left: this.getVerticalLinePosition(), 
            transform: "translateX(-50%)",
            transition: "left 0.3s ease-in-out",
            zIndex:9999
          }} 
        />
            {
            this.state.listView === "Room View" &&
            <div style={{ display: "grid", width: "100%" }}>
              {this.RenderRoomList()}
            </div>
          }

            {
              this.state.listView === "Task List View" &&
              <div style={{display:"grid",width:"100%"}}>
                {this.RendertaskName()}
              </div>
            }
            {this.renderTaskListDetailModel()}
            {this.renderRoomDetailModel()}
            {this.renderUploadModal()}
            {this.renderModal()}
           {this.renderConfirmDeleteModal()}
           <DialogCustom 
              open={this.state.openError}
              data-test-id="closedialog"
              onClose={()=>this.setState({openError:false})}
              style={{
                display: "flex",
                alignItems: "flex-start",  
                justifyContent: "center",  
              }}
              BackdropProps={{
                style: { backgroundColor: "transparent" }
              }}
            >
              <div style={{
                background: "white", 
                padding: "10px 16px", 
                borderRadius: "8px",
                display:'flex'
              }}>
                {this.state.isSuccess?
                <CheckCircleRounded style={{color:'#34D399',marginRight:'5px'}}/>
                :
                <ErrorOutlinedIcon style={{color:'#DC2626', marginRight:'5px'}}/>
                }
               <Typography > {this.state.statusError}</Typography>
              </div>
            </DialogCustom>
        </div>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DialogCustom =styled(Dialog)({
  "& .MuiDialog-paper":{
    position: 'absolute',
    top: '43px',
  }
})
const CancelButton = styled(Button)({
  backgroundColor: "#E0EDF0",
  borderRadius: "8px",
  border: "1px solid #C5E0E7",
  color: "#325962",
  fontWeight: 600,
  fontSize: "16px",
  height: "56px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  boxShadow: "none",
  fontFamily: "Poppins",
  borderWidth:0,
  '&:hover': {
    backgroundColor: '#E0EDF0', 
  },
});
const CustomToast = styled(ToastContainer)({
  width: "350px",
  marginTop: "0px",
  "& .Toastify__toast": {
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  },
  "& .Toastify__toast-body": {
    color: "#0F172A",
    fontFamily: "Poppins",
    padding: 0,
  },
});
const RenameButton = styled(Button)({
  borderRadius: "8px",fontFamily: "Poppins",padding: "1rem", textTransform: "none",
  background: "#237182",color: "#FFF",fontWeight: 600, fontSize: "16px",
  "&:disabled": {
    background: "#F1F5F9", color: "#64748B",
  },
  "&:hover": {background: "#237182", },
});
const CustomTab = styled(Tabs)({
  '& .PrivateTabIndicator-colorSecondary-7':{
    display:'none'
  },
  '& .PrivateTabIndicator-colorSecondary-8':{
    display:'none'
  },
  '& .PrivateTabIndicator-colorSecondary-196':{
    display:'none'
  },
  '& .jss8':{
    display:"none"
  },
})
const CustomDropDown = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#fff",
    color: "#237182",
  },
  "& .MuiSelect-select": {
    paddingBlock: "10px",
    color: "#237182",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily:'Poppins'
  },
  "& .MuiSvgIcon-root": {
    color: "#237182",
  },
});
const DeleteDialog = styled(Dialog)(({ theme }) => ({
  "& .title": {
    fontSize: "24px",
    fontWeight: 700,
    letterSpacing: "-0.005em",
    lineHeight: "32px",
    color: "#0F172A",
    fontFamily: "Poppins",
  },
  "& .cancelButton": {
    backgroundColor: "#E0EDF0",
    width: "90px",
    fontFamily: "Poppins",
    textTransform: "none",
    fontSize: "16px",
    color: "#325962",
    borderRadius: "8px",
    height: "56px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#E0EDF0",
    }
  },
  "& .confirmButton": {
    color: "#FFFFFF",
    fontSize: "16px",
    height: "56px",
    fontFamily: "Poppins",
    width: "84px",
    background: "#237182",
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    "&:hover": {
      background: "#237182",
    }
  },
  "& .dialogPaper": {
    border: '1px solid #237182',
    borderRadius: '12px',
    padding: '24px',
    backgroundColor: '#F0F5F7',
  },
  "& .dialodDescTypo": {
    textUnderlinePosition: 'from-font',
    fontFamily: 'Poppins',
    color: '#334155',
    fontSize: '16px',
    textDecorationSkipInk: 'none',
    lineHeight: '24px',
    fontWeight: 400,
    textAlign: 'left',
  },
  "& .dialogLabel": {
    "& .MuiFormControlLabel-label": {
      color: '#0F172A',
      fontWeight: 600,
      textAlign: 'left',
      fontFamily: 'Poppins',
      textDecorationSkipInk: 'none',
      fontSize: '16px',
      lineHeight: '24px',
      textUnderlinePosition: 'from-font',
    }
  }
}));
const CustomChip = styled(Chip)(({ theme }) => ({
  color: '#475569',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  textAlign: 'right',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  backgroundColor: '#E0EDF0',
  padding: '6px 2px 6px 6px',
  borderRadius: '24px',
  gap: '2px',
  alignItems:'center',
  height: '32px',
  margin:'4px',
  '& .MuiChip-deleteIcon': {
    color: '#475569',
  },
  '& .MuiChip-label':{
    marginLeft:'-8px',
    marginRight:'-4px'
  }
}));
const webStyle = {
  tabTitle:{
    color:'#64748B',
    fontSize:"16px",
    fontWeight:600,
    fontFamily:'Poppins',
    textTransform:'none' as 'none'
  },
  mainTitle:{
    color:'#0F172A',
    fontSize:'24px',
    fontWeight:700,
    fontFamily:"Poppins"
  },
  todayButton:{
    color:"#325962",
    fontSize:'16px',
    fontWeight:600,
    fontFamily:'Poppins',
    background:'#E0EDF0',
    textTransform:'none' as 'none',
    borderRadius:'8px',
    height:'32px',
    marginTop:'12px',
    cursor:'pointer'
  },
  tableHead:{
    color:'#334155',
    fontSize:'14px',
    fontWeight:600,
    fontFamily:"Poppins",
  },
  mainBox: {
    backgroundColor:"#E0EDF0",
    display:"flex",
    flexDirection:"column",
    paddingLeft:"30px",
    paddingRight:"30px"
  },
  calendarRoom:{
   backgroundColor:"#FFFFFF",
   width:"150px",
   height:"auto",
   display:"flex",
   alignItem:"space-between",
   flexDirection:"column",
   padding:"20px",
   marginBottom:"20px"
  },
  searchType:{
   width:"90vw",
   backgroundColor:"#FFFFFF",
   height:"fit-content"
  },
  bg:{
    backgroundColor:"#E2E8F0",
  },
  roomName:{
    height:"200px",
    display:"flex",
    alignItems:"center",
    color:"#475569"
  },
  viewSwitcherWrapper: {
    marginTop: "10px"
  },
  ganttBoxWrapper: {
    marginTop: "20px"
  },
  buttonStyle: {
    marginLeft: "10px",
    border: "none",
    backgroundColor: "#F0F0F0",
  },
  chkboxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  dateText:{
    color:"#1E293B",
    fontSize:'12px',
    fontWeight:600,
    fontFamily:'Poppins',
  },
  periodText:{
    color:"#64748B",
    fontSize:"12px",
    fontWeight:600,
    fontFamily:'Poppins',
  },
  monthText:{
    color:'#334155',
    fontSize:"12px",
    fontWeight:400,
    fontFamily:'Poppins'
  },
  percentageText:{
    color:'#0F172A',
    fontSize:'12px',
    fontFamily:"Poppins",
    fontWeight:400
  },
    iconDetailColorStyle:{
      color:"#475569", width:"24px", height:"24px", radius:"40px",
    },
    multipleDetailDataStyle:{
      fontFamily:"Poppins",
      fontSize:"14px",
      fontWeight:400,
      color:"#475569",
    },
    roomDataStyle:{
      display:"flex", justifyContent:"start",
      alignItems:"center", gap:"8px", fontFamily:"poppins",
      fontSize:'12px',
      fontWeight:600,
      color:"#64748B"
    },
    filterSection:{
      padding:"6px 8px 6px 0px",
      display:"flex",
      fontWeight:600,
      fontFamily:"poppins",
      justifyContent:"space-between",
      alignItems:"center",
      fontSize:"14px",
      gap:"10px",
      color:"#0F172A"
    },
    renameText: {
      fontSize: "1.5rem",
      fontWeight: 700, lineHeight: "2rem",
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      color: "#0F172A",
      alignItems: "center",
      marginBottom: "1rem",
    },
    renameModal: {
      margin: "auto", width: "40vw",
      background: "white",
      height: "fit-content", padding: "2.5rem 2rem",
      boxShadow:'none',
      borderRadius: "16px",
      outline:'none'
    },
    filterModal: {
      height: "100%",
      overflowY:"scroll",
      padding: "2.5rem 2rem",
      width: "40vw",
      position: "fixed",
      boxShadow:
        "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
      background: "white",
      right: 0, 
    top: 0,
    transform: "translateX(0)",
    whiteSpace:"nowrap",
    },
    inviteMemberStyle:{
      alignItems: "center",
      display: "flex",
      margin: "0.5rem auto",
      justifyContent: "start",
      gap: "8px",
      paddingLeft:"12px"
    },
    modalButtons: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "1rem",
      marginTop: "2rem",
    },
    filterButton: {
      marginTop:"16px",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      marginBottom:"2rem"
    },
    cancelButton: { background: "#E0EDF0", color: "#325962",
    },
    cancelFilterButton: {
      fontWeight:700,
      fontFamily:"poppins",
      color: "#237182",
      fontSize:"18px"
  
    },
    statusStyle:{
      padding:"4px 6px 4px 8px",
      backgroundColor:"#3B82F6" ,
      fontFamily:'Poppins',
      fontSize:"12px",
      color:"#FFFFFF",
       fontWeight:400 ,
       borderRadius:"40px",
  
    },
    filterStyle:{
      BorderColor:"#CBD5E1",
      borderRadius:"8px",
       display:"flex",
       backgroundColor:"white",
       alignItems:"center",
       width:"7%",
       padding:"10px 12px",
       paddingRight:"25px",
    },
    containerDataStyle: {
      display: "flex",
      color: "#64748B",
      alignItems: "center",
      gap: "4px",
    },
    priorityStyle: {
      width: "69px",
      borderRadius: "16px",
      padding: "1px 8px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      justifyContent: "center",
    },
    priorityStyleList: {
      borderRadius: "16px",
      display: "flex",
      alignItems: "center",
      width: "50px",
      padding: "1px 8px",
      justifyContent: "center",
      gap: "8px",
    },
    roomNameStyle: {
      fontWeight: 600,
      marginTop: "10px",
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#334155",
    },
    roomStyleCard: {
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      gap: "8px",
    },
    roomStyleMainCard:{
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
    },
  
    roomStatusCircleStyle: {
      width: "6px",
      backgroundColor: "#60A5FA",
      height: "6px",
      borderRadius: "50%",
    },
    roomTypeStyle: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 600,
      color: "#64748B",
    },
    cardOuterContainer: {
      borderRadius: "12px",
      margin:"1rem 0rem",
      padding: "12px",
      backgroundColor: "#FFFFFF",
      width:"95%"
    },
    mainStatusStyle: {
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      width: "100%",
    },
    statusCountStyle: {
      fontWeight: 600,
      alignItems:"center",
      justifyContent:"center",
      display:"flex",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      color: "white",
      fontFamily: "poppins",
      fontSize: "12px",
      textAlign: "center" as "center",
      backgroundColor: "#237182",
    },
  
    statusCircleStyle: {
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      backgroundColor: "#60A5FA",
      marginLeft:"1rem"
    },
    roomsStatusCircleStyle: {
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      backgroundColor: "#237182",
    },
    outerBoxStatus: {
      marginTop:"2rem",
      backgroundColor: "#FFFFFF",
      width: "95%",
      borderRadius: "8px",
      alignItems: "center",
      padding: "6px 0px",
      display: "flex",
      justifyContent: "left",
      gap: "8px",
    },
    gridCont: {
       paddingLeft:"6.5rem"
    },
  
    headerSection2: {
      display: "flex",
      justifyContent: "space-between",
      marginTop:"1.5rem",
      alignItems: "center",
      fontFamily: "poppins",
      fontSize: "16px",
    },
    outerBox: {
      borderRadius: "5px",
      alignItems: "center",padding: "0px",
      backgroundColor: "white",
      display: "flex",   justifyContent: "space-between",
      margin: "1.625rem 40px 0px 40px", paddingRight: "10px",
      paddingLeft: "10px",
    },
    OptionBox: {
      display: "flex", justifyContent: "start",
    },
    singleOptionBox: { display: "flex",
      backgroundColor: "#F8FAFC", borderRadius: "50px",
      padding: "4px",
    },
  
    PersonalInformationOption: {
      borderRadius: "50px", fontFamily: "Poppins",
      fontWeight: 600, padding: "9px 16px",
      cursor: "pointer", fontSize: "16px",
    },
  
    BusinessInformationOption: {
      padding: "9px 16px", borderRadius: "50px",
      cursor: "pointer", fontFamily: "Poppins",
      fontWeight: 600, margin: "0px",
      fontSize: "16px",
    },
    flex: {
      display: "flex", justifyContent: "space-between",
      alignItems: "center",padding: "0px 40px 0px 40px", marginTop: "32px",
      backgroundColor: "#f0f5f7",
    },
    sort: {
      marginRight: "3px",  fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400, color: "#334155",
    },
    innerDetail:{ display:"flex",
      alignItems:"center", gap:"8px",
      fontSize:"14px"
    }
    
};
const CustomUploadDialog = styled(Dialog)({
  "& .dialogTitleTypo":{
    fontWeight: 700,
    fontFamily: 'Poppins',
    fontSize: '24px',
    color:'#0F172A',
    lineHeight: '32px',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    letterSpacing: '-0.005em',
    textAlign: 'left',
  },
  "& .fileNameTypo":{
    color:'#0F172A',
    fontFamily: 'Poppins', fontSize: '14px',fontWeight: 400,
    lineHeight: '22px',textAlign: 'left', textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .fileSizeTypo":{ color:'#64748B',
    fontFamily: 'Poppins', fontSize: '12px',
    fontWeight: 400, lineHeight: '16px', textAlign: 'left', textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .attachedFileTypo":{
    marginTop:'16px', color:'#334155',
    fontFamily: 'Poppins', fontSize: '16px',
    fontWeight: 600, lineHeight: '24px',
    textAlign: 'left', textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  }

});

const MainWrapper = styled(Box)(({ theme }) => ({
  height:'100%',
  "& .main_box": { marginTop: '24px'
  },
  "& .main_paper": {  marginTop: '32px',
      marginBottom: '32px', borderRadius: '12px'
  },
  "& .main_paper1": { marginTop: '16px', marginBottom: '32px',
      borderRadius: '12px'
  },
  "& .main_paper2": {
      marginTop: '32px', marginBottom: '24px', borderRadius: '12px'
  },
  "& .first_grid_box": { display: 'flex',
      flexDirection: 'column', gap: '8px',
      padding: '0px 24px'
  },
  "& .second_grid_box": {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',padding: '24px 24px'
  },
  "& .third_grid_box": {
      padding: '16px 24px'
  },
  "& .thirdGridHeadingTypo": {
    display: '-webkit-box',
    WebkitLineClamp: 2, textOverflow: 'ellipsis',
    color:'#0F172A',
    overflow: 'hidden',  WebkitBoxOrient: 'vertical',
    textUnderlinePosition: 'from-font',
    fontFamily: 'Poppins', fontSize: '16px',
    fontWeight: 600,lineHeight: '24px',textAlign: 'left',
    textDecorationSkipInk: 'none',
  },
  "& .thirdGridDataTypoBox": {
    alignItems:'center',
    flexDirection: 'row',
    marginBottom: '4px',
    display: 'flex',
  },
  "& .thirdGridDataTypoBox2": {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
      alignItems: 'center'
  },
  "& .thirdGridDataTypo": {
    textUnderlinePosition: 'from-font',
      color: '#64748B',
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 400,
      fontFamily: 'Poppins',
      textDecorationSkipInk: 'none'
  },
  "& .thirdGridNameTypo": {
    fontWeight: 400,
    fontSize: '14px',
    color:'#64748B',
    lineHeight: '22px',
    fontFamily: 'Poppins',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .mainBoxFirst":{
    display:'flex', justifyContent:'space-between'
  },
  "& .OptionBox": {
    display: "flex", justifyContent: "start",
  },
  "& .singleOptionBox": { display: "flex",
    backgroundColor: "#F0F5F7",
    borderRadius: "50px", padding: "4px", height:'36px',
    gap:'8px'
  },
  
}));
// Customizable Area End