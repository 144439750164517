import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as React from "react";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import dayjs from "dayjs";

import moment from "moment";
import { toast } from "react-toastify";
const { PDFDocumentProxy } = require("pdfjs-dist");
const pdfjsLib = require("pdfjs-dist");
if (!pdfjsLib.GlobalWorkerOptions.workerSrc) {
  const pdfjsVersion = require('pdfjs-dist/package.json').version;
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;
}
interface Proposal {
  "id": string,
  "type": string,
  "attributes": {
    "title": string,
    "description": string,
    "price": number,
    "date_of_proposal": string,
    "customer_emails": string[],
    "name_of_proposal_creator": string,
    "customer_name": string

  }
}
interface ProjectDetails{
  projectName:string;
  fileName:string;
  fileCreated_On:string;
  Total_Cost:string;
  Adjusted_Cost:string;
  Address:string;
  AddressCity:string;
  Addressstate:string;
  Addressountry:string;
  Plan_Type:string;
  Retention_Percentage:string;
  PdfUrl:string;
  Status:string
}
export interface VersionHistory {
  id: number | null;
  project_id: number | null;
  message: string;
  action_name: string;
  account_id: number | null;
  created_at: string;
  updated_at: string;
}
export interface ProposalType {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    price: number;
    date_of_proposal: string;
    customer_emails: string[];
    name_of_proposal_creator: string;
    customer_name: string;
  };
}

export interface DateObject {
  day: number;
  dateString: string;
  month: number;
  timestamp: number;
  year: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openCreateForm: boolean,
  title: string,
  openDeleteDialog:boolean,
  openApproveDialog:boolean,
  openOngoingDialog:boolean,
  openRejectDialog:boolean,
  rejectReason:string,
  openCloseDialog:boolean,
  description: string,
  price: string,
  dateOfProposal: string,
  customerName: string,
  customerEmail: string,
  creator: string,
  listOfProposals: Proposal[],
  deleteID: string,
  proposalIndex: number,
  deleteConfirmationPopup: boolean,
  openCalendarPicker: boolean,
  shareActivated: boolean,
  numberOfCustomers: number,
  customersArray: string[],
  showValidateMessage: boolean,
  mode: string,
  shareProposalID: string,
  selectedDate: Date | null,
  proposalDate: null | MaterialUiPickersDate,
  viewproposal: null | Proposal,
  roleName:any,

  uploadFileUrl: string;
  pdfDoc: any;
  totalPages: number;
  pageNumber: number;
  zoom: number;
  currentPage: number;
  fileName: string;
  ProjectDetails :ProjectDetails;
  version_history: VersionHistory[],
  searchInputText: string;
  sortOption: string,
  sortedAndFilteredProjects: any,
  seachAndFilteredProjects: any,
  








  

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Proposalgeneration2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    canvasContainerRef: React.RefObject<HTMLDivElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  pageRendering: boolean = false;
  pageNum: number = 1;

  getProposalsAPICallID: string = "";
  createProposalAPICallID: string = "";
  deleteProposalAPICallID: string = "";
  shareProposalAPICallID: string = "";
  getpdf_generationCallId: string = "";
  createRoomsApiCallId: string = "";
  getClientData: string = "";
  selectPropertyTypesApi1: string = "";
  onGoingApi: string = "";
  rejectTypesApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openCreateForm: false,
      title: '',
      openDeleteDialog:false,
      openApproveDialog:false,
      openOngoingDialog:false,
      openRejectDialog:false,
      rejectReason:"",
      openCloseDialog:false,
      description: '',
      price: '',
      dateOfProposal: '',
      customerName: '',
      customerEmail: '',
      creator: '',
      listOfProposals: [],
      deleteID: '',
      proposalIndex: -1,
      deleteConfirmationPopup: false,
      openCalendarPicker: false,
      shareActivated: false,
      numberOfCustomers: 1,
      customersArray: ["0"],
      showValidateMessage: false,
      mode: 'add',
      shareProposalID: "",
      selectedDate: new Date(),
      proposalDate: null,
      viewproposal: null,
      uploadFileUrl: "",
      roleName:"",
      pdfDoc: null,
      totalPages: 0,
  pageNumber: 1,
  zoom: 1,
  currentPage: 1,
  fileName: "",
  ProjectDetails : {} as ProjectDetails,
  version_history: [
    {
      id: null,
      project_id: null,
      message: "",
      action_name: "",
      account_id: null,
      created_at: "",
      updated_at: ""
    }
  ],
  sortOption: "Earliest Deadline First",
  searchInputText: "",
  sortedAndFilteredProjects:[],
  seachAndFilteredProjects:[]







      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.canvasRef = React.createRef();
    this.canvasContainerRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
      return false
    }

    if (response.errors) {
      this.parseApiCatchErrorResponse(response)
      if (requestCallId === this.createRoomsApiCallId) {
        this.setState({openDeleteDialog:false})
      }
      return false
    }
    if (requestCallId === this.createRoomsApiCallId) {
      if(response){
        setStorageData("project_status","Pending")
        this.setState({openDeleteDialog:false})
        this.getPDFdata()
        toast.success('Project Proposal has been sent to client',{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          draggable: false,
          pauseOnHover: false,
          closeOnClick: false,
          theme: "light",
        })
        this.navigateToProjectportfolio()
      }
    } 
    if (requestCallId === this.getpdf_generationCallId)  {
      const pdfurl =response.data.attributes?.quote_pdf?.url
      this.loadPDF(pdfurl)
      this.getProjectByIdResponse(response);
     
     }
     if (requestCallId === this.getClientData)  {    
     this.setState({sortedAndFilteredProjects:response.data})
     this.setState({seachAndFilteredProjects:response.data})
     }
     if (requestCallId === this.selectPropertyTypesApi1)  {    
      this.setState({openApproveDialog:false})
      this.getPDFdata()
      }
      if (requestCallId === this.onGoingApi)  {    
        if(!response.error && response){
          this.props.navigation.navigate("ProjectPortfolio");
        }
        }
      
      if (requestCallId === this.rejectTypesApi)  {    
        this.setState({openRejectDialog:false,rejectReason:""})
        this.getPDFdata()
        }

    if (requestCallId === this.getProposalsAPICallID) {
      this.setState({ listOfProposals: response.data ? response.data : [] })
    } else if (requestCallId === this.createProposalAPICallID) {
      this.showAlert("", "Proposal Saved Successfully");
      this.handleSuccessResponse()
    } else if (requestCallId === this.deleteProposalAPICallID) {
      this.handleSuccessResponse()
    } else if (requestCallId === this.shareProposalAPICallID) {


      this.setState({ viewproposal: null });

      this.closePopUp()
    }
     
    // Customizable Area End
  }

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  setTitle = (text: string) => {
    this.setState({
      title: text
    })
  }

  setDescription = (text: string) => {
    this.setState({
      description: text
    })
  }

  setPrice = (text: string) => {
    this.setState({
      price: text
    })
  }

  setDateOfProposal = (dayObject: DateObject) => {
    this.setState({
      dateOfProposal: dayObject.day + "/" + dayObject.month + "/" + dayObject.year,
      openCalendarPicker: false
    })
  }
  setProposalDate = (dayObject: MaterialUiPickersDate) => {
    this.setState({
      proposalDate: dayObject
    })
  }
  setCustomerName = (text: string) => {
    this.setState({
      customerName: text
    })
  }

  handleOpenDeleteDialog = ()=>{
    this.setState({openDeleteDialog:true})
  }
  handleApproveOpenDialog = ()=>{
    this.setState({openApproveDialog:true})
  }
  handleApproveCloseDialog = ()=>{
    this.setState({openApproveDialog:false})
  }
  handleRejectOpenDialog = ()=>{
    this.setState({openRejectDialog:true})
  }
  handleRejectCloseDialog = ()=>{
    this.setState({openRejectDialog:false})
  }
  handleNewStatusDialog = ()=>{
    this.setState({openDeleteDialog:false})
  }
  handleNewApproveDialog = ()=>{
    this.setState({openApproveDialog:false})
  }
  handleOnGoing = ()=>{
    this.setState({openOngoingDialog:true})
  }
  handleOnGoingClose = ()=>{
    this.setState({openOngoingDialog:false})
  }
  
  selectPropertyTypesApiCall = async()=>{
     const token = await getStorageData("token")  
     const  quote_id  = await getStorageData("quote_id")  
     
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: token,
        };
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.selectPropertyTypesApi1 = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_proposal_generation/quote_managements/${quote_id}?status=accept`
      );
        
      const body={
      }
      
       requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

     onGoingApiCall = async()=>{
      const token = await getStorageData("token")  
      const project_idd = await getStorageData("project_id")
      
         const header = {
           "Content-Type": configJSON.validationApiContentType,
           token: token,
         };
       
       const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
       );
       
       this.onGoingApi = requestMessage.messageId;
       
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
       );
     
       requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         `bx_block_profile/projects/${project_idd}/proj_update_ongoing`
        
       );
          
       const body={
           "reason": this.state.rejectReason
       }
       
        requestMessage.addData(
         getName(MessageEnum.RestAPIRequestBodyMessage),
         JSON.stringify(body)
       );
     
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         "PUT"
       );
       
       runEngine.sendMessage(requestMessage.id, requestMessage);
     }

     rejectApiCall = async()=>{
      const token = await getStorageData("token")  
      const  quote_id  = await getStorageData("quote_id")  
      
         const header = {
           "Content-Type": configJSON.validationApiContentType,
           token: token,
         };
       
       const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
       );
       
       this.rejectTypesApi = requestMessage.messageId;
       
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
       );
     
       requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         `bx_block_proposal_generation/quote_managements/${quote_id}?status=reject`
       );
          
       const body={
           "reason": this.state.rejectReason
       }
       
        requestMessage.addData(
         getName(MessageEnum.RestAPIRequestBodyMessage),
         JSON.stringify(body)
       );
     
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         "PUT"
       );
       
       runEngine.sendMessage(requestMessage.id, requestMessage);
     }
  createRoomsApiCall = async ()=>{
    const tokenReq = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token:tokenReq,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.createRoomsApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/quote_managements`
    );

    const project_idd = await getStorageData("project_id")

    const body={
      "project_id":  project_idd,
      }
      
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCloseDeleteDialog = ()=>{
    this.setState({openDeleteDialog:false})
  }
  getShowBoxStatus = () => {
    const { version_history } = this.state;  
    const lastProposalSentIndex = version_history
      .slice()
      .reverse()
      .findIndex(item => item.message === 'Proposal rejected by client');  
    const actualIndex = lastProposalSentIndex !== -1
      ? version_history.length - 1 - lastProposalSentIndex
      : -1;
  
    return actualIndex !== -1 &&
      version_history[actualIndex + 1]?.message === 'New quote generated';
  };

  
  getShowBoxStatus2 = () => {
    const { version_history } = this.state;
    if (!version_history || version_history.length === 0) return false;
    
    const lastRejectedIndex = version_history
      .slice()
      .reverse()
      .findIndex(item => item.message === 'Proposal rejected by client');
  
    const isLastRejected =
      lastRejectedIndex !== -1 &&
      version_history.length - 1 - lastRejectedIndex === version_history.length - 1;
  
    return isLastRejected;
  };
  handleOpenBackDialog = ()=>{
    if( this.state.ProjectDetails.Status === "Draft"){
      this.setState({openCloseDialog:true})
    }else if(this.state.roleName === "client"){
      this.props.navigation.navigate("ProposalGeneration")
    }
    else{
      this.backNavigation()
    }
  }

  handleCloseBackDialog = ()=>{
    this.setState({openCloseDialog:false})
  }

   formattedDateRange = (date: string | number | Date | dayjs.Dayjs | null | undefined) => 
    dayjs(date).format("MMM D, YYYY • h:mmA");

  setCustomerMails = (text: string, index: number) => {
    let mailArray = this.state.customerEmail.split(',');
    mailArray[index] = text
    this.setState({ customerEmail: mailArray.toString() })
  }

  showAlertMessage = (type: string) => {

    if (this.state.showValidateMessage) {
      if (type === 'title' && this.isStringNullOrBlank(this.state.title)) {
        return "Please enter title of the proposal";
      } else if (type === 'price' && this.isStringNullOrBlank(this.state.price)) {
        return "Please enter price of the proposal";
      }
      else if (type === 'price' && !(/^[0-9]+$/.test(this.state.price))) {
        return "Price should be number";
      }
      else if (type === 'date' && this.isStringNullOrBlank(this.state.dateOfProposal) || type === 'date' && !!this.state.proposalDate) {
        return "Please enter date of the proposal";
      } else if (type === 'desc' && this.isStringNullOrBlank(this.state.description)) {
        return "Please enter description of the proposal";
      } else if (type === 'creator' && this.isStringNullOrBlank(this.state.creator)) {
        return "Please enter creator of the proposal";
      } else {
        return ""
      }

    } else {
      return ""
    }
  }



  plus = () => {
    let array = [...this.state.customersArray]
    array.push(this.state.numberOfCustomers + "");
    this.setState({
      customersArray: array,
      numberOfCustomers: this.state.numberOfCustomers + 1,
      customerEmail: this.state.customerEmail + ","
    })
  }

  isStringNullOrBlank(text: string) {
    return text === null || text.trim().length === 0;
  }

  handleDataSaveClick = async () => {
    this.setState({ showValidateMessage: false });
    let isDate = this.state.proposalDate === null ? this.isStringNullOrBlank(this.state.dateOfProposal) : !this.state.proposalDate
    if (this.isStringNullOrBlank(this.state.title) || isDate ||
      this.isStringNullOrBlank(this.state.price) ||
      this.isStringNullOrBlank(this.state.description) ||
      this.isStringNullOrBlank(this.state.creator)) {
      this.setState({ showValidateMessage: true })
      return
    }
    let date = !!this.state.dateOfProposal ? this.state.dateOfProposal : moment(this.state.proposalDate).format("DD/MM/YYYY")

    let body = {
      title: this.state.title,
      description: this.state.description,
      price: this.state.price,
      date_of_proposal: date,
      name_of_proposal_creator: this.state.creator,
      customer_name: this.state.customerName
    };

    this.createProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: this.state.mode === 'add' ? configJSON.postMethod : configJSON.updateMethod,
      endPoint: this.state.mode === 'add' ? configJSON.proposalEndpoint : configJSON.proposalEndpoint + '/' + this.state.mode,
      body: JSON.stringify(body)
    });
  }
  handleSaveClick = async () => {
    this.setState({ showValidateMessage: false })
    if (this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.dateOfProposal) ||
      this.isStringNullOrBlank(this.state.price) ||
      this.isStringNullOrBlank(this.state.description) ||
      this.isStringNullOrBlank(this.state.creator)) {
      this.setState({ showValidateMessage: true })
      return
    }

    const formData = new FormData();
    formData.append("title", this.state.title)
    formData.append("price", this.state.price)
    formData.append("name_of_proposal_creator", this.state.creator)
    formData.append("date_of_proposal", this.state.dateOfProposal)
    formData.append("description", this.state.description)
    this.state.customerName != '' && formData.append("customer_name", this.state.customerName)
    formData.append("customer_emails", '[]')
    this.createProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: this.state.mode === 'add' ? configJSON.postMethod : configJSON.updateMethod,
      endPoint: this.state.mode === 'add' ? configJSON.proposalEndpoint : configJSON.proposalEndpoint + '/' + this.state.mode,
      body: JSON.stringify(formData)
    });

  }

  getMailValue = (index: number) => {
    return this.state.customerEmail.split(',')[index]
  }

  setCreator = (text: string) => {
    this.setState({
      creator: text
    })
  }

  toggleCreateForm = () => {
    this.setState({
      mode: 'add',
      openCreateForm: true
    })

  }

  shareProposal = (proposal: Proposal) => {
    this.setState({
      title: proposal.attributes.title,
      price: proposal.attributes.price + "",
      description: proposal.attributes.description,
      creator: proposal.attributes.name_of_proposal_creator,
      dateOfProposal: proposal.attributes.date_of_proposal,
      proposalDate: new Date(moment(proposal.attributes.date_of_proposal, "DD/MM/YYYY").toDate()),
      customerName: proposal.attributes.customer_name,
      shareProposalID: proposal.id,
      shareActivated: true
    })
  }

  shareProposalToMail = async () => {
    if (this.isStringNullOrBlank(this.state.customerEmail.split(',').join(''))) {
      this.showAlert("Alert", "Please enter customer mail to share")
      return false
    }

    let mailArray = this.state.customerEmail.split(',').filter(item => item !== "");;
    let shareArray: string = '[';
    let validate = true;
    mailArray.forEach(element => {
      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(element) === false) {
        validate = false
      } else {
        shareArray = shareArray + '"' + element + '",'
      }
    });

    if (!validate) {
      this.showAlert("Alert", "Please enter valid mail ID to share")
      return false
    }

    const formData = new FormData();
    formData.append("proposal_id", this.state.viewproposal ? this.state.viewproposal?.id : "")
    formData.append('customer_emails', shareArray + ']');


    this.shareProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: configJSON.postMethod,
      endPoint: configJSON.shareProposalEndpoint,
      body: JSON.stringify(formData)
    });
  }

  editProposals = (proposal: Proposal) => {
    this.setState({
      title: proposal.attributes.title,
      price: proposal.attributes.price + "",
      description: proposal.attributes.description,
      creator: proposal.attributes.name_of_proposal_creator,
      dateOfProposal: proposal.attributes.date_of_proposal,
      proposalDate: new Date(moment(proposal.attributes.date_of_proposal.toString(), "DD/MM/YYYY").toDate()),
      customerName: proposal.attributes.customer_name || '',
      mode: proposal.id,
      openCreateForm: true
    })
  }
  shareModalFun = (selected: null | Proposal) => {
    this.setState({
      viewproposal: selected
    })
  }
  handleConfirmProposalEmail = () => {

  }
  shareProposalToMailWeb = async () => {

    if (this.isStringNullOrBlank(this.state.customerEmail.split(',').join(''))) {
      this.showAlert("Alert", "Please enter customer mail to share")
      return false
    }

    let mailArray = this.state.customerEmail.split(',').filter(item => item !== "");;
    let shareArray: string = '[';
    let validate = true;
    mailArray.forEach(element => {
      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(element) === false) {
        validate = false
      } else {
        shareArray = shareArray + '"' + element + '",'
      }
    });

    if (!validate) {
      this.setState({
        viewproposal: null
      })
      this.showAlert("Alert", "Please enter valid mail ID to share");

      return false
    }

    this.shareProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: configJSON.postMethod,
      endPoint: configJSON.shareProposalEndpoint,
      body: JSON.stringify({
        proposal_id: this.state.viewproposal ? this.state.viewproposal?.id : "",
        customer_emails: shareArray + ']'
      })
    });
  }
  openDeletePopup = (proposalID: string) => {
    this.setState({
      deleteID: proposalID + '',
      deleteConfirmationPopup: true
    })
  }

  handleTitle = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ title: event.target.value })

  }
  handlePrice = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ price: event.target.value })

  }
  handleEmail = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ customerEmail: event.target.value })
  }
  handleCreator = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ creator: event.target.value })

  }
  handleDescription = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ description: event.target.value })
  }
  handleCustomerName = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ customerName: event.target.value })

  }
  deleteHandler = async () => {
    this.deleteProposalAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteMethod,
      endPoint: configJSON.proposalEndpoint + "/" + this.state.deleteID,
      body: null
    });
  }


  closePopUp = () => {
    this.setState({
      deleteConfirmationPopup: false,
      openCreateForm: false,
      title: "",
      price: "",
      description: "",
      creator: "",
      dateOfProposal: "",
      proposalDate: null,
      proposalIndex: -1,
      shareActivated: false,
      openCalendarPicker: false,
      numberOfCustomers: 1,
      customersArray: ["0"],
      customerEmail: '',
      showValidateMessage: false,
      shareProposalID: ""
    })
  }

  toggleCalendar = () => {
    this.hideKeyboard();
    this.setState({
      openCalendarPicker: !this.state.openCalendarPicker
    })
  }



  getProposals = async () => {
    this.getProposalsAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType + '',
      method: configJSON.validationApiMethodType + '',
      endPoint: configJSON.proposalEndpoint + '',
      body: null
    });
  }

  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null
  }) => {
    const { contentType, method, endPoint, body } = data;
    const proposalRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
      })
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(proposalRequestMessage.id, proposalRequestMessage);
    return proposalRequestMessage.messageId;
  };

  handleSuccessResponse = () => {
    this.closePopUp();
    this.getProposals()
    return true
  }

  async componentDidMount() {
  this.getPDFdata()
  
  const roleName = await getStorageData("role_name")
  this.setState({ roleName });
    this.getProposals()
    this.getClientData1()
    await this.getFileFromStorage();

    // await this.loadPDF()
    window.addEventListener('keydown', this.handleKeyDown);
    const canvas = this.canvasRef.current;

  }
  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      this.handleNextPage();
    } else if (event.key === 'ArrowLeft') {
      this.handlePreviousPage();
    }
  };

  extractIdsFromUrl = (url:string)=>{
    const paths = url.split("/")
    const planID = paths[2]
    const projectID = paths[4]
    return {planID,projectID}
  }
  backBtnQuoteOverviewClicked = async ()=>{
    await setStorageData("backBtnQuoteOverviewClick",JSON.stringify(true))
  }
  backNavigation = async()=>{
    const project_id = await getStorageData("project_id")
    const planID = await getStorageData("projectPlanSelected")
    const pathname = window.location.pathname
    this.backBtnQuoteOverviewClicked()
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "QuoteOverview"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${planID}/project_id/${project_id}`);
    this.send(message);
  }
  async getFileFromStorage() {
    try {
      const fileObjString = await getStorageData("fileObj");

      if (!fileObjString) return;

      const fileObj = JSON.parse(fileObjString);
      this.setState(
        {
          uploadFileUrl: fileObj.fileUrl,
          fileName: fileObj.fileMetaData.name,
        },
      );
    } catch (error) {
      console.error("Error retrieving or parsing data from localStorage:", error);
    }
  }
  loadPDF = async (pdfurl:any) => {
    
    const url = pdfurl;
    try {
      const pdf = await pdfjsLib.getDocument(url).promise;
      this.setState({pdfDoc:pdf})
      this.setState({ totalPages: pdf.numPages }, () => {
        this.renderAllPages();
      });
    } catch (error) {
   
    }
  }
  renderAllPages = () => {
    const { pdfDoc, totalPages } = this.state;

    for (let num = 1; num <= totalPages; num++) {
      this.renderPage(num);
    }
  };
  renderPage = (num: number) => {
    
    if (this.pageRendering) return;
    this.pageRendering = true;
    const { zoom } = this.state;

    this.state.pdfDoc.getPage(num).then((page: any) => {

      const canvas = this.canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext('2d');
      const viewport = page.getViewport({ scale: zoom });

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      page.render({
        canvasContext: context!,
        viewport: viewport,
      }).promise.then(() => {
        this.pageRendering = false;
        this.setState({ currentPage: num });
      });
    });
  };
  handlePrevPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      this.setState({ pageNumber: prevPage }, () => {
        this.renderPage(prevPage);
      });
    }
  };
  NextPage = () => {
    const { currentPage, totalPages } = this.state;
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      this.setState({ pageNumber: nextPage }, () => {
        this.renderPage(nextPage);
      });
    }
  };
  handleNextPage = () => {
    if (this.state.pageNumber < this.state.totalPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
      this.renderPage(this.state.pageNumber);
    }
  };

  handlePreviousPage = () => {
    if (this.state.pageNumber > 1) {
      this.setState({ pageNumber: this.state.pageNumber - 1 })
      this.renderPage(this.state.pageNumber);
    }
  };

  handleReviewNavigation = async (project:any) => {
    await setStorageData("quote_id",project.id)
    await setStorageData("project_id",project.attributes.project_data.id)
    this.props.navigation.navigate("ProjectProposalgeneration")
  };
  handleReasonChange = (event: any) => {
    this.setState({ rejectReason: event.target.value });
  };
  handleZoomIn = () => {
    this.setState(
      (prevState) => ({ zoom: prevState.zoom + 0.1 }),
      () => this.renderPage(this.state.pageNumber)
    );
  };

  handleZoomOut = () => {
    this.setState(
      (prevState) => ({ zoom: prevState.zoom - 0.1 }),
      () => this.renderPage(this.state.pageNumber)
    );
  };
  handleDownload = async ( ) => {
  const fileUrl=  this.state.ProjectDetails.PdfUrl
  const fileName = this.state.ProjectDetails.fileName
    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }
  getPDFdata = async () => {
    const project_idd = await getStorageData("project_id")
    const token = await getStorageData("token")
    const status = await getStorageData("project_status")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getpdf_generationCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (status === "Draft" || status === "Rejected")
        ? `bx_block_tasks/project_rooms_task_lists/pdf_generation?project_id=${project_idd}`
        : `/bx_block_proposal_generation/proposal_generations/${project_idd}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProjectByIdResponse=(responseJson:any)=>{
   
  
    this.setState({
      ProjectDetails:{
      projectName:responseJson.data.attributes.project_name,
      fileName:responseJson.data.attributes.quote_pdf.file_name,
      fileCreated_On:this.formatDate(responseJson.data.attributes.quote_pdf.created_at),
      Total_Cost:responseJson.data.attributes.total_cost,
      Adjusted_Cost:responseJson.data.attributes.adjusted_cost,
      Address:responseJson.data.attributes.project_name,
      AddressCity:responseJson.data.attributes.addressable.city,
      Addressstate:responseJson.data.attributes.addressable.state,
      Addressountry:responseJson.data.attributes.addressable.country,
      Plan_Type:responseJson.data.attributes.payment_plan_data.payment_plan_name,
   Retention_Percentage:responseJson.data.attributes.payment_plan_data.retention_percentage ,
      PdfUrl:responseJson.data.attributes.quote_pdf.url,
     Status:responseJson.data.attributes.status
      
     },
     version_history:responseJson.data.attributes.version_histories
    })
   }
   formatDate(dateString: string): string {
    const inputDate: Date = new Date(dateString);
    const today: Date = new Date();

    // Format date as "MMM DD, YYYY"
    const formattedDate: string = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
    }).format(inputDate);

    // Convert dates to timestamps and calculate difference in days
    const diffTime: number = today.getTime() - inputDate.getTime();
    const diffDays: number = Math.round(diffTime / (1000 * 60 * 60 * 24));

    let relativeTime: string;
    if (diffDays === 0) {
        relativeTime = "Today";
    } else if (diffDays === 1) {
        relativeTime = "1 day ago";
    } else if (diffDays > 1) {
        relativeTime = `${diffDays} days ago`;
    }  else {
        relativeTime = `${Math.abs(diffDays)} days from now`;
    }

    return `${formattedDate} (${relativeTime})`;
}


handleChange = (event: { target: { value: any} }) => {
  this.setState({ sortOption: event.target.value });
};

handleSearchInputChange = (search: string) => {
  const sortedAndFilteredProjects = this.state.seachAndFilteredProjects.filter((shell:any) =>
  shell.attributes.project_data.name.toLowerCase().includes(search.toLowerCase())
  );
  this.setState({ sortedAndFilteredProjects, searchInputText: search ,  });
};
formatDateRange = (date: string | null | undefined) => 
  date ? dayjs(date).format('D MMM YYYY') : '';

getClientData1 = async () => {
  const token = await getStorageData("token")
  const header = {
    "Content-Type": "application/json",
    token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getClientData = requestMessage.messageId;
 
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_proposal_generation/quote_managements`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
navigateToProjectportfolio = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ProjectPortfolio'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { TabNumber: 1,})
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
}

  // Customizable Area End
}
