import React from "react";

import {
  Container,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start

  styled,
  Box,
  ThemeProvider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Dialog,
  StepLabel,
  StepConnector,
  Stepper,
  Step,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { AddCircleOutline, Delete, Edit ,ExpandMore } from '@material-ui/icons';
import { backButton,approve_icon,approveImg,rejectImg } from "./assets";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DateFnsUtils from "@date-io/date-fns";
import { createTheme } from "@mui/material/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ToastContainer } from "react-toastify";
import { success } from "../../teambuilder/src/assets";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from '@mui/icons-material/InfoOutlined';



// Customizable Area End

import Proposalgeneration2Controller, {
  Props,
  configJSON,
} from "./Proposalgeneration2Controller";

const theme = createTheme({
  palette: {
      primary: {
          main: "#9b59b6",
          contrastText: "#fff",
      },
      secondary: {
          main: "#f3f4f6",
      },
  },
});
export default class ProjectProposalgeneration extends Proposalgeneration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { currentPage, totalPages, zoom } = this.state;

    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainWrapper>
        <MyProfileHeader navigation={this.props.navigation} tabName="project"></MyProfileHeader>
        <CustomToast icon={<img src={success} />}  closeButton={false} />
      
<Box className="mainWrapper">
  
<Box style={{width:"80%"}}>

<SubHeader >

                {
                  this.state.roleName === "client" && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: "10px" }}>
                      <Box>
                        <img src={backButton} alt="back" />
                      </Box>
                      <Box style={{ cursor: "pointer" }} >
                        <Typography className="backButtonTypo" onClick={this.handleOpenBackDialog}>Back</Typography>
                      </Box>
                    </Box>
                  )
                }

                {
                  this.state.roleName !== "client" && (this.state.ProjectDetails.Status === 'Draft' || this.state.ProjectDetails.Status === 'Rejected') && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: "10px" }}>
                      <Box>
                        <img src={backButton} alt="back" />
                      </Box>
                      <Box style={{ cursor: "pointer" }} >
                        <Typography className="backButtonTypo" onClick={this.handleOpenBackDialog}>Back</Typography>
                      </Box>
                    </Box>

                  )
                }
    <Box className="tabButton_Wrapper">
          <TabButton>Proposal Quote</TabButton>

        </Box>
</SubHeader>

<Box className="pdfSection">
   <div className="pdf-viewer">
   <div style={{position:"relative"}}>
      <div   className="canvas-container">
      <canvas ref={this.canvasRef}></canvas>

   </div>
   </div>
   </div>
   <Box style={{
    position: "fixed",
    bottom: "12px",
    left: "40%",
    transform: "translateX(-60%)",
  }}>
   <div className="pdf-controls">
      <Typography className="toolbar">
      <ActionButton data-test-id="handleZoomIn" className="buttons" onClick={this.handlePrevPage.bind(this)}>prev</ActionButton>
         <span className="activePage " style={{margin:"10px ",border:"1px solid #CBD5E1",borderRadius:"8px", background:"#FFFFFF", padding:"6px",paddingRight:"8px"}}> {currentPage}</span> /  <span style={{margin:"10px "}}> {totalPages}</span>  
         <ActionButton data-test-id="handleZoomIn" className="buttons" onClick={this.NextPage.bind(this)}>next</ActionButton>
      </Typography>
      <Box style={{ width: '1px', background: '#E2E8F0', height: '35px', borderRadius: '1px', margin: '16px' }} />
      <ActionButton data-test-id="handleZoomOut" className="buttons" style={{ margin: '5px' }} onClick={this.handleZoomOut}>-</ActionButton>
      <p className="activePage zoom_percenage" style={{margin:"10px "}}>{(zoom * 100).toFixed(0)}%</p>
      <ActionButton data-test-id="handleZoomIn" className="buttons" onClick={this.handleZoomIn}>+</ActionButton>
      <Box style={{ width: '1px', background: '#E2E8F0', height: '35px', borderRadius: '1px', margin: '16px' }} />

      <ActionButton data-test-id="download" className="buttons" onClick={this.handleDownload}>
      <SaveAltIcon style={{width:'18px', height:"18px",color:"#475569"}} />
      </ActionButton>
   </div>
</Box>
</Box>
</Box>

<Box className="DetailSection">
        <Box className="acc_parent_wrapeer">
      <Accordionwrapper defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="span" className="acc_heading">Project Overview</Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Box >
          <Typography className="acc_det_heder">Project Name: </Typography>
          <Typography className="acc_det_para">
            {this.state.ProjectDetails.projectName}
          </Typography>
          </Box>
          <Box >
          <Typography className="acc_det_heder">File Name:</Typography>
          <Typography className="acc_det_para">{this.state.ProjectDetails.fileName}</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Created On:</Typography>
          <Typography className="acc_det_para">{this.state.ProjectDetails.fileCreated_On}</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Total Cost:</Typography>
          <Typography className="acc_det_para">£{this.state.ProjectDetails.Total_Cost}</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Adjusted Cost:</Typography>
          <Typography className="acc_det_para">{this.state.ProjectDetails.Adjusted_Cost}</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Address:</Typography>
          <Typography className="acc_det_para">
          {`${this.state.ProjectDetails.AddressCity} ${this.state.ProjectDetails.Addressstate} ${this.state.ProjectDetails.Addressountry}`}

             </Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Plan Type:</Typography>
          <Typography className="acc_det_para">{this.state.ProjectDetails.Plan_Type}</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Retention Percentage:</Typography>
          <Typography className="acc_det_para">{this.state.ProjectDetails.Retention_Percentage}%</Typography>
          </Box>
        </AccordionDetails>
      </Accordionwrapper>
      <Accordionwrapper1>
      <AccordionSummaryAlign expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
        <TypographyHeader className="acc_heading">Version History</TypographyHeader>
      </AccordionSummaryAlign>
      <AccordionDetails>
                    <Stepper
                      activeStep={1}
                      orientation="vertical"
                      connector={<CustomStepConnector />}
                      style={{ overflow: 'visible' }}
                    >
                      <TypographyAlign className="acc_det_heder">This Month</TypographyAlign>
                      {this.state.version_history.slice().reverse().map((item, index) => (
                        <Step
                          key={index}
                          style={{
                            position: 'relative',
                            overflow: 'visible',
                          }}
                        >
                          {this.state.ProjectDetails.Status === 'Accepted' && index === 0 && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '-12px',
                                left: '-55px',
                                right: '-65px',
                                bottom: '-12px',
                                backgroundColor: '#E6F4EA',
                                borderRadius: '12px',
                                zIndex: 0,
                                pointerEvents: 'none',
                              }}
                            />
                          )}

                          {this.state.ProjectDetails.Status === 'Rejected' && index === 0 && item.message === 'Proposal rejected by client' && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '-12px',
                                left: '-55px',
                                right: '-65px',
                                bottom: '-12px',
                                backgroundColor: '#FCE8E6',
                                borderRadius: '12px',
                                zIndex: 0,
                                pointerEvents: 'none',
                              }}
                            />
                          )}

                          <StyledStepLabels style={{ position: 'relative', zIndex: 1 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                              <TypographyHead
                                className="acc_det_para"
                                style={{
                                  color: this.state.ProjectDetails.Status === 'Rejected' && item.message === 'Proposal rejected by client'
                                    ? '#D93025'
                                    : this.state.ProjectDetails.Status === 'Accepted' && item.message === 'Proposal approved by client'
                                      ? '#059669'
                                      : '#0F172A',
                                  fontWeight:
                                    (this.state.ProjectDetails.Status === 'Rejected' && item.message === 'Proposal rejected by client') ||
                                      (this.state.ProjectDetails.Status === 'Accepted' && item.message === 'Proposal approved by client')
                                      ? 600
                                      : 400
                                }}
                              >
                                {item.message}
                              </TypographyHead>

                              {this.state.ProjectDetails.Status === 'Accepted' && index === 0 && (
                                <img
                                  src={approveImg}
                                  alt="Approved"
                                  style={{
                                    color: '#1E7E34',
                                    marginLeft: '10px',
                                    fontSize: '20px',
                                  }}
                                />

                              )}

                              {this.state.ProjectDetails.Status === 'Rejected' && item.message === 'Proposal rejected by client' && (
                                <>
                                  <img
                                    src={rejectImg}
                                    alt="Rejected"
                                    style={{
                                      color: '#D93025',
                                      marginLeft: '10px',
                                      fontSize: '20px',
                                    }}
                                  />
                                </>
                              )}
                            </div>
                            {this.state.ProjectDetails.Status === 'Rejected' && index === 0  && item.message === 'Proposal rejected by client' && (
                              <div
                                style={{
                                  position: 'relative',
                                  backgroundColor: '#FCE8E6',
                                  color: '#0F172A',
                                  borderRadius: '8px',
                                  zIndex: 1,
                                  fontSize:"12px",
                                  fontWeight:400,
                                  fontFamily:"Poppins"
                                }}
                              >
                               <Typography  style={{
                                  color: '#0F172A',
                                  fontSize:"12px",
                                  fontWeight:400,
                                  fontFamily:"Poppins"
                                }}>Reason: “{item.action_name}”</Typography>
                              </div>
                            )}
                              {this.state.ProjectDetails.Status === 'Rejected'  && index !== 0  && item.message === 'Proposal rejected by client' && (
                              <div
                                style={{
                                  position: 'relative',
                                  color: '#0F172A',
                                  borderRadius: '8px',
                                  zIndex: 1,
                                  fontSize:"12px",
                                  fontWeight:400,
                                  fontFamily:"Poppins"
                                }}
                              >
                                
                                <Typography  style={{
                                  color: '#0F172A',
                                  fontSize:"12px",
                                  fontWeight:400,
                                  fontFamily:"Poppins"
                                }}>Reason: “{item.action_name}”</Typography>
                              </div>
                            )}
                            <TypographyContent className="acc_det_heder">
                              {this.formattedDateRange(item.created_at)}
                            </TypographyContent>
                          </StyledStepLabels>
                        </Step>
                      ))}
                    </Stepper>
      </AccordionDetails>
      </Accordionwrapper1>
   
    </Box>
              {this.state.roleName === "client" && this.state.ProjectDetails.Status === "Pending" && (
                <>
                  <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", padding: "17px 0px", borderTop: "1px solid #E2E8F0" }}>
                    <CustomGenerateQuoteButton onClick={this.handleApproveOpenDialog}>
                      Approve
                    </CustomGenerateQuoteButton>
                  </Box>
                  <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomRejectButton onClick={this.handleRejectOpenDialog}>
                      Reject
                    </CustomRejectButton>
                  </Box>
                </>
              )}
               {
                this.state.roleName === "client" && this.state.ProjectDetails.Status === "Rejected" && (
                  <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", padding: "17px 0px", borderTop: "1px solid #E2E8F0" }}>
                    <CustomGenerateQuoteButton1>
                      <CloseIcon style={{ fontSize: "20px", marginRight: "8px" }} />
                      Proposal Rejected
                    </CustomGenerateQuoteButton1>
                  </Box>

                )
              }
                 
                 {
                this.state.roleName === "client" && this.state.ProjectDetails.Status === "Accepted" && (
                  <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", padding: "17px 0px", borderTop: "1px solid #E2E8F0" }}>
                    <CustomGenerateQuoteButton1>
                      <img
                        src={approve_icon}
                        alt="Approved"
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '8px'
                        }}
                      />
                      Proposal Approved
                    </CustomGenerateQuoteButton1>
                  </Box>

                )
              }
              {
                this.state.roleName !== "client" && this.state.ProjectDetails.Status === "Accepted" && (
                  <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", padding: "17px 0px", borderTop: "1px solid #E2E8F0" }}>
                    <CustomGenerateQuoteButton onClick={this.handleOnGoing}>
                    Mark As Ongoing
                    </CustomGenerateQuoteButton>
                  </Box>

                )
              }
              {
                this.state.roleName !== "client" && this.state.ProjectDetails.Status === "Rejected" && this.getShowBoxStatus2() &&(
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F8FAFC",
                        padding: "12px",
                        borderTop: "1px solid #E2E8F0",
                      }}
                    >
                      <InfoIcon style={{ color: "#64748B", padding: "12px" ,marginTop:"-15px"}} /> 
                      <Typography style={{ color: "#64748B", fontSize: "12px", fontWeight: 400 }}>
                        You can send for review after doing edit to this quote.
                      </Typography>
                    </Box>
                    <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", padding: "17px 0px" }}>
                      <CustomEditQuoteButton onClick={this.backNavigation}>
                        Edit Quote
                      </CustomEditQuoteButton>
                    </Box>
                    <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <DisableSendButton>
                        Send for Review
                      </DisableSendButton>
                    </Box>
                  </>
                )
              }

              {
                this.state.roleName !== "client" && this.state.ProjectDetails.Status === "Rejected" && this.getShowBoxStatus() && (
                  <>
                    <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", padding: "17px 0px",borderTop: "1px solid #E2E8F0"  }}>
                      <DisableSendButton >
                        Edit Quote
                      </DisableSendButton>
                    </Box>
                    <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <CustomGenerateQuoteButton onClick={this.handleOpenDeleteDialog}>
                        Send for Review
                      </CustomGenerateQuoteButton>
                    </Box>

                  </>
                )
              }
              {
                this.state.roleName === "client_user" && this.state.ProjectDetails.Status === "Rejected_status" && (
                  <>
                    <Box style={{ backgroundColor: "#fff", display: "none", justifyContent: "center", alignItems: "center", padding: "17px 0px", borderTop: "1px solid #E2E8F0" }}>
                      <DisableNewButton  onClick={this.handleNewApproveDialog}>
                        Edit Quote1
                      </DisableNewButton>
                    </Box>
                    <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <CustomNewButton onClick={this.handleNewStatusDialog}>
                        Send for Review1
                      </CustomNewButton>
                    </Box>

                  </>
                )
              }
                 
    {
                this.state.ProjectDetails.Status === "Draft" && (
                  <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", padding: "17px 0px", borderTop: "1px solid #E2E8F0" }}>
                    <CustomGenerateQuoteButton onClick={this.handleOpenDeleteDialog}>
                      Send for Review
                    </CustomGenerateQuoteButton>
                  </Box>

                )
              }
              {
                this.state.roleName !== "client" && this.state.ProjectDetails.Status === "Pending" && (
                  <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", padding: "17px 0px", borderTop: "1px solid #E2E8F0" }}>
                    <CustomGenerateQuoteButton1>
                      <AccessTimeIcon style={{ fontSize: "20px", marginRight: "8px" }} />
                      Waiting for Approval
                    </CustomGenerateQuoteButton1>
                  </Box>

                )
              }
</Box>
</Box>
<DeleteDialog open={this.state.openDeleteDialog} 
          PaperProps={{
            style: {
              borderRadius: '16px',
              width: '584px',
            },
          }}
          >
            <Grid container className="dialog-grid-container">
              <Grid item xs={11}>
                <Typography style={webStyle.deleteText}>
                Sending quote for review
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon style={{ width: '20px', height: '20px', color: '#0F172A', marginTop: '7px', cursor: 'pointer',paddingLeft:"18px" }} onClick={this.handleCloseDeleteDialog} />
              </Grid>
              <Grid item xs={12}>
                <Typography style={webStyle.actionTxt}>
                You won’t be able to edit this quote once sent.
                </Typography>
              </Grid>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '16px', paddingTop: '32px' }}>
                <button
                  style={webStyle.cancelBtn}
                  onClick={this.handleCloseDeleteDialog}
                >
                  Cancel
                </button>
                <button
                  style={webStyle.deleteBtn}
                  data-test-id="handleDeleteClick"
                  onClick={this.createRoomsApiCall}
                >
                  Send
                </button>
              </Box>
            </Grid>
          </DeleteDialog>
          <DeleteDialog open={this.state.openApproveDialog} 
          PaperProps={{
            style: {
              borderRadius: '16px',
              width: '584px',
            },
          }}
          >
            <Grid container className="dialog-grid-container">
              <Grid item xs={11}>
                <Typography style={webStyle.deleteText}>
                Approving Proposal
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon style={{ width: '20px', height: '20px', color: '#0F172A', marginTop: '7px', cursor: 'pointer',paddingLeft:"18px" }} onClick={this.handleApproveCloseDialog} />
              </Grid>
              <Grid item xs={12}>
                <Typography style={webStyle.actionTxt}>
                This action can’t be undone.
                </Typography>
              </Grid>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '16px', paddingTop: '32px' }}>
                <button
                  style={webStyle.cancelBtn}
                  onClick={this.handleApproveCloseDialog}
                >
                  Cancel
                </button>
                <button
                  style={webStyle.deleteBtn}
                  data-test-id="handleDeleteClick"
                  onClick={this.selectPropertyTypesApiCall}
                >
                  Approve
                </button>
              </Box>
            </Grid>
          </DeleteDialog>
          <DeleteDialog open={this.state.openOngoingDialog} 
          PaperProps={{
            style: {
              borderRadius: '16px',
              width: '584px',
            },
          }}
          >
            <Grid container className="dialog-grid-container">
              <Grid item xs={11}>
                <Typography style={webStyle.deleteText}>
                Mark As Ongoing
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon style={{ width: '20px', height: '20px', color: '#0F172A', marginTop: '7px', cursor: 'pointer',paddingLeft:"18px" }} onClick={this.handleOnGoingClose} />
              </Grid>
              <Grid item xs={12}>
                <Typography style={webStyle.actionTxt}>
                Are you sure you want to mark this quote as Ongoing?
                </Typography>
              </Grid>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '16px', paddingTop: '32px' }}>
                <button
                  style={webStyle.cancelBtn}
                  onClick={this.handleOnGoingClose}
                >
                  Cancel
                </button>
                <button
                  style={webStyle.deleteBtn}
                  data-test-id="handleDeleteClick"
                  onClick={this.onGoingApiCall}
                >
                  Confirm
                </button>
              </Box>
            </Grid>
          </DeleteDialog>
          <DeleteDialog open={this.state.openRejectDialog}
            PaperProps={{
              style: {
                borderRadius: '16px',
                width: '584px',
              },
            }}
          >
            <Grid container className="dialog-grid-container">
              <Grid item xs={11}>
                <Typography style={webStyle.deleteText}>
                  Rejecting Proposal
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon style={{ width: '20px', height: '20px', color: '#0F172A', marginTop: '7px', cursor: 'pointer', paddingLeft: "18px" }} onClick={this.handleRejectCloseDialog} />
              </Grid>
              <Grid item xs={12}>
                <Typography style={webStyle.actionTxt}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter reason to reject"
                    multiline
                    value={this.state.rejectReason}
                    onChange={this.handleReasonChange}
                    InputProps={{
                      style: {
                        paddingTop: "10px",
                      }
                    }}
                    style={{
                      border: '2px solid #CBD5E1',
                      borderRadius: '8px'
                    }}
                  />
                </Typography>
              </Grid>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '16px', paddingTop: '32px' }}>
                <button
                  style={webStyle.cancelBtn}
                  onClick={this.handleRejectCloseDialog}
                >
                  Cancel
                </button>
                <button
                  style={{
                    ...webStyle.rejectBtn,
                    opacity: 1,
                    pointerEvents: this.state.rejectReason.trim() ? 'auto' : 'none',
                    backgroundColor: this.state.rejectReason.trim() ? '#237182' : '#F1F5F9',
                    color: this.state.rejectReason.trim() ? '#FFFFFF' : '#94A3B8',
                  }}
                  data-test-id="handleDeleteClick"
                  onClick={this.rejectApiCall}
                  disabled={!this.state.rejectReason.trim()} 
                >
                  Reject
                </button>
              </Box>
            </Grid>
          </DeleteDialog>
          <DeleteDialog open={this.state.openCloseDialog} 
          PaperProps={{
            style: {
              borderRadius: '16px',
              width: '584px',
            },
          }}
          >
            <Grid container className="dialog-grid-container">
              <Grid item xs={11}>
                <Typography style={webStyle.deleteText}>
                Returning to projects without sending the quote?
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon style={{ 
                  width: '20px', height: '20px', color: '#0F172A', marginTop: '7px', cursor: 'pointer',paddingLeft:"18px" }} onClick={this.handleCloseBackDialog} />
              </Grid>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '16px', paddingTop: '32px' }}>
                <button
                  style={webStyle.cancelBtn}
                  onClick={this.handleCloseBackDialog}
                >
                  Cancel
                </button>
                <button
                  style={webStyle.deleteBtn1}
                  data-test-id="handleBackClick"
                  onClick={this.backNavigation}
                >
                  Back to Projects
                </button>
              </Box>
            </Grid>
          </DeleteDialog>
        </MainWrapper>

        </ThemeProvider>

      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ActionButton = styled(Button)({
border:"none",
background:"transparent",
padding:"0px",
minWidth:"unset"
})
const webStyle = {
  cancelBtn: {
    width: '90px', height: '56px', backgroundColor: '#E0EDF0', color: '#325962',
    fontWeight: 600, fontSize: '16px', fontFamily: 'Poppins', borderRadius: '8px',
    border:"none",cursor:"pointer"
  },
  deleteBtn: {
    width: '90px', height: '56px', backgroundColor: '#237182', color: '#FFFFFF',
    fontWeight: 600, fontSize: '16px', fontFamily: 'Poppins', borderRadius: '8px',cursor:"pointer",
    border:"none"
  },
  rejectBtn: {
    width: '90px', height: '56px', backgroundColor: '#F1F5F9', color: '#64748B',
     fontWeight: 600, fontSize: '16px', fontFamily: 'Poppins', borderRadius: '8px',cursor:"pointer",
     border:"none"
  },
  deleteBtn1: {
    width: '162px', height: '56px', backgroundColor: '#237182', color: '#FFFFFF',
    fontWeight: 600, fontSize: '16px', fontFamily: 'Poppins', borderRadius: '8px',cursor:"pointer",
    border:"none"
  },
  deleteText: { fontSize: '24px', fontWeight: 700, color: '#0F172A', fontFamily: 'Poppins !important' },
  actionTxt: { fontSize: '16px', fontWeight: 400, color: '#1E293B', fontFamily: 'Poppins !important',marginTop:"7px" }
};
const CustomGenerateQuoteButton = styled(Button)(({ theme }) => ({
  width: "320px",
   height: "44px",
  padding: "10px 16px", gap: "8px",
  borderRadius: "8px", background: "#237182",
  color: "#FFFFFF",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: "capitalize",
  "&:hover": {
    background: "#237182",
  },

}));

const CustomNewButton = styled(Button)(({ theme }) => ({
  width: "320px",
   height: "44px",
  padding: "10px 16px", gap: "8px",
  borderRadius: "8px", background: "#237182",
  color: "#FFFFFF",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: "capitalize",
  "&:hover": {
    background: "#237182",
  },

}));

const CustomEditQuoteButton = styled(Button)(({ theme }) => ({
  width: "320px",
   height: "44px",
  padding: "10px 16px", gap: "8px",
  borderRadius: "8px", background: "#E0EDF0",
  color: "#325962",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: "capitalize",
  "& .MuiButton-label": {
    fontFamily: "Poppins", 
  },
  "&:hover": {
    background: "#E0EDF0",
  },

}));

const CustomGenerateQuoteButton1 = styled(Button)(({ theme }) => ({
  width: "320px",
  height: "44px",
  padding: "10px 16px", gap: "8px",
  borderRadius: "8px", background: "#F1F5F9",
  color: "#64748B",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: "capitalize",
  margin:"auto",
  "& .MuiButton-label": {
    fontFamily: "Poppins",
  },
  "&:hover": {
    background: "#F1F5F9",
  },

}));

const CustomRejectButton = styled(Button)(({ theme }) => ({
  width: "320px",
  height: "44px",
  padding: "10px 16px", gap: "8px",
  borderRadius: "8px", background: "#E0EDF0",
  color: "#325962",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: "capitalize",
  margin:"auto",
  "&:hover": {
    background: "#E0EDF0",
  },

}));

const DisableSendButton = styled(Button)(({ theme }) => ({
  width: "320px",
  height: "44px",
  padding: "10px 16px", gap: "8px",
  borderRadius: "8px", background: "#F1F5F9",
  color: "#64748B",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: "capitalize",
  margin:"auto",
  cursor: "not-allowed",
  "& .MuiButton-label": {
    fontFamily: "Poppins", 
  },
  "&:hover": {
    background: "#F1F5F9",
    cursor: "not-allowed",
  },

}));
const DeleteDialog = styled(Dialog)(({ theme }) => ({
  "& .dialog-grid-container": {
    padding: '40px 32px',
  },
  "& .dialog-paper": {
    borderRadius: '16px',
    width: '584px',
  },
}));
const DisableNewButton = styled(Button)(({ theme }) => ({
  height: "44px",
  width: "320px",
  padding: "10px 16px", gap: "8px",
  borderRadius: "8px", background: "#F1F5F9",
  fontFamily: 'Poppins',
  fontSize: '16px',
  textTransform: "capitalize",
  lineHeight: '24px',
  color: "#64748B",
  fontWeight: 600,
  margin:"auto",
  cursor: "not-allowed",
  "& .MuiButton-label": {
    fontFamily: "Poppins", 
  },
  "&:hover": {
    cursor: "not-allowed",
    background: "#F1F5F9",
  },

}));
const Accordionwrapper = styled(Accordion)({
  " & .MuiAccordion-root":{
  height:"68vh",
 boxShadow:"none"

},
"& .MuiAccordionSummary-root":{
  padding:"16px 16px",

},
"& .MuiPaper-elevation1":{
  boxShadow:"none !important"
},
  "& .acc_heading":{
  color: '#0F172A',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '26px',
},
"& .MuiAccordionSummary-root.Mui-expanded":{
  border:"1px solid #E2E8F0 !important"
},
"& .MuiAccordion-rounded":{
  marginBottom:"99px"
},
"& .acc_det_heder":{
color:"#334155",
fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
},
"& .acc_det_para":{
color:"#334155",
fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
},
"& .MuiAccordionDetails-root":{
  flexDirection:"column",
  gap:"10px"
}
})

const SubHeader = styled(Box)({
  display: 'flex',  alignItems: 'center', width: '100%', 
  justifyContent:"start",
  padding:" 20px ",
  paddingBottom:0,
});
const TabButton = styled(Button)({
  padding: "8px 16px",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize:16,
  // textTransform: "none",
  background: "#D3E4E9",
  color: "#0F172A",
  fontWeight: 400,
  border:"1px solid #325962",

});
const MainWrapper = styled(Box)(({ theme }) => ({
"& .mainWrapper":{
  display:"flex",
  width:"100%",
  hight:"100%",
  backgroundColor: "rgb(240, 245, 247)",

},
"& .pdfsection":{
  margin:"20px ",
  marginBottom:"0px",
  padding:" 20px ",
  paddingBottom:"0px",
  borderTopLeftRadius: "8px", 
  borderTopRightRadius: "8px", 
  overflowY:"scroll",
  height:" calc(100vh - 195px)",
  position: 'relative',
  background:"#ffffff",
  overflowX: "hidden", 
  scrollbarWidth: "auto", 
  scrollbarColor:" #CBD5E1 #ffffff", 

   "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },


},


"& .DetailSection":{
  minWidth:"360px",
  width:"360",
  height:" calc(100vh - 88px)", 
  scrollbarWidth: "auto", 
  scrollbarColor:" #CBD5E1 #ffffff", 
  backgroundColor:"#fff",
  display:"flex",
  justifyContent:"space-between",
  flexDirection:"column",
   "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },
},
"& .acc_parent_wrapeer":{
  height: "calc(100% - 44px)",
  overflow:"scroll",
  scrollbarWidth: "none", 
  "-ms-overflow-style": "none", 
  "&::-webkit-scrollbar": {
    display: "none",
  },
},
"& .backButtonTypo": {
  color: '#334155',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textAlign: 'left',
},
"& .tabButton_Wrapper":{
  display: 'flex', justifyContent: 'space-between', alignItems: 'center',  gap:"10px" 
},
"& .canvas": {
  flex: 1,
  position: 'relative',
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
"& .pdfCanvas": {
  width: '90%',
  height: '90%',
  border: '1px solid #ccc',
},
'& .pdf-controls': {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
  background: '#F8FAFC',
  boxShadow: '0px 8px 32px 0px #0000000F',
  padding: '0px 20px',
  textAlign: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  height: '45px'
},
'& .pdf-viewer': {
        position: 'relative',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
    },
'& .canvas-container:': {
  overflow: 'auto',
  width: '100%',
},
"& .zoom_percenage":{
  border:"1px solid #CBD5E1",
  borderRadius:"8px",
  background:"#FFFFFF",
  padding:"10px 8px"

}

}))

const Accordionwrapper1 = styled(Accordion)({
  " & .MuiAccordion-root":{
  height:"68vh",
 boxShadow:"none"

  },
"& .MuiAccordionSummary-root":{
  padding:"16px 16px",

  },
})

const CustomStepConnector = styled(StepConnector)({
  '& .MuiStepConnector-line': {
    borderLeft: '1px solid #E2E8F0',
    position: 'absolute',
    left: '35px',
    top: '140px',
    bottom: '45px',
    width: '0',
    zIndex: 0.9,
    maxHeight: 'auto',
  },
});


const StyledStepLabel = styled(StepLabel)({
  '& .MuiStepLabel-iconContainer': {
    position: 'relative',
    zIndex: 1,
    width: '20px',
    top: -5,
    right: 9
  },
  '& .MuiStepIcon-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10px',
    height: '10px',
    backgroundColor: '#64748B',
    borderRadius: '50%',
    color: 'transparent'
  },
  '& .MuiStepIcon-text': {
    display: 'none',
  },
});

const AccordionSummaryAlign = styled(AccordionSummary)({
  borderBottom: '1px solid #E2E8F0'
})


const TypographyAlign = styled(Typography)({
  textAlign: 'left',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  marginTop: '10px',
  marginLeft: '30px'
});


const TypographyHeader = styled(Typography)({
  font: 'Poppins',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '26px',
})

const TypographyHead = styled(Typography)({
  fontFamily: 'Poppins', 
  fontSize: '14px',
  lineHeight: '24px',
  color: "#0F172A",
  fontWeight:400
});


const TypographyContent = styled(Typography)({
  font: 'Poppins',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  color: "#64748B"
})
const StyledStepLabels = styled(StyledStepLabel)({
  transition: 'background-color 0.3s',
});

const CheckCircleIcons = styled(CheckCircleIcon)({
  position: 'relative',
  top: '6px',
  padding: '0px 5px',
  color: '#059669'
})

const CancelIcons = styled(CancelIcon)({
  position: 'relative',
  top: '6px',
  padding: '0px 5px',
  color: '#DC2626'
})
const CustomToast = styled(ToastContainer)({
  width: "unset",
  marginTop: "80px",
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.2rem 1rem",
    borderRadius: "8px",
    maxHeight: "42px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    padding: 0,
  },
  "& .Toastify__close-button": {
    display: "none", 
  },
})
// Customizable Area End
